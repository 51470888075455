import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/get-days-off', data || {});
    return response;
  } catch (error) {
    console.error('Error fetching all days off:', error);
    throw error;
  }
}

async function getPublicHolidays () {
  try {
    const response = await apiClient.get('/get-public-holidays');
    return response;
  } catch (error) {
    console.error('Error fetching public holidays:', error);
    throw error;
  }
}

async function createOrUpdate(data) {
  try {
    const response = await apiClient.post('/request-days-off', data);
    return response;
  } catch (error) {
    console.error('Error creating day off:', error);
    throw error;
  }
}

async function deleteDayOff(data) {
  try {
    const response = await apiClient.post('/delete-day-off', data);
    return response;
  } catch (error) {
    console.error('Error deleting day off:', error);
    throw error;
  }
}

export { fetchAll, createOrUpdate, deleteDayOff, getPublicHolidays };
