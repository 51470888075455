<template>
    <div class="container-xxl py-3">

        <div v-if="userData.permissions.length" class="table-responsive permission-applied">
            <table class="table table-striped custom-table">
                <thead>
                    <tr>
                        <th>Entity</th>
                        <th class="text-center">View</th>
                        <th class="text-center">View All</th>
                        <th class="text-center">Create</th>
                        <th class="text-center">Edit</th>
                        <th class="text-center">Edit All</th>
                        <th class="text-center">Archive</th>
                        <th class="text-center">Accept</th>
                        <th class="text-center">Edit creator</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(permission, index) in userData.permissions" :key="index">
                        <td class="fw-bold">{{ permission.entity.title }}</td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.view === 1"
                                @change="updatePermission(index, 'view', $event)"
                                :title="getPermissionTooltip(permission.entity, 'view')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.viewAdmin === 1"
                                @change="updatePermission(index, 'viewAdmin', $event)"
                                :title="getPermissionTooltip(permission.entity, 'viewAll')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.create === 1"
                                @change="updatePermission(index, 'create', $event)"
                                :title="getPermissionTooltip(permission.entity, 'create')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.edit === 1"
                                @change="updatePermission(index, 'edit', $event)"
                                :title="getPermissionTooltip(permission.entity, 'edit')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.editAll === 1"
                                @change="updatePermission(index, 'editAll', $event)"
                                :title="getPermissionTooltip(permission.entity, 'editAll')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.archive === 1"
                                @change="updatePermission(index, 'archive', $event)"
                                :title="getPermissionTooltip(permission.entity, 'archive')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.accept === 1"
                                @change="updatePermission(index, 'accept', $event)"
                                :title="getPermissionTooltip(permission.entity, 'accept')"
                            />
                        </td>
                        <td class="text-center">
                            <input disabled
                                class="form-check-input disabled"
                                type="checkbox"
                                :checked="permission.accept === 1"
                                @change="updatePermission(index, 'editCreator', $event)"
                                :title="getPermissionTooltip(permission.entity, 'editCreator')"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-else>Δεν βρεθηκαν αποτελέσματα...</p>
    </div>
</template>

<script>
import { fetchUserInfo } from '@/services/user';

export default {
    name: 'MyPermissions',
    data() {
        return {
            userData: {
                permissions: []
            }
        };
    },
    methods: {
        updatePermission(index, permissionType, event) {
            this.userData.permissions[index][permissionType] = event.target.checked ? 1 : 0;
        },
        getPermissionTooltip(entity, permissionType) {
            const permissionTypeText = `${permissionType}Description`;
            return entity[permissionTypeText] || '';
        },
        massCheckUncheckAll(event, permissionType) {
            const isChecked = event.target.checked;
            this.userData.permissions.forEach((permission) => {
                if (permissionType === 'all') {
                    permission.view = isChecked ? 1 : 0;
                    permission.viewAdmin = isChecked ? 1 : 0;
                    permission.create = isChecked ? 1 : 0;
                    permission.edit = isChecked ? 1 : 0;
                    permission.editAll = isChecked ? 1 : 0;
                    permission.archive = isChecked ? 1 : 0;
                    permission.accept = isChecked ? 1 : 0;
                } else {
                    permission[permissionType] = isChecked ? 1 : 0;
                }
            });
        }
    },
    async mounted() {
        // Fetch initial permissions data here if needed
        const userInfoResponse = await fetchUserInfo();
        this.userData.permissions = userInfoResponse.data.info.perms;
    }
};
</script>

<style scoped>
.table-responsive {
    margin-top: 20px;
}
</style>
