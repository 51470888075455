import apiClient from './api';

async function getAuthUrl() {
  try {
    const response = await apiClient.get('/box/get-auth-url', {});
    return response;
  } catch (error) {
    console.error('Error getting auth url', error);
    throw error;
  }
}

export { getAuthUrl };
