<template>
    <div class="body d-flex py-lg-3 py-md-2">
      <div class="container-xxl">
        <!-- Existing Content -->
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="card border-0 mb-2 no-bg">
              
            </div>
          </div>
        </div>
    
        <!-- daysoff Table -->
        <div class="row clearfix g-3">
          <div class="col-sm-12">
            <div class="card mb-3">
              <div class="card-body table-responsive" style="width: 95%; margin: 0 auto">
                <table id="myProjectTable" class="table table-hover align-middle mb-0 mis_table" style="width: 100%">
                  <thead>
                    <tr>
                      <th style="width:8%;" v-if="superUser">
                      <div  class="d-flex align-items-center">
                        <select class="ms-1 nav-link rounded" name="" id="dropdownManagers"
                          @change="updateManagerFilter">
                          <option value="" selected>User</option>
                          <option v-for="manager in managers" :key="manager.id"
                              :value="manager.id">
                              {{ manager.username }}
                          </option>
                        </select>
                        <img class="menu-img" src="../assets/images/icons8-superman.svg"
                                                        alt="Citrine Logo" style="width:2em" >
                      </div>
                      
                    </th>
                      <th style="width:8%;"><div>Start<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('from')"></i></div></th>
                      <th style="width:8%;"><div>End<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('to')"></i></div></th>
                      <th style="width:8%;"><div>Reason</div></th>
<!--                       <th style="width:8%;"><div>Accepted</div></th>
 -->                      <th style="width:6%; background-color:white; border:none" class=no-after><div>Actions</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(daysoff, index) in data" :key="daysoff.id" :class="{ 'approved': daysoff.approved === 1, 'not-approved': daysoff.approved === 0 }">
                      <td>{{ daysoff.user?.username }}</td>
                      <td>{{ daysoff.from_formatted }}</td>
                      <td>{{ daysoff.to_formatted }}</td>
                      <td>{{ daysoff.description }}</td>
                      <!-- <td>
                      <button type="button" class="btn btn-dark deleterow">
                        <i v-if="daysoff.approved === 1" class="icofont-ui-check text-white"></i>
                        <i v-if="daysoff.approved === 0" class="icofont-ui-close text-white"></i>
                      </button>
                    </td>    -->
                      <td>
                        <template v-if="daysoff.approved === null">
                          <button type="button" class="btn btn-secondary" @click="acceptDayOff(daysoff.id)" title="Accept">
                            <i class="icofont-tick-mark text-white"></i>
                          </button>
                          <button type="button" class="btn btn-secondary ms-2" @click="rejectDayOff(daysoff.id)" title="Reject">
                            <i class="icofont-close text-white"></i>
                          </button>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="!data || !data.length">
                        <td colspan="1">No data</td>
                    </tr>
                  </tbody>
                </table>
                <nav aria-label="Page navigation" v-if="data && data.length">
                  <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                      <router-link :to="{ path: 'days-off-requests', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                        <span class="page-link" v-html="paginationLink.label"></span>
                      </router-link>
                      <span class="page-link" v-else v-html="paginationLink.label"></span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Accept Day Off Modal -->
        <div class="modal fade" id="accept-daysoff-form" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title fw-bold" id="acceptdaysoffLabel">Accept PTO?</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <input name="id" id="id" type="hidden" v-model="daysoffData.id" />
                <div class="mb-3">
                  The client will have to request further changes, if needed.
                </div>
              </div>
              <div class="modal-footer">
<!--                 <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
 -->                <button type="button" class="btn btn-secondary" @click="confirmAcceptDayOff">Accept</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Reject Day Off Modal -->
        <div class="modal fade" id="reject-daysoff-form" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title fw-bold" id="acceptdaysoffLabel">Reject PTO?</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <input name="id" id="id" type="hidden" v-model="daysoffData.id" />
                <div class="mb-3">
                    The client will have to make a new request, if needed.
                </div>
              </div>
              <div class="modal-footer">
<!--                 <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
 -->                <button type="button" class="btn btn-secondary" @click="confirmRejectDayOff">Reject</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </template>
  
  
  <script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, accept, reject } from '@/services/daysoffrequests';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import { fetchManagers } from '@/services/followup';

const defaultdaysoffData = {
  id: '',
  user: '',
  from: '',
  to: '',
  description: ''
};

export default {
  name: 'DaysOff',
  computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
       
    },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const managers = ref([]);


    const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

    const daysoffData = ref(defaultdaysoffData);

    const fetchManagersData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    managers.value = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch managers:', error);
            }
        };

        const updateManagerFilter = (event) => {
            const newManagerId = event.target.value;
            updateFilters({ ...filters.value, userFilter: newManagerId, page: 1 });
        };

    const acceptDayOff = (id) => {
      daysoffData.value.id = id;
      $('#accept-daysoff-form').modal('toggle');
    };

    const rejectDayOff = (id) => {
      daysoffData.value.id = id;
      $('#reject-daysoff-form').modal('toggle');
    };

    const confirmAcceptDayOff = async () => {
      try {
        const response = await accept(daysoffData.value);
        if (response.data.success) {
          toast.success(response.data.message);
          $('#accept-daysoff-form').modal('toggle');
          fetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('Error submitting form');
      }
    };

    const confirmRejectDayOff = async () => {
      try {
        const response = await reject(daysoffData.value);
        if (response.data.success) {
          toast.success(response.data.message);
          $('#reject-daysoff-form').modal('toggle');
          fetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('Error submitting form');
      }
    };

    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    onMounted(async () => {
      await fetchData();
      fetchManagersData();

    });

    return {
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      daysoffData,
      getPageFromUrl,
      acceptDayOff,
      confirmAcceptDayOff,
      rejectDayOff,
      confirmRejectDayOff, sortBy,
      updateManagerFilter, managers
    };
  },
  metaInfo: {
    title: 'Days Off',
    meta: [
      { name: 'description', content: 'MIS - Days Off' },
      { property: 'og:title', content: 'MIS - Days Off' },
      { property: 'og:description', content: 'This is the days off page.' }
    ]
  }
};
</script>

  
  <style scoped>
  .dropdown-menu.show {
    display: block;
  }
  
  .approved{
  background-color: rgb(107, 219, 107);
}

.not-approved{
  background-color: rgb(241, 111, 111);
}
</style>

  