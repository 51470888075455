<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        <div
                            class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                            <div class="header-section">
                                <div class="search-area mt-2">
                                    <input id="main-search-input" type="search" class="form-control"
                                        placeholder="Αναζήτηση..." aria-label="search"
                                        aria-describedby="addon-wrapping" />
                                </div>
                            </div>
                            <div class="d-flex mt-sm-0 mt-3 justify-content-end">
                                <button type="button" class="ms-0 ms-sm-3 btn btn-dark" style="color:var(--secondary-color); font-weight:bold"
                                    @click.prevent="createSupplier" v-if="canCreateSupplier || superUser" ><i class="icofont-plus me-2 fs-6"></i>New Supplier</button>
                                    <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0" v-if="canArchive || superUser">
                                    <button class="ms-0 ms-sm-3 btn btn-primary dropdown-menu-toggler" type="button" style="color:var(--secondary-color); font-weight:bold">
                                        Ενέργειες
                                    </button>
                                    
                                    <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': isDropdownOpen}"
                                        :style="dropdownStyle">
                                        <li><a class="dropdown-item py-2 rounded archiveSelected" href="#"
                                                @click.prevent="archiveSuppliers">Αρχειοθέτηση
                                                επιλεγμένων</a></li>
                                    </ul>
                                    
                                </div>
                                <div class="ms-3 d-flex align-items-center">
                                        <img v-if="isGridView" src="../assets/images/list.png" alt="List" title="List"
                                            class="menu-img" style="width:2.5em; cursor: pointer;"
                                            @click="isGridView = false">
                                        <div v-if="!isGridView" style="background-color: #AFAFAF">
                                            <img src="../assets/images/list2.png" alt="List" title="List"
                                                class="menu-img" style="width:2.5em; cursor: pointer;"
                                                @click="isGridView = false">
                                        </div>
                                        <img v-if="!isGridView" src="../assets/images/grid.png" alt="Grid" title="Grid"
                                            class="menu-img" style="width:2.5em; cursor: pointer;"
                                            @click="isGridView = true">
                                        <div v-if="isGridView" style="background-color: #AFAFAF">
                                            <img src="../assets/images/grid2.png" alt="Grid" title="Grid"
                                                class="menu-img" style="width:2.5em; cursor: pointer;"
                                                @click="isGridView = true">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isGridView" class="card-body table-responsive">
                                <table id="projectTable" class="table table-hover align-middle mb-0 mis_table"
                                    style="width:100%">
                                    <thead>
                                        <tr  @click="toggleSupplierSelection(supplierData.id, $event)">
                                            <th style="width:25%;">
                                                <div>Supplier<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('title')"></i></div>
                                            </th>
                                            <th style="width:25%;">
                                                <div>Occupation<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('occupation')"></i></div>
                                            </th>

                                            <th style="width:20%;">
                                                <div v-if="superUser" class="d-flex align-items-center">

                                                    <select class="nav-link rounded" name="" id="dropdownCreators"
                                                        @change="updateCreatorFilter">
                                                        <option value="" selected>CREATOR</option>
                                                        <option v-for="creator in creators" :key="creator.id"
                                                            :value="creator.id">
                                                            {{ creator.username }}
                                                        </option>
                                                    </select>
                                                    <img class="menu-img" src="../assets/images/icons8-superman.svg"
                                                        alt="Citrine Logo" style="width:2em" >
                                                </div>
                                                <div v-else>
                                                    Creator
                                                    <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('c.username')"></i>
                                                </div>
                                            </th>

                                        

                                            <th style="width:20%;">
                                                <div>
                                                    Email
                                                    <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('email')"></i>
                                                </div>
                                            </th>

                                            
                                            <th class="no-after"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(supplierData, index) in data" :key="supplierData.id" @click="toggleSupplierSelection(supplierData.id, $event)" :class="{ 'selected': selectedSupplierIds.includes(supplierData.id) }">
                                            <td >
                                                {{ supplierData.title }}
                                            </td>
                                            <td >
                                                {{ supplierData.occupation }}
                                            </td>

                                            <td v-if="supplierData.creator?.avatar_file_name" class="small-column">
                                                <img :src="`/storage/avatars/${supplierData.creator.avatar_file_name}`"
                                                    width="30" style="border-radius:50%;" :title="supplierData.creator.username">
                                            </td>
                                            <td v-else class="small-column">
                                                {{ supplierData.creator?.username }}
                                            </td>


                                            <td >
                                                {{ supplierData.email }}
                                            </td>                                          
                                            <td>
                                                <div class="button-group">
                                                    
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark"
                                                        @click.prevent="editSupplier(supplierData.id)" v-if="canEditSupplier(supplierData) || canEditAllSuppliers || superUser">
                                                            <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                                class="menu-img" style="width:1.5em">
                                                        </button>
                                                    </div>
                                                    
                                                </div> 
                                            </td>

                                        </tr>
                                        <tr class="col" v-if="!data || !data.length">
                                            <td>No data</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
            <div v-if="isGridView" class="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 row-deck py-1 pb-4" style="position:relative;z-index:0">
                <div v-for="(supplierData, index) in data" :key="supplierData.id" class="col-6">
                    <div :class="{ 'shadow card teacher-card': selectedSupplierIds.includes(supplierData.id), 'card teacher-card': !selectedSupplierIds.includes(supplierData.id) }">
                        <div class="card-body d-flex">
                            <div class="checkbox-wrapper">
                                <input type="checkbox" @change="toggleSupplierSelection(supplierData.id, $event)" :checked="selectedSupplierIds.includes(supplierData.id)">
                            </div>
                            <div class="profile-av pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center w220">
                                <img src="../assets/images/lg/avatar3.jpg" alt="" class="avatar xl rounded-circle img-thumbnail shadow-sm">
                                <div class="about-info d-flex align-items-center mt-3 justify-content-center">
                                </div>
                            </div>
                            <div class="teacher-info border-start ps-xl-4 ps-md-3 ps-sm-4 ps-4 w-100">
                                <h6 class="mb-0 mt-2  fw-bold d-block fs-6"></h6>
                                <span class="light-info-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-11 mb-0 mt-1">{{supplierData.occupation}}</span>
                                <div class="video-setting-icon mt-3 pt-3 border-top">
                                    <p>{{supplierData.title}}</p>
                                    <p>{{supplierData.email}}</p>
                                </div>
                                <a href="#" style="color:var(--secondary-color); font-weight:bold" class="btn btn-dark btn-sm mt-1" @click.prevent="editSupplier(supplierData.id)" v-if="canEditSupplier(supplierData) || canEditAllSuppliers || superUser"><i class="icofont-invisible me-2 fs-6"></i>Επεξεργασία</a>
                            </div>
                        </div>
                    </div>
                </div>
           
        </div>

            <nav aria-label="Page navigation" v-if="data && data.length">
                    <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                        <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                            <router-link :to="{ path: 'suppliers', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                                <span class="page-link" v-html="paginationLink.label"></span>
                            </router-link>
                            <span class="page-link" v-else v-html="paginationLink.label"></span>
                        </li>
                    </ul>
                </nav>
        </div>

        <!-- Modal for creating a new user -->
        <div class="modal fade" id="create-supplier-form" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold"> Supplier Data</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input name="id" id="id" type="hidden" v-model="supplierData.id" />
                        <div class="deadline-form">
                            <form>
                                <div class="row g-3 mb-3">
                                    <div class="col">
                                        <label for="title" class="form-label">Title</label>
                                        <input type="text" class="form-control" id="title" name="title" placeholder="Title" v-model="supplierData.title">
                                    </div>
                                    <div class="col">
                                        <label for="nickname" class="form-label">Nickname</label>
                                        <input type="text" class="form-control" id="nickname" name="nickname" placeholder="Nickname" v-model="supplierData.nickname">
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="col">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" name="email" placeholder="Email" v-model="supplierData.email">
                                    </div>
                                    <div class="col">
                                        <label for="phone" class="form-label">Phone</label>
                                        <input type="text" class="form-control" id="phone" name="phone" placeholder="Phone" v-model="supplierData.phone">
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="col">
                                        <label for="afm" class="form-label">ΑΦΜ</label>
                                        <input type="text" class="form-control" id="afm" name="afm" placeholder="ΑΦΜ" v-model="supplierData.afm">
                                    </div>
                                    <div class="col">
                                        <label for="doy" class="form-label">ΔΟΥ</label>
                                        <input type="text" class="form-control" id="doy" name="doy" placeholder="ΔΟΥ" v-model="supplierData.doy">
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="col">
                                        <label for="address" class="form-label">Address</label>
                                        <input type="text" class="form-control" id="address" name="address" placeholder="Address" v-model="supplierData.address">
                                    </div>
                                    <div class="col">
                                        <label for="postal" class="form-label">ΤΚ</label>
                                        <input type="text" class="form-control" id="postal" name="postal" placeholder="TK" v-model="supplierData.postal">
                                    </div>
                                    <div class="col">
                                        <label for="country" class="form-label">Χώρα</label>
                                        <input type="text" class="form-control" id="country" placeholder="Χώρα" v-model="supplierData.country">
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="col">
                                        <label for="occupation" class="form-label">Occupation</label>
                                        <input type="text" class="form-control" id="occupation" placeholder="Occupation" v-model="supplierData.occupation">
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="create-supplier-errors"></div>
                    </div>
                    <div class="modal-footer">
<!--                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 -->                        <button type="button" class="btn btn-secondary" @click="submitForm">Save</button>
                    </div> 
                </div>  
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveSuppliers } from '@/services/suppliers';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { mapGetters } from 'vuex';
import { fetchManagers } from '@/services/followup';



const defaultSupplierData = {
    id: '',
    title: '',
    nickname: '',
    email: '',
    afm: '',
    doy: '',
    phone: '',
    country: '',
    address: '',
    postal: '',
    occupation: ''
};

export default {
    name: 'Suppliers',
    data() {
        return {
            isGridView: false, // true for grid view, false for list view
        };
    },
    methods: {
        
        canEditSupplier(supplier) {
            if (!supplier) { return false; }
            return localStorage.getItem('current_user_id') == supplier?.createdBy && this.userPermissions?.edit === 1;
        },
       
    },
   
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Suppliers');
        },
        
        canEditAllSuppliers() {
            return this.userPermissions?.editAll === 1;
        },
        canCreateSupplier() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
    },
    
    

    setup() {
        const route = useRoute();
        const router = useRouter();

        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

        const supplierData = ref(defaultSupplierData);
        const selectedSupplierIds = ref([]);

        const isDropdownOpen = ref(false);

        const creators = ref([]);

        const fetchCreatorsData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    creators.value = response.data.data;
                } else {
                    console.error("Failed to fetch creators:", response.data.message);
                }
            } catch (error) {
                console.error("Failed to fetch creators:", error);
            }
        };


        const toggleSupplierSelection = (supplierId, event) => {
            if (!selectedSupplierIds.value.includes(supplierId)) {
                selectedSupplierIds.value.push(supplierId);
            } else {
                selectedSupplierIds.value = selectedSupplierIds.value.filter(id => id !== supplierId);
            }
        };

        const archiveSuppliers = async () => {
            if (!selectedSupplierIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει πελάτες');
                return;
            }
            const response = await massArchiveSuppliers({ ids: selectedSupplierIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const editSupplier = async (id) => {
            const supplierInfo = await get({ "id": id });
            if (supplierInfo.data.success) {
                supplierData.value.id = supplierInfo.data.data.id;
                supplierData.value.title = supplierInfo.data.data.title;
                supplierData.value.nickname = supplierInfo.data.data.nickname;
                supplierData.value.email = supplierInfo.data.data.email;
                supplierData.value.phone = supplierInfo.data.data.phone;
                supplierData.value.address = supplierInfo.data.data.address;
                supplierData.value.postal = supplierInfo.data.data.postal;
                supplierData.value.occupation = supplierInfo.data.data.occupation;
                supplierData.value.afm = supplierInfo.data.data.afm;
                supplierData.value.doy = supplierInfo.data.data.doy;
                supplierData.value.country = supplierInfo.data.data.country;
            }
            $("#create-supplier-form").modal('toggle');
        };

        const createSupplier = async () => {
            supplierData.value = {
                id: '',
                title: '',
                nickname: '',
                email: '',
                afm: '',
                doy: '',
                phone: '',
                country: '',
                address: '',
                postal: '',
                occupation: ''
            };
            $("#create-supplier-form").modal('toggle');
        };

        const submitForm = async () => {
            const response = await createOrUpdate(supplierData.value);
            if (response.data.success) {
                useToast().success(response.data.message);
                $("#create-supplier-form").modal('toggle');
                fetchData();
            } else {
                useToast().error('An error occurred');
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const updateCreatorFilter = (event) => {
            const newCreatorId = event.target.value;
            updateFilters({ ...filters.value, creatorFilter: newCreatorId, page: 1 });
        };


        const closeMenu = (event) => {
            if (document.querySelector('.dropdown-menu').length && !document.querySelector('.dropdown-menu').contains(event.target) && !document.querySelector('.dropdown-menu-toggler').contains(event.target)) {
                isDropdownOpen.value = false;
            }
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({path: '/suppliers', query: {...route.query, search: $("#main-search-input").val()}});
            }, 300);
        };

        onMounted(() => {
            $(document.body).on('keyup', "#main-search-input", searchAction);
            document.addEventListener('click', closeMenu);
            fetchCreatorsData();

        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction);
            document.removeEventListener('click', closeMenu);

        });

        return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, supplierData, submitForm, editSupplier, createSupplier, selectedSupplierIds, updateCreatorFilter, creators, toggleSupplierSelection, archiveSuppliers, dropdownStyle, closeDropdown, sortBy, isDropdownOpen, toggleDropdown };
    },
    metaInfo: {
        title: 'Προμηθευτές',
        meta: [
            { name: 'description', content: 'MIS - Suppliers' },
            { property: 'og:title', content: 'MIS - Suppliers' },
            { property: 'og:description', content: 'This is the suppliers page.' }
        ]
    }
};
</script>

<style scoped>
.dropdown-menu.show {
    display: block;
}
tr.selected,
div.selected {
    background: #b1afaf;
}
</style>
