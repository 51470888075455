import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/get-clients-contacts', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function createOrUpdate(data) {
  try {
    const response = await apiClient.post('/create-update-contact', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function massArchiveContacts(data) {
  data.field = 'deleted_at';
  // date now in javascript, in format YYYY-mm-dd H:i:s
  data.value = new Date().toISOString().replace('T', ' ').substr(0, 19);
  try {
    const response = await apiClient.post('/mass-update-contacts', data); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getClientsServicesStatusesUsers(data) {
  try {
      const response = await apiClient.get('/get-clients-services', data || {}); // Replace with your endpoint
      return response;
  } catch (error) {
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return error.response;
      } else if (error.request) {
          // The request was made but no response was received
          return { success: false, message: "No response received", details: error.request };
      } else {
          // Something happened in setting up the request that triggered an Error
          return { success: false, message: "Request setup error", details: error.message };
      }
  } 
}

async function get(data) {
  try {
    const response = await apiClient.get('/get-contact', {params: data || {}}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export {fetchAll, createOrUpdate, get, massArchiveContacts, getClientsServicesStatusesUsers}