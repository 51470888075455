<template>
  <div class="body d-flex py-lg-3 py-md-2">
    <div class="container-xxl">
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="card border-0 no-bg">
            <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-end main-button-area">
            <div class="col-auto d-flex">
              <button type="button" class="ms-0 ms-sm-3 btn btn-dark" style="color:var(--secondary-color); font-weight:bold" @click.prevent="createRequest" v-if="canCreateDayOff || superUser">
                <i class="icofont-plus me-2 fs-6"></i>Request Days Off
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <!-- daysoff Table -->
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div class="card-body table-responsive" style="width: 95%; margin: 0 auto">
              <table id="myProjectTable" class="table table-hover align-middle mb-0 mis_table" style="width: 100%">
                <thead>
                  <tr>
                    <th style="width:12%;" v-if="superUser || canViewAllDaysOff">
                      <div  class="d-flex align-items-center">
                        <select class="ms-1 nav-link rounded" name="" id="dropdownManagers"
                          @change="updateManagerFilter">
                          <option value="" selected>Name</option>
                          <option v-for="manager in managers" :key="manager.id"
                              :value="manager.id">
                              {{ manager.username }}
                          </option>
                        </select>
                        
                      </div>
                      
                    </th>
                    <th style="width:15%;"><div>Dates</div></th>
                    <th style="width:12%;"><div>Reason</div></th>
                    <th style="width:10%;"><div>Days</div></th>
                    <th style="width:12%;"><div>Accepted</div></th>
                    <th style="width:6%; background-color:white; border:none" class="no-after"><div></div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(daysoff, index) in data" :key="daysoff.id" :class="{ 'approved': daysoff.approved === 1, 'not-approved': daysoff.approved === 0 }" >
                    <td v-if="superUser || canViewAllDaysOff">{{ daysoff.user?.name }} {{ daysoff.user?.surname }}</td>
                    <td>{{ daysoff.from_formatted }} {{ daysoff.to_formatted }}</td>
                    <td>{{ daysoff.description }}</td>
                    <td>{{ daysoff.days }}</td>
                    <td>
                      <button type="button" class="btn btn-secondary deleterow">
                        <i v-if="daysoff.approved === 1" class="icofont-ui-check text-white"></i>
                        <i v-if="daysoff.approved === 0" class="icofont-ui-close text-white"></i>
                      </button>
                    </td>                    <td>
                      <button v-if="superUser || canEditDayOff" type="button" class="btn btn-secondary deleterow" @click="removeDayOff(daysoff.id)">
                        <i class="icofont-ui-delete text-white"></i>
                      </button>
                    </td>
                  </tr>
                  <tr class="col" v-if="!data || !data.length">
                            <td></td>
                            <td>No data</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation" v-if="data && data.length">
                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                  <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                    <router-link :to="{ path: 'days-off', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                      <span class="page-link" v-html="paginationLink.label"></span>
                    </router-link>
                    <span class="page-link" v-else v-html="paginationLink.label"></span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!-- Calendar -->
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div class="card-body calendar-container">
              <FullCalendar ref="fullCalendar" :options="calendarOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- Add Event -->
      <div class="modal fade" id="create-daysoff-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="createprojectLabel">Request Days Off</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input name="id" id="id" type="hidden" v-model="daysoffData.id" />
              <div class="deadline-form">
                <div class="row g-3 mb-1">
                  <div class="col">
    <label for="from" class="form-label">Start Date</label>
    <input
      type="date"
      class="form-control"
      id="from"
      v-model="daysoffData.from"
      :min="today" 
      placeholder="Start Date"
    />
  </div>
                  <div class="col">
                    <label for="to" class="form-label">End Date</label>
                    <input type="date" class="form-control" id="to" v-model="daysoffData.to" placeholder="End Date" :min="daysoffData.from || today" >
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <label for="description" class="form-label">Reason (optional)</label>
                <textarea class="form-control" id="description" rows="3" v-model="daysoffData.description" placeholder="Reason (optional)"></textarea>
              </div>
            </div>
            <div class="modal-footer">
<!--               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
 -->              <button type="button" class="btn btn-secondary" @click="submitForm">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, deleteDayOff } from '@/services/daysoff'; // Update the import statement for your daysoff service
import { useToast } from 'vue-toastification';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import { mapGetters } from 'vuex';
import { fetchManagers } from '@/services/followup';

const defaultdaysoffData = {
  id: '',
  user: '',
  from: '',
  to: '',
  description: ''
};

const prepareDaysOffData = function(data) {
  if (!Array.isArray(data)) {
    console.error('Expected an array, but received:', data); // Debugging log
    return []; // Return an empty array if data is not an array
  }

  const today = new Date().toISOString().slice(0, 10);
  return data.map(daysoff => {
    const startDate = new Date(daysoff.from);
    const endDate = new Date(daysoff.to);
    endDate.setDate(endDate.getDate() + 1); // FullCalendar's end date is exclusive, so we add one day
    return {
      id: daysoff.id,
      title: '', // Empty title, since we're using description instead
      start: startDate.toISOString().slice(0, 10),
      end: endDate.toISOString().slice(0, 10),
      isToday: daysoff.from === today,
      isToday: daysoff.to === today,

      description: daysoff.description, // Include description
      user: daysoff.user.username // Include user.username
    };
  });
};


export default {
  name: 'DaysOff',
  components: {
    FullCalendar
  },
  methods: {
        
        canEditDayOff(dayoff) {
            if (!dayoff) { return false; }
            return localStorage.getItem('current_user_id') == dayoff?.createdBy && this.userPermissions?.edit === 1;
        },
       
    },
  computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Days off');
        },
        canEditDayOff() {
            return this.userPermissions?.editCreator === 1;
        },
        canEditAllDaysOff() {
            return this.userPermissions?.editAll === 1;
        },
        canViewAllDaysOff() {
            return this.userPermissions?.viewAdmin === 1;
        },
        canCreateDayOff() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
    },
  setup() {
    const route = useRoute();
    const toast = useToast();

    const { data, paginationLinks, filters, updateFilters, fetchData } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

    const daysoffData = ref(defaultdaysoffData);
    const calendarEvents = ref([]);
    const managers = ref([]);

    const today = new Date().toISOString().slice(0, 10);



    // Update createRequest function to reset daysoffData
    const createRequest = () => {
      daysoffData.value = { ...defaultdaysoffData };
      $('#create-daysoff-form').modal('toggle');
    };

    // Update deleteDayOff function to use the correct service function
    const removeDayOff = async (id) => {
      const response = await deleteDayOff({ id });
      if (response.data.success) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    };

    const fetchManagersData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    managers.value = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch managers:', error);
            }
        };

        const updateManagerFilter = (event) => {
            const newManagerId = event.target.value;
            updateFilters({ ...filters.value, userFilter: newManagerId, page: 1 });
        };

    // Update submitForm function to use the correct service function
    const submitForm = async () => {
      try {
        const response = await createOrUpdate(daysoffData.value);
        if (response.data.success) {
          toast.success(response.data.message);
          $('#create-daysoff-form').modal('toggle');
          fetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(response.data.data);
      }
    };

    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    const fetchDaysOff = async () => {
  try {
    const response = await fetchAll();
    console.log('Fetch response:', response.data); // Log to inspect the response structure
    if (response.data.success) {
      calendarEvents.value = prepareDaysOffData(response.data.data.data); // Adjust based on the structure
    }
  } catch (error) {
    console.error('Error fetching daysoff:', error);
  }
};


    const calendarOptions = computed(() => ({
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',
      events: calendarEvents.value,
      eventContent: function(arg) {
        const description = arg.event.extendedProps.description; // Access description property
        const username = arg.event.extendedProps.user; // Access user property
        if (arg.event.extendedProps.isToday) {
          return {
            html: `<div class="fc-event-title" title="${description}">${username} (${description})</div>`
          };
        }
        return { html: `<div class="fc-event-title" title="${description}">${username} (${description})</div>` };
      }
    }));

    onMounted(async () => {
      await fetchData(); // Fetch data for the table
      await fetchDaysOff(); // Fetch data for the calendar
      fetchManagersData();

    });

    return {
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      daysoffData,
      getPageFromUrl,
      submitForm,
      createRequest,
      removeDayOff,
      calendarOptions,
      updateManagerFilter, managers,
      today
    };
  },
  metaInfo: {
    title: 'Days Off',
    meta: [
      { name: 'description', content: 'MIS - Days Off' },
      { property: 'og:title', content: 'MIS - Days Off' },
      { property: 'og:description', content: 'This is the days off page.' }
    ]
  }
};
</script>

<style scoped>
.calendar-container {
  height: 1000px; /* Adjust this height as needed */
  overflow: auto;
}

.fc-event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-event-title:hover {
  white-space: normal;
}
</style>
