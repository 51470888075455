<template>
  <div class="body d-flex py-lg-3 py-md-2">
    <div class="container-xxl">
      <div class="row align-items-center">
        <div class="border-0 mb-2">
          <div class="card-header py-1 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between flex-wrap">
            <div class="d-flex py-2 project-tab flex-wrap gap-0 gap-xl-2 col-6">
              <div class="col-5 col-xl-2 permission-applied" v-if="superUser || canViewAll">
                <select2 v-model="filters.userId" :settings="userSettings" :options="userOptions" placeholder="Χρήστες" multiple></select2>
              </div>
              <div class="col-5 col-xl-2">
                <select2 v-model="filters.statusId" :settings="statusSettings" :options="statusOptions" placeholder="Status" multiple></select2>
              </div>
              <div class="col-12 mt-3 mt-xl-0 col-xl-2">
                <button type="button" class="w-sm-100 ms-0 btn btn-secondary" @click="fetchData"><i class="icofont-search me-2 fs-6"></i>Search</button>
              </div>
            </div>            
            <div class="d-flex py-2 project-tab flex-wrap justify-content-between gap-2 col-6">
              <div class="d-flex">
                <div class="d-flex w-100 text-nowrap align-items-center gap-3">
                  <label for="filterDateFrom" class="form-label mb-0">Ημ/νία από:</label>
                  <input type="date" class="form-control" v-model="filters.dateFrom">
                </div>
              </div>
              <div class="d-flex">
                <div class="d-flex w-100 text-nowrap align-items-center gap-3">
                  <label for="filterDateTo" class="form-label mb-0">Ημ/νία έως:</label>
                  <input type="date" class="form-control" v-model="filters.dateTo">
                </div>
              </div>
                <button class="ms-0 ms-sm-3 btn btn-secondary dropdown-menu-toggler" type="button" @click.stop="toggleActionsDropdown" >
                  Ενέργειες
                </button>
                <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': showActionsDropdown}" v-show="showActionsDropdown" style="right:60px; top:170px">
                  <li><a class="dropdown-item py-2 rounded archiveSelected" @click.prevent="exportWithoutTasks">Εξαγωγή χωρίς tasks</a></li>
                  <li><a class="dropdown-item py-2 rounded archiveSelected" @click.prevent="exportWithTasks">Εξαγωγή με tasks</a></li>
                </ul>
            </div>
          </div>
        </div>
       
      </div>

      <div class="row clearfix g-3">
        <div class="col-lg-12">
          <div>
            <div class="card mb-3">
              <div class="card-body p-0 mt-3 table-responsive">
                <table id="reports" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                  <thead>
                    <tr class= "reportsHeader">
                      <th style="width:10%; color:white">Εργο</th>
                      <th style="width:10%; color:white">Εκτιμ.</th>
                      <th style="width:10%; color:white">Total</th>
                      <th style="width:10%; color:white">Period</th>
                      <th style="width:10%; color:white" v-for="user in users" :key="user.userId">{{ user.username }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="report in reports" :key="report.projectId">
                      <tr @click="toggleTasks(report.projectId)">
                        <td><router-link :to="`/project?id=${report.projectId}`">{{ report.title }}</router-link></td>
                        <td>{{ report.estimated_hours }}</td>
                        <td>{{ report.total_worked_hours_formatted }}</td>
                        <td>{{ report.total_worked_hours_period_formatted }}</td>
                        <td v-for="user in users" :key="user.userId">{{ getUserProjectHours(report.user_hours, user.userId) }}</td>
                      </tr>
                      <tr v-if="report.tasks" v-for="task in report.tasks" :key="task.task_id" v-show="task.show" class="reports_tasks" style="font-weight: lighter;">
                        <td><router-link :to="`/task?id=${task.task_id}`">{{ task.title }}</router-link></td>
                        <td>-</td>
                        <td>{{ task.total_worked_hours_formatted }}</td>
                        <td>{{ task.total_worked_hours_period_formatted }}</td>
                        <td v-for="user in users" :key="user.userId">{{ getUserTaskHours(task.user_hours, user.userId) }}</td>
                      </tr>
                      
                    </template>
                    <tr v-if="!reports || !reports.length">
                          <td colspan="1">No data</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/api';
import axios from 'axios';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { RouterLink } from 'vue-router';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';

export default {
  components: {
    Select2,
    RouterLink
  },
  data() {
    return {
      filters: {
        dateFrom: null,
        dateTo: null,
        userId: [],
        statusId: [],
      },
      users: [],
      statuses: [],
      reports: [],
      showActionsDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
    userSettings() {
      return { multiple: true, allowClear: true };
    },
    userOptions() {
      return this.users.map(user => ({ id: user.userId, text: user.username }));
    },
    statusSettings() {
      return { multiple: true, allowClear: true };
    },
    statusOptions() {
      return this.statuses.map(status => ({ id: status.id, text: status.description }));
    },
    superUser() {
        return this.getSuperUserPermission === 1;
    },
     userPermissions() {
        return this.getUserPermission('Reporting');
    },
    
    canViewAll() {
        return this.userPermissions?.viewAdmin === 1;
    },
    
  },
  methods: {
    closeActionsDropdown(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.showActionsDropdown = false;
      }
    },
    toggleActionsDropdown() {
      this.showActionsDropdown = !this.showActionsDropdown;
    },
    fetchData() {
      const { dateFrom, dateTo, userId, statusId } = this.filters;
      apiClient
        .get('/get-hours-report', {
          params: {
            date_from: dateFrom,
            date_to: dateTo,
            userId: Array.isArray(userId) ? userId : [userId],
            statusId: Array.isArray(statusId) ? statusId : [statusId],
          }
        })
        .then((response) => {
          if (response.data.success) {
            this.reports = response.data.data.data;
            this.users = response.data.data.users;
            this.statuses = response.data.data.project_statuses;
            this.reports.forEach(report => {
              report.tasks.forEach(task => {
                task.show = false;
              });
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
    exportWithoutTasks() {
      this.exportData(false);
    },
    exportWithTasks() {
      this.exportData(true);
    },
    exportData(withTasks) {
      const { dateFrom, dateTo, userId, statusId } = this.filters;
      apiClient
        .get('/get-hours-report', {
          params: {
            date_from: dateFrom,
            date_to: dateTo,
            userId: Array.isArray(userId) ? userId : [userId],
            statusId: Array.isArray(statusId) ? statusId : [statusId],
            export: 1,
            export_with_tasks: withTasks ? 1 : undefined,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `hours-export${withTasks ? '-with-tasks' : ''}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error('Error exporting data:', error);
        });
    },
    toggleTasks(reportId) {
      const report = this.reports.find((r) => r.projectId === reportId);
      report.tasks.forEach((task) => {
        task.show = !task.show;
      });
    },
    getUserProjectHours(userHours, userId) {
      const user = userHours.find((u) => u.userId === userId);
      return user ? user.project_worked_hours_formatted : '0';
    },
    getUserTaskHours(userHours, userId) {
      const user = userHours.find((u) => u.userId === userId);
      return user ? user.task_worked_hours_formatted : '0';
    },
    getAuthToken() {
      // Implement your method to get the auth token
      return localStorage.getItem('api_token');
    },
  },
  mounted() {
    this.fetchData();
    document.addEventListener('click', this.closeActionsDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeActionsDropdown);
  },
};
</script>

<style scoped>
/* Add any scoped styles here */
.reportsHeader{
  background-color: #6C757D;
}

#reports tr th {
    border-right: 2px solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width:80px;
    text-align: center;
    padding:2px;
}
.select2-selection__choice{
  background-color: #6C757D;
}
</style>
