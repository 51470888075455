<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row g-3">
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="card teacher-card mb-3">
                        <div class="card-body d-flex teacher-fulldeatil">
                            <div class="teacher-info ps-xl-12 ps-md-12 ps-sm-12 ps-12 w-100">
                                <RouterLink to="/dashboard" class="breadcrumb-links"><span>Homepage</span></RouterLink>
                                <span> ⤏ &nbsp;</span>
                                <RouterLink to="/projects" class="breadcrumb-links"><span>Projects</span></RouterLink>
                                <span> ⤏ &nbsp;</span>
                                <RouterLink :to="{ path: '/project', query: { id: projectViewData.info.id } }" class="breadcrumb-links" v-if="projectViewData">
                                    <span>{{ projectViewData.info?.code }}</span>
                                </RouterLink>
                                <h6 class="mb-0 mt-2 fw-bold d-block fs-6" v-if="projectViewData">
                                    {{ projectViewData.info?.code }}
                                    <button type="button" class="btn btn-dark show-edit-project-modal" @click.prevent="editProject(projectViewData.info?.id)"  v-if="superUser || canEditAllProjects || (canEditProject && (projectViewData.info.createdBy === getUser || projectViewData.info.managerId === getUser))">
                                        <i class="icofont-edit text-white"></i>
                                    </button>
                                </h6>
                                <h1 class="mt-2 m-2 title" v-if="projectViewData">{{ projectViewData.info?.description }}</h1>
                                <p class="mt-2 m-2 description" v-if="projectViewData" v-unescape-html="projectViewData.info?.body"></p>
                                <div class="row g-2 pt-2">
                                    <div class="col-xl-5" v-if="projectViewData && projectViewData.creator">
                                        <div class="d-flex align-items-center">
                                            <span class="ms-2 small">Creator: {{ projectViewData.creator?.username }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.manager">
                                            <span class="ms-2 small">Manager: {{ projectViewData.manager?.username }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.payManager">
                                            <span class="ms-2 small">Pay Manager: {{ projectViewData.payManager?.username }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center text-start" v-if="projectViewData && projectViewData.assigned && projectViewData.assigned.length">
                                            <span class="ms-2 small">Assigned: {{ projectViewData.assigned?.map(assigned => assigned.username).join(', ') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.service">
                                            <span class="ms-2 small">Service: {{ projectViewData.service?.title }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.client">
                                            <span class="ms-2 small">Client: {{ projectViewData.client?.nickname }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.status">
                                            <span class="ms-2 small">Status: {{ projectViewData.status?.title }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.info.startDate">
                                            <span class="ms-2 small">Start date: {{ projectViewData.info.startDate }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.info.endDate">
                                            <span class="ms-2 small">End date: {{ projectViewData.info.endDate }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.info.estimatedTime">
                                            <span class="ms-2 small">Estimated time: {{ projectViewData.info.estimatedTime }}</span>
                                        </div>
                                    </div>

                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.info.subscriptionDate">
                                            <span class="ms-2 small">Subscritpion Date: {{ projectViewData.info.subscriptionDate }}</span>
                                        </div>
                                    </div>
                                    
                                    <div class="col-xl-5">
                                        <div class="d-flex align-items-center" v-if="projectViewData && projectViewData.info.subscriptionDuration">
                                            <span class="ms-2 small">Subscritpion Duration: {{ projectViewData.info.subscriptionDuration }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-12">
                            <div class="card ">
                                <div class="d-flex align-items-center justify-content-between task-header">
                                    <div class="card-header py-3 d-flex justify-content-between align-items-center">
                                        <div class="info-header">
                                            <h6 class="mb-0 fw-bold ">Tasks</h6>
                                            <div class="d-flex py-2 project-tab flex-wrap w-sm-100">
                                                <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100"
                                                    role="tablist">
                                                    <li class="nav-item col">
                                                        <a :class="{ 'nav-link text-center active': filters.statusId == '1', 'nav-link text-center': filters.statusId != '1' }"
                                                            @click.prevent="() => updateFilters({ ...filters, statusId: '1', page: 1 })" href="#" role="tab">On Hold</a>
                                                    </li>
                                                    <li class="nav-item col">
                                                        <a :class="{ 'nav-link text-center active': filters.statusId == '2' || filters.statusId == undefined, 'nav-link text-center': filters.statusId != '2' && filters.statusId != undefined }"
                                                            @click.prevent="() => updateFilters({ ...filters, statusId: '2', page: 1 })" href="#" role="tab">On Progress</a>
                                                    </li>
                                                    <li class="nav-item col">
                                                        <a :class="{ 'nav-link text-center active': filters.statusId == '3', 'nav-link text-center': filters.statusId != '3' }"
                                                            @click.prevent="() => updateFilters({ ...filters, statusId: '3', page: 1 })" href="#" role="tab">Done</a>
                                                    </li>
                                                    <li class="nav-item col">
                                                        <a :class="{ 'nav-link text-center active': filters.statusId == '5', 'nav-link text-center': filters.statusId != '5' }"
                                                            @click.prevent="() => updateFilters({ ...filters, statusId: '5', page: 1 })" href="#" role="tab">On Going</a>
                                                    </li>
                                                    <li class="nav-item col">
                                                        <a :class="{ 'nav-link text-center active': filters.statusId == '4', 'nav-link text-center': filters.statusId != '4' }"
                                                            @click.prevent="() => updateFilters({ ...filters, statusId: '4', page: 1 })" href="#" role="tab">Archived</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-sm-0 mt-3 justify-content-between">
                                        <button type="button" class="ms-0 ms-sm-3 btn btn-dark" @click.prevent="createTask" v-if="canCreate"><i class="icofont-plus-circle me-2 fs-6"></i>New task</button>
                                        <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0" v-if="canArchive">
                                            <button class="ms-0 ms-sm-3 btn btn-primary dropdown-menu-toggler" type="button">Ενέργειες</button>
                                            <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': isDropdownOpen}" :style="dropdownStyle">
                                                <li><a class="dropdown-item py-2 rounded" href="#" @click.prevent="archiveTasks">Αρχειοθέτηση επιλεγμένων</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-body table-responsive">
                                    <table id="myProjectTable" class="table table-hover align-middle mb-0"
                                        style="width:100%">
                                        <thead>
                                            <tr>
                                                <th data-field="tasks.title">Title<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.title')"></i></th>
                                                <th data-field="tasks.startDate">Date Start<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.startDate')"></i>
                                                </th>
                                                <th data-field="tasks.endDate">Date End<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.endDate')"></i>
                                                </th>
                                                <th data-field="tasks.statusId">Status<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.statusId')"></i></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(task, index) in data" :key="task.info.id" :class="{ 'selected': selectedTaskIds.includes(task.info.id) }" @click="toggleTaskSelection(task.info.id, $event)">
                                                <td>{{ task.info?.title }}</td>
                                                <td>{{ task.info?.startDate }}</td>
                                                <td>{{ task.info?.endDate }}</td>
                                                <td>{{ task.status?.title }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-dark" @click.prevent="editTask(task.info?.id)" style="margin-right:2px" v-if="superUser || canEditAllTasks || (canEditTask && (task.info.createdBy === getUser || task.info.managerId === getUser))">
                                                        <i class="icofont-edit text-white"></i>
                                                    </button>
                                                    <router-link :to="{ path: 'task', query: { id: task.info?.id } }" class="btn btn-dark">
                                                        <i class="icofont-arrow-right text-white"></i>
                                                    </router-link>
                                                </td>
                                            </tr>
                                            <tr class="col" v-if="!data || !data.length">
                                                <td>No data</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav aria-label="Page navigation">
                                        <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                            <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                                                <router-link :to="{ path: 'project', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                                                    <span class="page-link" v-html="paginationLink.label"></span>
                                                </router-link>
                                                <span class="page-link" v-else v-html="paginationLink.label"></span>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div><!-- Row End -->
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="card sticky-top mb-3 chatFixed">
                        <div class="card-header py-1">
                            <h6 class="mb-0 fw-bold text-center">Συζήτηση - Σχόλια</h6>
                        </div>
                        <div class="card ChatDiv card-chat-body border-0  w-100 px-4 px-md-4 py-3 py-md-4">

                            <ul class="chat-history p-3 list-unstyled mb-0 flex-grow-1">

                                <li class="mb-3 d-flex flex-row-reverse align-items-end" v-if="projectViewData && projectViewData.comments" v-for="(projectComment, index) in projectViewData.comments" :key="projectComment.info.id">
                                    <div class="max-width-70 text-right">
                                        <div class="user-info mb-1">
                                            <span class="text-muted small">{{projectComment.author.username}} @ {{projectComment.info.created_at}}</span>
                                        </div>
                                        <div class="card border-0 p-3 bg-primary text-light">
                                            <div class="message">{{projectComment.info.comment}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <!-- Chat: Footer -->
                            <div class="chat-message" style="display: flex;">
                                <div id="mentions"></div>
                                <textarea class="form-control comment-val"
                                    placeholder="Αποστολή μηνύματος..." @keyup="handleKeyup"></textarea>
                                <button 
                                    @click.prevent="submitComment(projectViewData?.info?.id || 0)"
                                    class="btn btn-dark" type="button"
                                    style="height: 34px; margin: auto; margin-left: 5px;" id="create-comment"><i
                                        class="fa fa-paper-plane" aria-hidden="true"></i></button>
                            </div>
                            <ul v-if="filteredUsernames.length" class="list-unstyled suggestion-list">
                                <li v-for="(username, index) in filteredUsernames" :key="index" @click="selectUsername(username)">
                                    {{ username }}
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Create Project-->
    <div class="modal fade" id="create-project-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold" id="createprojectlLabel">Edit Project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="projectTitle" class="form-label" aria-label="Title" aria-placeholder="Title">Title</label>
                        <input type="text" class="form-control" id="projectTitle" placeholder="Title" name="title" v-model="projectData.description">
                    </div>
                    <div class="mb-3">
                        <label for="projectDescriptionTinyMce" class="form-label">Description</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="projectData.body"></Editor>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Client</label>
                        <Select2 :options="allData.clients" v-model="projectData.clientId" :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Status</label>
                        <Select2 :options="allData.statuses" v-model="projectData.statusId" :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Services</label>
                        <Select2 :options="allData.services" v-model="projectData.serviceId" :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Manager</label>
                        <Select2 :options="allData.users" v-model="projectData.managerId" :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Assigned users</label>
                        <Select2 :options="allData.users" v-model="projectData.assignedUsers" :settings="{...select2SettingsProject, multiple: true}" />
                    </div>
                    <div class="mb-3 payable-container">
                        <div class="mb-3">
                            <label class="form-label">Payable</label>
                            <Select2 :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]" v-model="projectData.payable" :settings="select2SettingsProject"/>
                        </div>
                        <div class="payable-fields" v-if="projectData.payable == '1'">
                            <div class="mb-3">
                                <label class="form-label">Pay Manager</label>
                                <Select2 :options="allData.users" v-model="projectData.payManagerId" :settings="select2SettingsProject" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Invoiced</label>
                                <Select2 :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]" v-model="projectData.invoiced" :settings="select2SettingsProject" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Paid</label>
                                <Select2 :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]" v-model="projectData.paid" :settings="select2SettingsProject" />
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="projectEstimatedHours" class="form-label" aria-label="Estimated hours" aria-placeholder="Estimated hours">Estimated hours</label>
                        <input type="number" class="form-control" id="projectEstimatedHours" placeholder="Estimated hours" aria-label="Estimated hours" aria-placeholder="Estimated hours" name="estimatedHours" v-model="projectData.estimatedTime">
                    </div>
                    <div class="mb-3">
                            <label for="subscriptionDate" class="form-label" aria-label="Subscription date" aria-placeholder="Subscription date">Subscription date</label>
                            <input type="date" class="form-control" id="subscriptionDate" placeholder="Subscription date" aria-label="Subscription date" aria-placeholder="Subscription date" name="subscriptionDate" v-model="projectData.subscriptionDate">
                        </div>
                        <div class="mb-3">
                            <label for="subscriptionDuration" class="form-label" aria-label="Subscription duration (months)" aria-placeholder="Subscription duration (months)">Subscription duration (months)</label>
                            <input type="number" class="form-control" id="subscriptionDuration" placeholder="Subscription duration (months)" aria-label="Subscription duration (months)" aria-placeholder="Subscription duration (months)" name="subscriptionDuration" v-model="projectData.subscriptionDuration">
                        </div>
                    <div class="deadline-form">
                        <form>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="projectStartDate" class="form-label">Start Date</label>
                                    <input type="date" class="form-control" id="projectStartDate" name="startDate" v-model="projectData.startDate">
                                </div>
                                <div class="col">
                                    <label for="projectEndDate" class="form-label">End Date</label>
                                    <input type="date" class="form-control" id="projectEndDate" name="endDate" v-model="projectData.endDate">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="create-project-errors"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="submitForm">Update</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Create Task-->
    <div class="modal fade" id="create-task-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold">Task Data</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="hidden" name="id" id="ïd" v-model="taskData.id">
                    <div class="mb-3">
                        <label for="projectTitle" class="form-label" aria-label="Title" aria-placeholder="Title">Title</label>
                        <input type="text" class="form-control" id="projectTitle" placeholder="Title" name="title" v-model="taskData.title">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Description</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="taskData.body"></Editor>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Status</label>
                        <Select2 :options="allData.statuses" v-model="taskData.statusId" :settings="select2SettingsTask" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Manager</label>
                        <Select2 :options="allData.users" v-model="taskData.managerId" :settings="select2SettingsTask" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Assigned users</label>
                        <Select2 :options="allData.users" v-model="taskData.assignedUsers" :settings="{...select2SettingsTask, multiple: true}" />
                    </div>
                    <div class="mb-3">
                        <label for="projectEstimatedHours" class="form-label" aria-label="Estimated hours" aria-placeholder="Estimated hours">Estimated hours</label>
                        <input type="number" class="form-control" id="projectEstimatedHours" placeholder="Estimated hours" aria-label="Estimated hours" aria-placeholder="Estimated hours" name="totalHours" v-model="taskData.totalHours">
                    </div>
                    
                    <div class="deadline-form">
                        <form>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="projectStartDate" class="form-label">Start Date</label>
                                    <input type="date" class="form-control" id="projectStartDate" name="startDate" v-model="taskData.startDate">
                                </div>
                                <div class="col">
                                    <label for="projectEndDate" class="form-label">End Date</label>
                                    <input type="date" class="form-control" id="projectEndDate" name="endDate" v-model="taskData.endDate">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="create-project-errors"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="submitTaskForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { getClientsServicesStatusesUsers, createComment } from '@/services/projects';
import { fetchAll, massArchiveTasks } from '@/services/tasks';
import { get as getTask } from '@/services/tasks';
import { get as getProject, searchUsers } from '@/services/projects';
import { createOrUpdate as createOrUpdateProject } from '@/services/projects';
import { createOrUpdate as createOrUpdateTask } from '@/services/tasks';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';


export default {
    name: 'Projects',
    components: {
        Editor,
        Select2
    },
    computed: {
    ...mapGetters({
      getUserPermission: 'getUserPermission',
      getSuperUserPermission: 'getSuperUserPermission', // Add this line

    }),
    userPermissionsTasks() {
      return this.getUserPermission('Tasks');
    },
    userPermissionsProjects() {
      return this.getUserPermission('Projects');
    },
    canCreate() {
      return this.userPermissions?.create === 1;
    },
    canUpdate() {
      return this.userPermissions?.create === 1;
    },
    canArchive() {
        return this.userPermissionsTasks?.archive === 1;
    },
    canEditProject() {
      return this.userPermissionsProjects?.edit === 1;
    },
    canEditAllProjects() {
      return this.userPermissionsProjects?.editAll === 1;
    },
    canEditTask() {
      return this.userPermissionsTasks?.edit === 1;
    },
    canEditAllTasks() {
      return this.userPermissionsTasks?.editAll === 1;
    },
    superUser() {
      return this.getSuperUserPermission === 1;
    },
    getUser() {
      return this.$store.state.userInfo.id;
    },
  },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const defaultProjectData = {
            id: '',
            title: '',
            description: '',
            clientId: '',
            assignedUsers: [],
            serviceId: '',
            managerId: '',
            statusId: '',
            payable: '0',
            payManagerId: '',
            invoiced: '0',
            paid: '1',
            estimatedTime: '',
            subscriptionDate: '',
            subscriptionDuration: '',
            startDate: '',
            endDate: ''
        };

        const defaultTaskData = {
            id: '',
            projectId: route.query.id || 0,
            title: '',
            body: '',
            assignedUsers: [],
            managerId: '',
            statusId: '',
            totalHours: '',
            startDate: '',
            endDate: ''
        };
        
        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { id: route.query.id, page: route.query.page || 1, archived: route.query.archived || 0 }, { id: route.query.id, page: 1, archived: 0 });

        const projectData = ref(defaultProjectData);
        const taskData = ref(defaultTaskData);
        const projectViewData = ref(null);
        const allData = ref({});
        const selectedTaskIds = ref([]);
        const isDropdownOpen = ref(false);
        const usernames = ref([]);
        const filteredUsernames = ref([]);
        
        const transformDate = (dateStr) => {
            const [day, month, year] = dateStr.split('/');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        };

        const revertDate = (dateStr) => {
            const [year, month, day] = dateStr.split('-');
            const formattedDate = `${day}/${month}/${year}`;
            return formattedDate;
        };

        const toggleTaskSelection = (taskId, event) => {
            if (!selectedTaskIds.value.includes(taskId)) {
                selectedTaskIds.value.push(taskId);
            } else {
                selectedTaskIds.value = selectedTaskIds.value.filter(id => id !== taskId);
            }
        };

         // Fetch project data using the route query parameters
        const fetchProjectData = async () => {
            const projectId = route.query.id;
            try {
                const response = await useFetchData(`/project/${projectId}`);
                projectViewData.value = response.data;
            } catch (error) {
                console.error('Error fetching project data:', error);
                toast.error('Error fetching project data');
            }
        };

        const archiveTasks = async () => {
            if (!selectedTaskIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει projects');
                return;
            }
            const response = await massArchiveTasks({ ids: selectedTaskIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const submitComment = async (id) => {
            const response = await createComment({"id": id, comment: $(".comment-val").val()});
            if (response.data.success) {
                $(".comment-val").val('');
                const projectDataTmp = await getProject({id: id || 0});
                if (projectDataTmp.data.success) {
                    projectViewData.value = projectDataTmp.data.data;
                }
                $(".chat-history").animate({ scrollTop: $(".chat-history")[0].scrollHeight }, 1000);
                useToast().success(response.data.message);
            } else {
                useToast().error(response.data.message);
            }
        };

        const editProject = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers();
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const projectInfo = await getProject({ "id": id });
            if (projectInfo.data.success) {
                projectData.value = {
                    id: projectInfo.data.data.info.id,
                    projectId: route.query.id || 0,
                    description: projectInfo.data.data.info.description,
                    body: projectInfo.data.data.info.body,
                    assignedUsers: projectInfo.data.data.assigned ? projectInfo.data.data.assigned?.map((assignedUser) => assignedUser.id) : [],
                    managerId: projectInfo.data.data.manager?.id,
                    serviceId: projectInfo.data.data.service?.id,
                    clientId: projectInfo.data.data.client?.id,
                    payable: projectInfo.data.data.info.payable || '0',
                    payManagerId: projectInfo.data.data.payManager?.id,
                    invoiced: projectInfo.data.data.invoiced || '0',
                    paid: projectInfo.data.data.paid || '0',
                    statusId: projectInfo.data.data.status?.id,
                    estimatedTime: projectInfo.data.data.info.estimatedTime,
                    subscriptionDate: projectInfo.data.data.info.subscriptionDate,
                    subscriptionDuration: projectInfo.data.data.info.subscriptionDuration,
                    startDate: transformDate(projectInfo.data.data.info.startDate),
                    endDate: transformDate(projectInfo.data.data.info.endDate)
                };
            }
            $("#create-project-form").modal('toggle');
        };

        const editTask = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers();
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const taskInfo = await getTask({ "id": id });
            if (taskInfo.data.success) {
                taskData.value = taskInfo.data.data.info;
                taskData.value = {
                    id: taskInfo.data.data.info.id,
                    projectId: route.query.id || 0,
                    title: taskInfo.data.data.info.title,
                    body: taskInfo.data.data.info.body,
                    assignedUsers: taskInfo.data.data.assigned ? taskInfo.data.data.assigned?.map((assignedUser) => assignedUser.pivot.userId) : [],
                    managerId: taskInfo.data.data.manager?.id,
                    statusId: taskInfo.data.data.status?.id,
                    totalHours: taskInfo.data.data.info.totalHours,
                    startDate: taskInfo.data.data.info.startDate,
                    endDate: taskInfo.data.data.info.endDate
                };
            }
            $("#create-task-form").modal('toggle');
        };

        const createTask = async () => {
            const tmpAllData = await getClientsServicesStatusesUsers();
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            taskData.value = {
                id: '',
                projectId: route.query.id || 0,
                title: '',
                body: '',
                assignedUsers: [],
                managerId: '',
                statusId: '',
                totalHours: '',
                startDate: '',
                endDate: ''
            };
            $("#create-task-form").modal('toggle');
        };

        const submitTaskForm = async () => {
            const response = await createOrUpdateTask(taskData.value);
            if (response.data.success) {
                fetchData();
                useToast().success(response.data.message);
                $("#create-task-form").modal('toggle');
            } else {
                useToast().error(response.data.message);
            }
        };

        const submitForm = async () => {
            const response = await createOrUpdateProject(projectData.value);
            if (response.data.success) {
                const projectDataTmp = await getProject({id: route.query.id || 0});
                if (projectDataTmp.data.success) {
                    projectViewData.value = projectDataTmp.data.data;
                }
                fetchData();
                const projectInfo = await getProject({ "id": route.query.id || 0 });
                if (projectInfo.data.success) {
                    projectData.value = {
                        id: projectInfo.data.data.info.id,
                        projectId: route.query.id || 0,
                        description: projectInfo.data.data.info.description,
                        body: projectInfo.data.data.info.body,
                        assignedUsers: projectInfo.data.data.assigned ? projectInfo.data.data.assigned?.map((assignedUser) => assignedUser.id) : [],
                        managerId: projectInfo.data.data.manager?.id,
                        serviceId: projectInfo.data.data.service?.id,
                        clientId: projectInfo.data.data.client?.id,
                        payable: projectInfo.data.data.info.payable || '0',
                        payManagerId: projectInfo.data.data.payManager?.id,
                        invoiced: projectInfo.data.data.invoiced || '0',
                        paid: projectInfo.data.data.paid || '0',
                        statusId: projectInfo.data.data.status?.id,
                        estimatedTime: projectInfo.data.data.info.estimatedTime,
                        subscriptionDate: projectInfo.data.data.info.subscriptionDate,
                        subscriptionDuration: projectInfo.data.data.info.subscriptionDuration,
                        startDate: transformDate(projectInfo.data.data.info.startDate),
                        endDate: transformDate(projectInfo.data.data.info.endDate)
                    };
                }
                useToast().success(response.data.message);
                $("#create-project-form").modal('toggle');
            } else {
                useToast().error(response.data.message);
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const tinymceInit = {
            height: 300,
            license_key: 'gpl',
            skin_url: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/ui/oxide',
            content_css: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/content/default/content.css'
        };

        const select2SettingsProject = {
            dropdownParent: '#create-project-form .modal-content',
            placeholder: "Please select"
        };

        const select2SettingsTask = {
            dropdownParent: '#create-task-form .modal-content',
            placeholder: "Please select"
        };

        const handleKeyup = async (event) => {
            const value = event.target.value;
            const mentionIndex = value.lastIndexOf('@');
            if (mentionIndex !== -1) {
                const mentionQuery = value.substring(mentionIndex + 1);
                const unamesResponse = await searchUsers({search: mentionQuery});
                if (unamesResponse.data.success) {
                    filteredUsernames.value = unamesResponse.data.data.map((user) => user.info.username);
                } else {
                    filteredUsernames.value = [];
                }
            } else {
                filteredUsernames.value = [];
            }
        };

        const selectUsername = (username) => {
            const commentField = document.querySelector('.comment-val');
            const value = commentField.value;
            const mentionIndex = value.lastIndexOf('@');
            commentField.value = value.substring(0, mentionIndex + 1) + username + ' ';
            filteredUsernames.value = [];
        };

        const scrollToBottomMessages = () => {
            if ($(".chat-history").length) {
                $(".chat-history").animate({ scrollTop: $(".chat-history")[0].scrollHeight }, 1000);
            } else {
                setTimeout(scrollToBottomMessages, 300);
            }
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({path: '/project', query: {...route.query, search: $("#main-search-input").val()}});
            }, 300);
        };

        onMounted(async () => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            const projectDataTmp = await getProject({id: route.query.id || 0});
            if (projectDataTmp.data.success) {
                projectViewData.value = projectDataTmp.data.data;
            }
            scrollToBottomMessages();
        });

        onUnmounted(async () => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
        });

        return { 
            data, 
            submitComment, 
            toggleTaskSelection, 
            submitTaskForm, 
            allData, 
            editTask, 
            taskData, 
            projectViewData, 
            paginationLinks, 
            filters, 
            updateFilters, 
            fetchData, 
            getPageFromUrl, 
            projectData, 
            taskData, 
            submitForm, 
            editProject, 
            createTask, 
            selectedTaskIds, 
            archiveTasks, 
            toggleDropdown, 
            closeDropdown, 
            isDropdownOpen, 
            dropdownStyle, 
            tinymceInit, 
            select2SettingsProject, 
            select2SettingsTask, 
            handleKeyup, 
            filteredUsernames, 
            selectUsername, sortBy 
        };
    },
    metaInfo: {
        title: 'Projects',
        meta: [
            { name: 'description', content: 'MIS - Project' },
            { property: 'og:title', content: 'MIS - Project' },
            { property: 'og:description', content: 'This is the project page.' }
        ]
    }
};
</script>


<style scoped>
.checkbox-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

.card.selected-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 28px;
}

.select2-container--default .select2-selection--single {
    height: 38px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}

.select2-container .select2-selection--single {
    z-index: 9000;
    /* Adjust this value as necessary */
}

.select2-container .select2-selection--multiple {
    z-index: 9000;
    /* Adjust this value as necessary */
}

.select2-dropdown {
    z-index: 9000 !important;
    /* Ensure this is higher than other elements */
}

.modal.show .modal-dialog {
    z-index: 1050;
}

.modal-backdrop.show {
    z-index: 1040;
}

.suggestion-list {
    background-color: white;
    border: 1px solid #ddd;
    margin-top: 5px;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
}

.suggestion-list li {
    padding: 10px;
    cursor: pointer;
}

.suggestion-list li:hover {
    background-color: #f5f5f5;
}
</style>
