<template>
    <div class="body d-flex py-lg-3 py-md-2">
      <div class="container-xxl">
        <!-- Existing Content -->
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="card border-0 no-bg">
              <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-end main-button-area">
                <div class="col-auto d-flex w-sm-100 gap-2">
                   <!-- Bind input to filterDate -->
                <input type="date" class="form-control" v-model="filterDate">
                <!-- Trigger fetch with filter on click -->
                <button type="button" class="btn btn-secondary" @click="applyDateFilter">
                  <i class="icofont-search-2 me-2 fs-6"></i>Search
                </button>
                 </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- missinghours Table -->
        <div class="row clearfix g-3">
          <div class="col-sm-12">
            <div class="card mb-3">
              <div class="card-body table-responsive" style="width: 95%; margin: 0 auto">
                <table id="myProjectTable" class="table table-hover align-middle mb-0 mis_table" style="width: 100%">
                  <thead>
                    <tr>
                      <th style="width:50%;" v-if="superUser">
                      <div  class="d-flex align-items-center">
                        <select class="ms-1 nav-link rounded" name="" id="dropdownManagers"
                          @change="updateManagerFilter">
                          <option value="" selected>User</option>
                          <option v-for="manager in managers" :key="manager.id"
                              :value="manager.id">
                              {{ manager.username }}
                          </option>
                        </select>
                        <img class="menu-img" src="../assets/images/icons8-superman.svg"
                                                        alt="Citrine Logo" style="width:2em" >
                      </div>
                      
                    </th>
                      <th style="width:50%;" class="no-after"><div>Date</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(missinghours, index) in data" :key="missinghours.id">
                      <td>{{ missinghours.username }}</td>
                      <td>{{ missinghours.day }}</td>
                    </tr>
                    <tr v-if="!data || !data.length">
                        <td colspan="1">No data</td>
                    </tr>
                  </tbody>
                </table>
                <nav aria-label="Page navigation" v-if="data && data.length">
                  <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                      <router-link :to="{ path: 'missing-hours', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                        <span class="page-link" v-html="paginationLink.label"></span>
                      </router-link>
                      <span class="page-link" v-else v-html="paginationLink.label"></span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  
  <script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll } from '@/services/missinghours';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import { fetchManagers } from '@/services/followup';


const defaultmissinghoursData = {
  id: '',
  username: '',
  day: ''
};

export default {
  name: 'DaysOff',
  computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
       
    },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const managers = ref([]);
    const filterDate = ref(''); // New ref for filter date


    const fetchManagersData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    managers.value = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch managers:', error);
            }
        };

        const updateManagerFilter = (event) => {
            const newManagerId = event.target.value;
            updateFilters({ ...filters.value, userFilter: newManagerId, page: 1 });
        };


    const toast = useToast();

    const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

    const missinghoursData = ref(defaultmissinghoursData);

    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    // Function to call fetchData with filterDate as a payload
    const applyDateFilter = () => {
      updateFilters({ ...filters.value, filterDate: filterDate.value, page: 1 });
      fetchData();
    };


    const searchAction = () => {
      setTimeout(() => {
        router.push({path: '/missing-hours', query: {...route.query, search: $("#main-search-input").val()}});
      }, 300);
    };

    onMounted(async () => {
        $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
        await fetchData();
        fetchManagersData();

    });

    onUnmounted(() => {
        $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
    });

    return {
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      missinghoursData,
      getPageFromUrl, sortBy,
      updateManagerFilter, managers,
      filterDate, 
      applyDateFilter 

    };
  },
  metaInfo: {
    title: 'Days Off',
    meta: [
      { name: 'description', content: 'MIS - Days Off' },
      { property: 'og:title', content: 'MIS - Days Off' },
      { property: 'og:description', content: 'This is the days off page.' }
    ]
  }
};
</script>

  
  <style scoped>
  .dropdown-menu.show {
    display: block;
  }
  
</style>

  