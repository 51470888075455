import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useFetchData(fetchFunction, initialParams, defaultParams) {
    const route = useRoute();
    const router = useRouter();
    const data = ref([]);
    const paginationLinks = ref([]);
    const filters = ref({ ...defaultParams, ...initialParams });
    const count = ref({ projects: 0, tasks: 0, subtasks: 0 });

    const fetchData = async (params = filters.value) => {
        const response = await fetchFunction({ params });
        if (response.data.success) {
            data.value = response.data.data.data;
            paginationLinks.value = response.data.data.links || response.data.data.pagination?.links || [];
            count.value = response.data.data.count || { projects: 0, tasks: 0, subtasks: 0 };
        } else {
            data.value = [];
            paginationLinks.value = [];
            count.value = { projects: 0, tasks: 0, subtasks: 0 };
        }
    };

    const sortBy = (field) => {
        const currentSortField = route.query.sortBy;
        const currentSortDirection = route.query.sortDirection;
        const newSortDirection = currentSortField === field && currentSortDirection === 'asc' ? 'desc' : 'asc';
        updateFilters({ sortBy: field, sortDirection: newSortDirection });
    };

    const updateFilters = (newFilters) => {
        filters.value = { ...filters.value, ...newFilters };
        router.push({ query: { ...filters.value } });
    };

    watch(() => route.query, (newQuery) => {
        filters.value = { ...defaultParams, ...newQuery };
        fetchData();
    }, { immediate: true });

    watch(filters, () => {
        fetchData();
    });

    onMounted(() => fetchData());

    return { data, paginationLinks, filters, updateFilters, fetchData, count, sortBy };
}
