<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-end main-button-area">
                            <input type="checkbox" class="form-check-input check-uncheck-all" v-model="allChecked" @change="checkUncheckAll">&nbsp; Check/uncheck all

                            <div class="d-flex mt-sm-0 mt-1 justify-content-between">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix g-3">
                <div class="col-lg-12">
                    <div class="mb-1">
                        <div class="card-body table-responsive">
                            <table id="settings" class="table table-hover align-middle mb-0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th data-field="setting.description">Description</th>
                                        <th data-field="settings.enabled">Enabled</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(setting, index) in data" :key="setting.id" :class="{ 'selected': selectedServiceIds.includes(setting.id) }">
                                        <td>
                                            {{ setting.description }}
                                        </td>
                                        <td>
                                            <input type="checkbox" :checked="isChecked(setting)" @change="updateSetting(setting.id, !setting.enabled)">
                                        </td>
                                    </tr>
                                    <tr class="col" v-if="!data || !data.length">
                                        <td colspan="3">
                                            No data
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" v-if="!data || !data.length">
                <p>Δεν βρεθηκαν αποτελέσματα...</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, update, updateAll } from '@/services/notification-settings';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const defaultSettingsData = {
    id: '',
    description: '',
    enabled: '',
};

export default {
    name: 'NotificationSettings',
    setup() {
        const route = useRoute();
        const toast = useToast();

        const { data, paginationLinks, filters, updateFilters, fetchData } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

        const settingsData = ref(defaultSettingsData);
        const selectedServiceIds = ref([]);
        const serviceGroups = ref([]);

        const isDropdownOpen = ref(false);
        const allChecked = ref(false); // Track state of "Check/uncheck all" checkbox

        const toggleServiceSelection = (serviceId) => {
            if (!selectedServiceIds.value.includes(serviceId)) {
                selectedServiceIds.value.push(serviceId);
            } else {
                selectedServiceIds.value = selectedServiceIds.value.filter(id => id !== serviceId);
            }
        };

        const updateSetting = async (id, enabled) => {
            const payload = {
                key: id,
                value: enabled
            };
            const response = await update(payload);
            if (response.data.success) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        };

        const isChecked = (setting) => {
            return setting.enabled === 1;
        };

        const checkUncheckAll = async (event) => {
    const checked = event.target.checked;
    const updateData = {};

    data.value.forEach(setting => {
        const key = `updateData[${setting.id}]`; // Dynamically format the key
        updateData[key] = checked ? 1 : 0;
        // Optionally update local state immediately (if desired)
        setting.enabled = checked ? 1 : 0;
    });

    allChecked.value = checked; // Update allChecked state


    const response = await updateAll(updateData);
    console.log(response)
    if (response.data.success) {
        toast.success(response.data.message);
        fetchData();
    } else {
        toast.error(response.data.message);
    }
};


        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const closeMenu = (event) => {
            const dropdownMenu = document.querySelector('.dropdown-menu');
            const dropdownMenuToggler = document.querySelector('.dropdown-menu-toggler');
            
            if (dropdownMenu && !dropdownMenu.contains(event.target) && dropdownMenuToggler && !dropdownMenuToggler.contains(event.target)) {
                isDropdownOpen.value = false;
            }
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        onMounted(() => {
            document.addEventListener('click', closeMenu);
        });

        onUnmounted(() => {
            document.removeEventListener('click', closeMenu);
        });

        return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, settingsData, updateSetting, selectedServiceIds, toggleServiceSelection, checkUncheckAll, dropdownStyle, closeDropdown, isDropdownOpen, toggleDropdown, serviceGroups, isChecked, allChecked };
    },
    metaInfo: {
        title: 'Notifications Settings',
        meta: [
            { name: 'description', content: 'MIS - Services' },
            { property: 'og:title', content: 'MIS - Services' },
            { property: 'og:description', content: 'This is the services page.' }
        ]
    }
};
</script>
