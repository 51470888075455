import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createMetaManager } from 'vue-meta';
import unescapeHtml from './directives/unescapeHtml';
import './assets/css/my-task.style.min.css';
import './assets/css/my-cstm.css';
import 'vue-toastification/dist/index.css';
import Toast from 'vue-toastification';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // Includes Popper.js
// import 'vue-select/dist/vue-select.css';  // Importing vue-select CSS
import 'select2/dist/css/select2.css';
import 'jquery';

/* import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'; */

// Import TinyMCE and plugins
import 'tinymce/tinymce.min.js';
import 'tinymce/models/dom';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/icons/default/icons.min.js';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import store from './store';


const metaManager = createMetaManager();
const toastOptions = {
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
};

createApp(App)
.directive('unescape-html', unescapeHtml)
.use(router)
.use(Toast, toastOptions)
.use(metaManager)
.use(store)
.mount('#app');