<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row g-3">
                <!-- Left Column with Tabs -->
                <div class="col-xl-8 col-lg-7 col-md-12" style="border-right:lightgray 2px solid;
    padding-right: 10px;">
    
                   
                    <!-- SubTask Header with Custom Status Dropdown -->
                    <div class="d-flex align-items-center justify-content-start mb-3 mt-2">
                        <div class="d-inline-flex align-items-center ms-2">
        <!-- Circle with 'P' -->
        <span class="project-circle2">P</span>

        <!-- Pipe separator -->
        <span class="ms-2" style="font-size:1.5rem">|</span>

         <!-- Avatar image, only shown if projectViewData and projectViewData.client exist -->
         <img v-if="subTaskViewData && subTaskViewData?.task.project?.client?.avatar_file_name"
             :src="`/storage/clients_avatars/${subTaskViewData.task.project.client.avatar_file_name}`"
             style="width:10em">

        <span>
            <span v-if="subTaskViewData && subTaskViewData.project?.code" style="font-size:1.2rem">{{ subTaskViewData.project.code }}</span>
        </span> 
    </div>
</div>

<div class="d-flex align-items-center justify-content-start mb-3 mt-2">
                        <div class="d-inline-flex align-items-center ms-2">
        <!-- Circle with 'P' -->
        <span class="project-circle2">T</span>

        <!-- Pipe separator -->
        <span class="ms-2" style="font-size:1.5rem">|</span>

        

        <span class="ms-2">
            <span v-if="subTaskViewData && subTaskViewData.task?.title" style="font-size:1.2rem">{{ subTaskViewData.task.title }}</span>
        </span> 
    </div>
</div>

<div class="d-flex align-items-center justify-content-start mb-3 mt-2">
    <div class="d-inline-flex align-items-center ms-2">

    <span class="project-circle">S</span>
                        <span class="project-code ms-2" v-if="subTaskViewData">{{ subTaskViewData.info?.title }}</span>

                        <div class="status-dropdown ms-3">
                            <button class="status-button justify-content-center" v-if="subTaskViewData" :style="getStatusStyle(subTaskViewData.status?.id)">
                                <i :class="getStatusIcon(subTaskViewData.status?.id)" v-if="canEditSubtask(subTaskViewData) || canEditAllSubtasks || superUser"></i>
                                {{ getCurrentStatus(subTaskViewData.status?.id) }}
                                <i class="fa fa-caret-down" v-if="canEditSubtask(subTaskViewData) || canEditAllSubtasks || superUser"></i>
                            </button>

                            <!-- Dropdown Content (Visible on Hover) -->
                            <div class="status-dropdown-content" v-if="canEditSubtask(subTaskViewData) || canEditAllSubtasks || superUser">
                                <button v-for="status in statuses" :key="status.id"
                                    @click="selectStatus(subTaskViewData.info?.id, status.id)" :style="getStatusStyle(status.id)"
                                    :class="{ 'active-status': subTaskViewData?.status.id === status.id }">
                                    <i :class="status.icon"></i>
                                    {{ status.description }}
                                </button>
                            </div>
                        </div>
                        <!-- Button Positioned Over Border -->
                        <div class="status-button-wrapper"  v-if="subTaskViewData && (canEditSubtask(subTaskViewData) || canEditAllSubtasks || superUser)">
                            <button class="btn btn-dark" @click="editSubTask(route.query.id)">
                                <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img" style="width:2em">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-start mb-1 ms-2">

<router-link :to="{ path: 'project', query: { id: subTaskViewData.project?.id } }" v-if="subTaskViewData">
            <span class="breadcrumb-links">{{ subTaskViewData.project?.code }}</span>
        </router-link> <span class="ms-2 me-2">/</span><router-link :to="{ path: 'task', query: { id: subTaskViewData.task?.id } }" v-if="subTaskViewData">
            <span class="breadcrumb-links">{{ subTaskViewData.task?.title }}</span>
        </router-link> <span class="ms-2 me-2">/</span><router-link :to="{ path: 'subtask', query: { id: subTaskViewData.info?.id } }" v-if="subTaskViewData">
            <span class="breadcrumb-links">{{ subTaskViewData.info?.title }}</span>
        </router-link>
</div>
                    <!-- Tabs -->
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'info' }"
                                @click.prevent="activeTab = 'info'" href="#">Info</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'managerBrief' }"
                                @click.prevent="activeTab = 'managerBrief'" href="#">Manager's Brief</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'subTaskChat' }"
                                @click.prevent="activeTab = 'subTaskChat'" href="#">SubTask Chat</a>
                        </li>
                    </ul>

                    <!-- Tab Content -->
                    <div class="tab-content">
                        <!-- Info Tab -->
                        <div v-if="activeTab === 'info'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body">
                                    <div v-html="subTaskViewData.info?.body" v-if="subTaskViewData"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Manager's Brief Tab -->
                        <div v-if="activeTab === 'managerBrief'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body">
                                    <div v-html="subTaskViewData.info?.manager_brief" v-if="subTaskViewData"></div>
                                </div>
                            </div>
                        </div>

                        <!-- SubTask Chat Tab -->
                        <div v-if="activeTab === 'subTaskChat'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body">
                                    <ul ref="chatHistory" class="chat-history list-unstyled mb-3">
                                        <li class="mb-3 d-flex" v-if="subTaskViewData && subTaskViewData.comments"
                                            v-for="comment in subTaskViewData.comments" :key="comment.id"
                                            :class="{
                                            'justify-content-end': comment.author?.id == currentUserId,
                                            'justify-content-start': comment.author?.id != currentUserId
                                             }">
                                             <div style="width:50%;">
                                                <span class="text-muted small d-flex align-items-center" :class="{
                            'justify-content-end': comment.author?.id == currentUserId,
                            'justify-content-start': comment.author?.id != currentUserId
                        }">
                                                <img v-if="comment.author?.avatar_file_name"
                                                    :src="`/storage/avatars/${comment.author?.avatar_file_name}`"
                                                    width="25"><h6 class=fw-bolder>{{ comment.author.username }}</h6>

                                            </span>
                                            <div
                            class="chat-message p-2 mt-1 rounded"
                            
                        >
                            {{ comment.info?.comment }}
                        </div>
                        <span class="text-muted small">
                            <i class="icofont icofont-date"></i><img src="../assets/images/diary.png" alt="Edit" title="Edit" class="menu-img" style="width:1.5em">{{ comment.info.created_at }}
                        </span>
                    </div>
                                                
                                        </li>
                                    </ul>
                                    <div class="d-flex">
    <textarea
        id="commentTextarea"
        class="form-control position-relative"
        placeholder="Αποστολή Μηνύματος"
        v-model="newComment"
        @input="filterUsernames"
    ></textarea>

    <!-- Autocomplete Dropdown -->
    <div
        v-if="showAutocomplete"
        class="autocomplete-dropdown"
        :style="{ 
            top: `${dropdownTop}px`, 
            left: `${dropdownLeft}px`, 
            width: `${dropdownWidth}px` 
        }"
    >
        <ul class="list-unstyled m-0 p-2">
            <li
                v-for="user in filteredUsernames"
                :key="user.id"
                @click="selectUsername(user.username)"
                class="autocomplete-item"
            >
                {{ user.username }}
            </li>
        </ul>
    </div>
    <button @click.prevent="submitComment" class="btn sendComment mt-2">
        <i class="fa fa-paper-plane"></i>
    </button>
</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Column with SubTask Details -->
                <div class="col-xl-4 col-lg-5 col-md-12" v-if="activeTab != 'subTasks' && subTaskViewData">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12"><strong>Start Date:</strong> {{ subTaskViewData.info.startDate }}</div>
                                <div class="col-12"><strong>End Date:</strong> {{ subTaskViewData.info.endDate }}</div>
                                <br/><br/>
                                <div class="col-12"><strong>Creator:</strong> <img v-if="subTaskViewData?.creator?.avatar_file_name" :src="`/storage/avatars/${subTaskViewData?.creator?.avatar_file_name}`" width="25" :title="subTaskViewData.creator.username"/></div>
                                <div class="col-12"><strong>Manager:</strong> <img v-if="subTaskViewData?.manager?.avatar_file_name" :src="`/storage/avatars/${subTaskViewData?.manager?.avatar_file_name}`" width="25" :title="subTaskViewData.manager.username"/></div>
                                <div style="display: inline-flex;">
                                    <div class="col-12"><strong>Assigned Users:</strong>
                                        <div v-for="user in subTaskViewData.assigned" :key="user.id"
                                            style="display: inline-flex; align-items: center; margin-right: 10px;">
                                            <img v-if="user?.avatar_file_name"
                                                :src="`/storage/avatars/${user.avatar_file_name}`" width="25" :title="user.username"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Column with SubTask Details -->
                <div class="col-xl-4 col-lg-5 col-md-12" v-if="activeTab == 'subTasks'">
                    <div class="card">
                        <div class="card-body">
                            <div class="filter-section mt-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div>
                                        <button type="button" class="btn btn-dark create-subtask-button"
                                            @click.prevent="createSubtask">
                                            Create Subtask
                                        </button>
                                    </div>
                                    <div>
                                        <input id="main-search-input" type="search" class="form-control"
                                            placeholder="Search..." aria-label="search"
                                            aria-describedby="addon-wrapping" />
                                    </div>
                                </div>

                                <!-- Status Tabs -->
                                <ul class="nav nav-tabs tab-body-header rounded w-100 mb-2" role="tablist">
                                    <li class="nav-item">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '0' || filters.statusId == undefined,
                                            'nav-link text-center': filters.statusId != '0' && filters.statusId != undefined,
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '0', page: 1 })"
                                            href="#" role="tab">
                                            All
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '1',
                                            'nav-link text-center': filters.statusId != '1',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '1', page: 1 })"
                                            href="#" role="tab">
                                            On Hold
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '2',
                                            'nav-link text-center': filters.statusId != '2',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '2', page: 1 })"
                                            href="#" role="tab">
                                            In Progress
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '3',
                                            'nav-link text-center': filters.statusId != '3',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '3', page: 1 })"
                                            href="#" role="tab">
                                            Done
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '4',
                                            'nav-link text-center': filters.statusId != '4',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '4', page: 1 })"
                                            href="#" role="tab">
                                            Archived
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '5',
                                            'nav-link text-center': filters.statusId != '5',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '5', page: 1 })"
                                            href="#" role="tab">
                                            On Going
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- Date Filters -->
                            <div class="mb-3 d-flex align-items-center">
                                <label for="start-date" class="form-label me-2">Start date:</label>
                                <input type="date" id="start-date" v-model="startDate" class="form-control w-auto" />
                            </div>

                            <div class="mb-3 d-flex align-items-center">
                                <label for="end-date" class="form-label me-2">End date:</label>
                                <input type="date" id="end-date" v-model="endDate" class="form-control w-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Create SubTask -->
    <div class="modal fade" id="create-subtask-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold">Edit SubTask</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <input type="hidden" name="projectId" v-model="subTaskData.projectId">
                <div class="modal-body">
                    <!-- SubTask Data Form -->
                    <div class="mb-3">
                        <label class="form-label">Title</label>
                        <input type="text" class="form-control" placeholder="Title" name="title"
                            v-model="subTaskData.title">
                    </div>
                    <div class="mb-3" v-if="(subTaskData && subTaskData.createdBy == currentUserInfo?.id) || superUser">
                        <label for="subTaskDescriptionTinyMce" class="form-label">Creator's brief</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="subTaskData.body"></Editor>
                    </div>
                    <div class="mb-3" v-if="(subTaskData && subTaskData.managerId == currentUserInfo?.id) || superUser">
                        <label for="subTaskManagerBriefTinyMce" class="form-label">Manager's brief</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="subTaskData.manager_brief"></Editor>
                    </div>
                    <div class="mb-3" v-if="superUser || canEditSubTaskCreator">
                        <label class="form-label">Creator</label>
                        <Select2 :options="allData.users" v-model="subTaskData.createdBy"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Status</label>
                        <Select2 :options="allData.statuses" v-model="subTaskData.statusId"
                            :settings="select2SettingsSubTask" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Manager</label>
                        <Select2 :options="allData.users" v-model="subTaskData.managerId"
                            :settings="select2SettingsSubTask" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Assigned users</label>
                        <Select2 :options="allData.users" v-model="subTaskData.assignedUsers"
                            :settings="{ ...select2SettingsSubTask, multiple: true }" />
                    </div>
                    <div class="deadline-form">
                        <form>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="subTaskStartDate" class="form-label">Start Date</label>
                                    <input type="date" class="form-control" name="startDate"
                                        v-model="subTaskData.startDate">
                                </div>
                                <div class="col">
                                    <label for="subTaskEndDate" class="form-label">End Date</label>
                                    <input type="date" class="form-control" name="endDate" v-model="subTaskData.endDate">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="create-project-errors"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="submitSubTaskForm">Update</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="archiveConfirmationModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold">Confirm Archive</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to archive this subtask?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="confirmArchive">Yes, Archive</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { getClientsServicesStatusesUsers, createComment } from '@/services/subtasks';
import { fetchAll } from '@/services/subtasks';
import { get as getTask, searchUsers } from '@/services/tasks';
import { get as getSubtask } from '@/services/subtasks';
import { createOrUpdate as createOrUpdateSubtask, massArchiveSubTasks } from '@/services/subtasks';
import { createOrUpdate as createOrUpdateTask } from '@/services/tasks';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';
import { fetchUserInfo } from '@/services/user';
import { all } from 'axios';
import { updateTaskStatus } from '@/services/todo';
import { updateSubtaskStatus } from '@/services/followup';

export default {
    name: 'Task',
    components: {
        Editor,
        Select2
    },
    methods: {
        canEditSubtask(subtask) {
            if (!subtask) { return false; }
            return localStorage.getItem('current_user_id') == subtask.info?.createdBy && this.userPermissionsSubTasks?.edit === 1;
        }
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission'
        }),
        userPermissionsSubTasks() {
            return this.getUserPermission('Subtasks');
        },
        canCreate() {
            return this.userPermissionsTasks?.create === 1;
        },
        canUpdate() {
            return this.userPermissionsTasks?.create === 1;
        },
        canArchive() {
            return this.userPermissionsSubTasks?.archive === 1;
        },
        userPermissionsTasks() {
            return this.getUserPermission('Tasks');
        },
        canEditSubTaskCreator() {
            return this.userPermissionsSubTasks?.editCreator === 1;
        },
        canEditTask() {
            return this.userPermissionsTasks?.edit === 1;
        },
        canEditAllTasks() {
            return this.userPermissionsTasks?.editAll === 1;
        },
        canEditAllSubtasks() {
            return this.userPermissionsSubTasks?.editAll === 1;
        },
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        getUser() {
            return this.$store.state.userInfo.id;
        },
        currentUserId() {
            return localStorage.getItem('current_user_id'); // Safely retrieve the ID once
        }
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();
        const activeTab = ref('info');
        const newComment = ref('');
        const startDate = ref('');
        const endDate = ref('');
        const showAutocomplete = ref(false);
        const subtaskIdToArchive = ref(null); // Store the ID of the task to archive
        const users = ref([]);
        const subTaskViewData = ref(null);

        const openArchiveConfirmation = (subtaskId) => {
            subtaskIdToArchive.value = subtaskId;
            $('#archiveConfirmationModal').modal('show'); // Show the confirmation modal
        };

        const confirmArchive = async () => {
            if (!subtaskIdToArchive.value) return;

            try {
                const response = await massArchiveSubTasks({ ids: [subtaskIdToArchive.value] });
                if (response.data.success) {
                    useToast().success('Subtask archived successfully');
                    fetchData(); // Refresh the subtasks list
                } else {
                    useToast().error('Failed to archive subtask');
                }
            } catch (error) {
                useToast().error('An error occurred while archiving');
            } finally {
                $('#archiveConfirmationModal').modal('hide'); // Close the modal
                subtaskIdToArchive.value = null;
            }
        };

        const filterUsernames = () => {
            const input = newComment.value;
            const match = input.match(/@(\w*)$/); // Match @username pattern

            if (match) {
                const searchTerm = match[0].substring(1); // Extract text after '@'

                  // Call the filtering logic only if there are at least 2 characters after "@"
                if (searchTerm.length >= 2) {

                const query = match[1].toLowerCase();
                filteredUsernames.value = users.value.filter(user =>
                    user.username.toLowerCase().includes(query)
                );
                showAutocomplete.value = filteredUsernames.value.length > 0;
                }else {
                showAutocomplete.value = false;
            }
            } else {
                showAutocomplete.value = false;
            }
        };

        // Watch for changes to startDate and endDate and update URL filters
        watch([startDate, endDate], ([newStartDate, newEndDate]) => {
            router.push({
                path: '/task',
                query: { ...route.query, startDate: newStartDate, endDate: newEndDate },
            });
        });

        const subTaskData = ref({
            id: '',
            taskId: '0',
            title: '',
            description: '',
            assignedUsers: [],
            managerId: '',
            statusId: '',
            startDate: '',
            endDate: ''
        });

        const allData = ref({});
        const taskViewData = ref(null);
        const selectedSubTaskIds = ref([]);
        const isDropdownOpen = ref(false);
        const filteredUsernames = ref([]);
        const currentUserInfo = ref(null);

        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { id: route.query.id, page: route.query.page || 1, archived: route.query.archived || 0, statusId: route.query.statusId || 2 }, { id: route.query.id, page: 1, archived: 0, statusId: 2 });

        const toggleSubTaskSelection = (subTaskId) => {
            if (!selectedSubTaskIds.value.includes(subTaskId)) {
                selectedSubTaskIds.value.push(subTaskId);
            } else {
                selectedSubTaskIds.value = selectedSubTaskIds.value.filter(id => id !== subTaskId);
            }
        };

        const createSubtask = async () => {
            const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "subtasks" } });
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            subTaskData.value = {
                taskId: route.query.id || 0,
                title: '',
                manager_brief: '',
                body: '',
                assignedUsers: [],
                managerId: '',
                statusId: '0',
                totalHours: '',
                startDate: '',
                endDate: ''
            };
            $("#create-subtask-form").modal('toggle');
        };

        const submitComment = async () => {
            const response = await createComment({ "id": route.query?.id, comment: newComment.value });
            if (response.data.success) {
                newComment.value = '';
                subTaskViewData.value = (await getSubtask({ id: route.query?.id })).data.data;
                $(".chat-history").animate({ scrollTop: $(".chat-history")[0].scrollHeight }, 1000);
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        };

        const editSubTask = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "subtasks" } });
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const subTaskInfo = await getSubtask({ id });
            let subtaskDataValue = subTaskInfo.data.data.info;
            subtaskDataValue.managerId = subTaskInfo.data.data.manager?.id;
            subtaskDataValue.statusId = subTaskInfo.data.data.status?.id;
            subtaskDataValue.assignedUsers = subTaskInfo.data.data.assigned?.map((u) => u.id);
            subtaskDataValue.projectId = subTaskInfo.data.data.project?.id;
            subTaskData.value = subtaskDataValue;
            $("#create-subtask-form").modal('toggle');
        };

        const submitSubTaskForm = async () => {
            const response = await createOrUpdateSubtask(subTaskData.value);
            response.data.success ? toast.success(response.data.message) : toast.error(response.data.message);
            subTaskViewData.value = (await getSubtask({ id: route.query.id })).data.data;
            fetchData();
            $("#create-subtask-form").modal('toggle');
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const getStatusStyle = (id) => {
            switch (id) {
                case 2:
                    return 'background: #1CA6F1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em; font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; align-items:center; height:2.5em';
                case 3:
                    return 'background: #A5CF27; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center;  height:2.5em';
                case 1:
                    return 'background: #B1B1B1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 5:
                    return 'background: #ECC308; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 4:
                    return 'background: #796EEE; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 6:
                    return 'background: #E4364D; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                default:
                    return 'text-align:center';
            }
        };

        const tinymceInit = {
            height: 300,
            license_key: 'gpl',
            skin_url: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/ui/oxide',
            content_css: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/content/default/content.css'
        };

        const select2SettingsTask = {
            dropdownParent: '#create-task-form .modal-content',
            placeholder: "Please select"
        };

        const selectUsername = (username) => {
            newComment.value = newComment.value.replace(/@(\w*)$/, `@${username} `);
            filteredUsernames.value = [];
            showAutocomplete.value = false;
        };

        const selectStatus = async (taskId, statusId) => {
            const r = await updateSubtaskStatus(taskId, statusId);
            if (r.data.success) {
                useToast().success(r.data.message);
                subTaskViewData.value = (await getSubtask({ id: route.query.id })).data.data;
                fetchData();
            } else {
                useToast().error(r.data.message);
            }
        };

        const statuses = ref([]);

        const getStatusIcon = (id) => {
            const status = statuses.value.find((s) => s.id === id);
            return status ? status.icon : "";
        };

        const getCurrentStatus = (id) => {
            const status = statuses.value.find((s) => s.id === id);
            return status ? status.description : "Select Status";
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/task', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const handleClickOutside = (event) => {
    const dropdown = document.querySelector('.autocomplete-dropdown');
    const textarea = document.getElementById('commentTextarea');

    if (
        (dropdown && !dropdown.contains(event.target)) &&
        (!textarea || !textarea.contains(event.target))
    ) {
        showAutocomplete.value = false;
    }
};


        onMounted(async () => {
            const allRequiredData = await getClientsServicesStatusesUsers({ params: { type: "subtasks" } });
            statuses.value = allRequiredData.data.statuses;
            users.value = allRequiredData.data.users;
            currentUserInfo.value = (await fetchUserInfo()).data;
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            subTaskViewData.value = (await getSubtask({ id: route.query.id })).data.data;
            document.addEventListener('click', handleClickOutside);

        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
            document.removeEventListener('click', handleClickOutside);

        });

        const updateSubtaskStatusId = async (subtaskId, statusId) => {
            const r = await updateSubtaskStatus(subtaskId, statusId);
            if (r.data.success) {
                useToast().success(r.data.message);
                subTaskViewData.value = (await getSubtask({ id: route.query.id })).data.data;
                fetchData();
            } else {
                useToast().error(r.data.message);
            }
        };

        return {
            showAutocomplete,
            selectUsername,
            filterUsernames,
            filteredUsernames,
            confirmArchive,
            openArchiveConfirmation,
            startDate,
            endDate,
            newComment,
            updateSubtaskStatusId,
            createSubtask,
            selectStatus,
            data,
            filters,
            updateFilters,
            paginationLinks,
            activeTab,
            route,
            getPageFromUrl,
            statuses,
            getStatusIcon,
            getCurrentStatus,
            searchAction,
            allData,
            subTaskData,
            subTaskViewData,
            selectedSubTaskIds,
            toggleDropdown,
            closeDropdown,
            isDropdownOpen,
            dropdownStyle,
            tinymceInit,
            select2SettingsTask,
            submitSubTaskForm,
            submitComment,
            editSubTask,
            filteredUsernames,
            selectUsername,
            getStatusStyle
        };
    },
};
</script>

<style scoped>
.chat-message {
    color: black !important;
}
.project-code {
    font-size: 1.5rem;
    font-weight: bold;
}

.status-dropdown {
    position: relative;
    display: inline-block;
}

.status-button {
    background-color: #f1f1f1;
    color: #333;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
   
}

.status-dropdown:hover .status-dropdown-content {
    display: flex;
}

.status-dropdown-content {
    z-index: 9000;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
    display: none;
    flex-direction: column;
    
}

.status-dropdown >button{
    height:1.5em;
}

.status-dropdown-content button {
    width: 100%;
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom:3px;

}

.status-dropdown-content button:hover {
    background-color: #461010;
}

.active-status {
    font-weight: bold;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}

.create-task-button {
    width: 100%;
}
.autocomplete-dropdown {
    position: absolute;
    z-index: 1000;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Limit height */
    overflow-y: auto;
}

.autocomplete-dropdown ul {
    padding: 0;
    margin-top: 110px;
}

.autocomplete-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.autocomplete-item:hover {
    background-color: #f0f0f0;
    color: #333;
}

textarea {
    resize: none;
    overflow: auto;
}

textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}


textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.nav-tabs{
    border:none;
}

/* Style for all list items to have a bottom border */
.nav-tabs .nav-item {
  border-bottom: 1px solid #DCDCDC; /* Light gray border for non-active items */
  margin-left:20px;
}

.nav-tabs .nav-item .nav-link {
  color:#8C8C8C;
}

/* Style for the active tab to have a darker bottom border */
.nav-tabs .nav-item .nav-link.active,  .nav-tabs .nav-item .nav-link:hover{
    border-color: white;

  border-bottom: 3px solid #8C8C8C; /* Darker gray border for the active tab */
  color:black;

}


/* Optionally, you can remove the bottom border for non-active tabs on hover to make the UI clearer */

/* Add this CSS to ensure the button is placed on the right border */
.col-xl-8.col-lg-7.col-md-12 {
  position: relative; /* Make the parent div relative */
}

/* Style the button to be positioned absolutely at the border-right */
.status-button-wrapper {
  position: absolute; /* Position it absolutely within the parent */
  top: 50px; /* Vertically center the button */
  right: -1.5em; /* Position it slightly outside to touch the border-right */
  transform: translateY(-50%); /* Adjust for exact vertical centering */
  z-index: 10; /* Ensure the button stays above other elements */
}
</style>
