<template>
  <component :is="layout">
    <router-view />
  </component>
  <div v-if="isLoading" class="loading-spinner d-flex justify-content-center align-items-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import LoginLayout from './layouts/LoginLayout.vue';
import MainLayout from './layouts/MainLayout.vue';
import RegisterLayout from './layouts/RegisterLayout.vue';
import loadingStore from './loading'; // Make sure this path is correct

export default {
  name: 'App',
  components: {
    LoginLayout,
    MainLayout,
    RegisterLayout
  },
  setup() {
    const isLoading = computed(() => loadingStore.isLoading.value);
    const route = useRoute();
    const layout = computed(() => route.meta.layout || 'MainLayout');
    return { layout, isLoading };
  }
};
</script>

<style>
.loading-spinner {
  position: fixed;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  margin-top: 200px; /* Adjust this value to move the spinner down */
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
