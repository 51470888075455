<template>
  <div class="container-xxl">
    <div class="row clearfix">
      <div class="align-items-center">
        <div class="border-0 mb-1">
          <div
            class="card-header py-1 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between flex-wrap">
            <div class="col-auto d-flex w-sm-100 gap-2">
  <label class="form-check-label">
    <input 
      type="checkbox" 
      class="form-check-input" 
      v-model="archived" 
      :true-value="1" 
      :false-value="0" 
      @change="toggleArchived"
    >
    Show archived projects/tasks
  </label>
</div>


            <div class="col-auto d-flex w-sm-100 gap-2">
              <input type="date" class="form-control" v-model="selectedDate" @change="handleDateChange">
              <button type="button" class="btn btn-secondary" @click.prevent="handleDateChange"><i
                  class="icofont-search-2 me-2 fs-6"></i>Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card mb-2">
        <div class="card-body table-responsive">
          <table id="firstRowTable" class="table align-middle mb-0 mis_table" style="width:100%">
      <thead>
        <tr>
          <th style="width:20%;">Project</th>
          <th style="width:20%;">Task</th>
          <th style="width:20%;">From</th>
          <th style="width:20%;">To</th>
          <th style="width:20%;">Description</th>
          <th class="no-after"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white mb-5">
          <td><Select2 :options="projects" v-model="formRow.projectId" :settings="select2SettingsProject" @select="handleProjectChange(-1)" /></td>
          <td><Select2 :options="formRow.tasks" v-model="formRow.taskId" :settings="select2Settings" /></td>
          <td><input type="time" min="09:00" max="17:00" class="form-control" v-model="formRow.from"></td>
          <td><input type="time" min="09:00" max="17:00" class="form-control" v-model="formRow.to"></td>
          <td><input type="text" class="form-control" v-model="formRow.body" placeholder="Description..."></td>
          <td><button type="button" class="btn btn-secondary" @click="addFormRowAsHourRow" :disabled="!isFormRowComplete"><i class="icofont-ui-add text-white"></i></button></td>
        </tr>
      </tbody>
    </table>
        </div>
        <div class="card-body table-responsive additional-rows-table mt-4">
        <table id="additionalRowsTable" class="table align-middle mb-0 mis_table" style="width:100%">
          <tbody>
            <tr v-for="(row, index) in hoursRows" :key="index" class="row-padding">
              <td><Select2 :options="projects" v-model="row.projectId" :settings="select2SettingsProject" @select="handleProjectChange(index)" /></td>
              <td><Select2 :options="row.tasks" v-model="row.taskId" :settings="select2Settings" /></td>
              <td><input type="time" min="09:00" max="17:00" class="form-control" v-model="row.from"></td>
              <td><input type="time" min="09:00" max="17:00" class="form-control" v-model="row.to"></td>
              <td><input type="text" class="form-control" v-model="row.body" placeholder="Description..."></td>
              <td><button type="button" class="btn btn-secondary" @click="removeHourRow(index)"><i class="icofont-ui-delete text-white"></i></button></td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      <button type="button" class="w-sm-100 btn btn-secondary submit-hours" @click="confirmAndSubmitHours"><i
          class="icofont-lock me-2 fs-6"></i>Αποθήκευση</button>
    </div>
  </div>
  <!-- Modal-->
  <div class="modal fade" id="complete-hours-form" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold" id="createprojectlLabel">Επιβεβαίωση καταχώρησης</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Πρόκειται να προχωρήσετε σε καταχώρηση ωρών εργασίας. Το σύνολο των ωρών που θα καταχωρηθούν είναι: <span
              id="totalHoursConfirm">8 ώρες</span></p>
        </div>
        <div class="modal-footer">
<!--           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
 -->          <button type="button" class="btn btn-secondary" @click.prevent="submitHours">Καταχώρηση ωρών</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import apiClient from '../services/api';

export default {
  name: 'Hours',
  components: {
    Select2
  },
  setup() {
    const toast = useToast();
    const selectedDate = ref(new Date().toISOString().slice(0, 10));
    const projects = ref([]);
    const formRow = ref({
      projectId: '',
      taskId: '',
      from: '',
      to: '',
      body: '',
      tasks: []
    });
    const hoursRows = ref([]);
    const archived = ref(0);
    const selectedProjectId = ref(null);
    const selectedIndex = ref(null);

    const fetchProjects = async () => {
      try {
        const response = await apiClient.get('get-user-projects', {
          params: { archived: archived.value },
          headers: {
            "Authorization": "Bearer " + getAuthTokenFromCookie()
          }
        });
        if (response.data.success) {
          projects.value = response.data.data.map(project => ({
            text: project.code,
            id: project.id
          }));
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('An error occurred while fetching projects');
        console.error(error);
      }
    };

    // Computed property to check if the first row is complete
    const isFirstRowComplete = computed(() => {
      const firstRow = hoursRows.value[0];
      return firstRow.projectId && firstRow.taskId && firstRow.from && firstRow.to && firstRow.body;
    });

    // Script
const toggleArchived = () => {
  fetchProjects();
  if (selectedProjectId.value !== null && selectedIndex.value !== null) {
    fetchTasks(selectedProjectId.value, selectedIndex.value);
  }
};


    const fetchTasks = async (projectId, index) => {
      try {
        const response = await apiClient.get(`get-project-tasks?id=${projectId}`, {
          params: { archived: archived.value },
          headers: {
            "Authorization": "Bearer " + getAuthTokenFromCookie()
          }
        });
        if (response.data.success) {
          formRow.value.tasks = response.data.data?.data?.map(task => ({
            text: task.info.title,
            id: task.info.id
          }));
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('An error occurred while fetching tasks');
        console.error(error);
      }
    };

    const handleProjectChange = (index) => {
      const projectId = formRow.value.projectId;
      selectedProjectId.value = projectId;
      selectedIndex.value = index;
      fetchTasks(projectId, index);
    };

    const handleDateChange = () => {
      fetchHours(selectedDate.value);
    };

     // Computed property to check if form row is complete
     const isFormRowComplete = computed(() => {
      return formRow.value.projectId && formRow.value.taskId && formRow.value.from && formRow.value.to && formRow.value.body;
    });

    const fetchHours = async (date) => {
      try {
        const response = await apiClient.get(`get-user-hours?date=${date}`, {
          headers: {
            "Authorization": "Bearer " + getAuthTokenFromCookie()
          }
        });

        if (response.data.success) {
          // Populate hoursRows with fetched data
          hoursRows.value = response.data.data.map(hour => ({
            projectId: hour.info.projectId,
            taskId: hour.info.taskId,
            from: hour.info.from,
            to: hour.info.to,
            body: hour.info.body,
            tasks: hour.tasks.map(task => ({
              text: task.title,
              id: task.id,
              selected: task.selected
            }))
          }));
        } else {
          // Initialize with empty array if no data
          hoursRows.value = [];
        }

        // Clear formRow for new input
        formRow.value = {
          projectId: '',
          taskId: '',
          from: '',
          to: '',
          body: '',
          tasks: []
        };

      } catch (error) {
        toast.error('An error occurred while fetching hours');
        console.error(error);
      }
    };

    // Add form row to hoursRows as a new row and reset form row
    const addFormRowAsHourRow = () => {
      if (isFormRowComplete.value) {
        hoursRows.value.push({ ...formRow.value });
        formRow.value = {
          projectId: '',
          taskId: '',
          from: '',
          to: '',
          body: '',
          tasks: []
        };
      }
    };

    const removeHourRow = (index) => {
      hoursRows.value.splice(index, 1);
    };


    const confirmAndSubmitHours = () => {
      const totalHours = calculateTotalHours();
      $("#totalHoursConfirm").text(totalHours);
      $("#complete-hours-form").modal('toggle');
    };

    const calculateTotalHours = () => {
  const totalHours = hoursRows.value.reduce((total, row) => {
    if (row.projectId && row.taskId && row.from && row.to && row.body) {
      const fromTime = new Date(`1970-01-01T${row.from}:00`);
      const toTime = new Date(`1970-01-01T${row.to}:00`);
      const diff = (toTime - fromTime) / (1000 * 60 * 60); // Convert milliseconds to hours
      return total + diff;
    } else {
      return total;
    }
  }, 0);

  return parseFloat(totalHours.toFixed(2)); // Round to 2 decimal places
};


    const submitHours = async () => {
      let rows = hoursRows.value.map(row => ({
        projectId: row.projectId,
        taskId: row.taskId,
        from: row.from,
        to: row.to,
        body: row.body
      }));
      // Filtering rows to ensure no field is null or empty
      rows = rows.filter(row =>
        row.projectId && row.taskId && row.from && row.to && row.body
      );
      try {
        const response = await apiClient.post('save-hours', {
          date: selectedDate.value,
          hours: rows
        }, {
          headers: {
            "Authorization": "Bearer " + getAuthTokenFromCookie()
          }
        });
        if (response.data.success) {
          $("#complete-hours-form").modal('toggle');
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('An error occurred while saving hours');
        console.error(error);
      }
    };

    onMounted(() => {
      fetchProjects();
      fetchHours(new Date().toISOString().slice(0, 10)); // Load hours for today
    });

    const getAuthTokenFromCookie = () => {
      return localStorage.getItem('api_token');
    };

    const select2Settings = {
      placeholder: "Select Task"
    };
    const select2SettingsProject = {
      placeholder: "Select Project"
    };

    return {
      toggleArchived,
      archived,
      select2Settings,
      select2SettingsProject,
      selectedDate,
      projects,
      formRow,
      hoursRows,
      projects,
      isFormRowComplete,
      addFormRowAsHourRow,
      removeHourRow,      handleDateChange,
      confirmAndSubmitHours,
      submitHours,
      handleProjectChange,
      isFirstRowComplete
    };
  }
};
</script>


<style scoped>
/* Adds spacing between the first row and subsequent rows */
.first-row {
  background-color: white;
  margin-bottom: 30px; /* Adjust as needed */
  border: 1px solid #007bff; /* Adjust to your primary color */
}

.first-row-table {
  margin-bottom: 0; /* Ensures no extra space after first row */
}

.additional-rows-table {
  margin-top: 20px; /* Adds space between first and second table */
}

.row-padding td{
  margin-top: 20px; /* Space between rows */
  padding-top: 15px; /* Adds padding at the top */
  background-color: #F2F2F2;
}


tbody tr:first-child {
  margin-bottom: 50px;
  /* Ensure there's space below the first row */
}
</style>
