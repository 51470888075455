<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <!-- Loading Spinner -->
            <div v-if="loading" class="d-flex justify-content-center align-items-center">
                
            </div>
            <div v-else>
                <!-- Existing Content -->
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="card border-0 mb-2 no-bg">
                            <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                                <div class="header-section">
                                    <div class="search-area mt-2">
                                        <input id="main-search-input" type="search" class="form-control"
                                            placeholder="Search Group" aria-label="search"
                                            aria-describedby="addon-wrapping" />
                                    </div>
                                </div>                               <div class="d-flex mt-sm-0 mt-3 justify-content-between"> 
    <button type="button" class="ms-0 ms-sm-3 btn" 
            style="color:var(--secondary-color); font-weight:bold" 
            @click.prevent="createGroup" 
            v-if="canCreateGroup || superUser">
        <i class="icofont-plus me-2 fs-6"></i>New group
    </button>

    <div class="dropdown" 
         @click="toggleDropdown" 
         @blur="closeDropdown" 
         tabindex="0" 
         v-if="canArchive || superUser">
        <button class="ms-0 ms-sm-3 btn dropdown-menu-toggler" 
                type="button" 
                id="dropdownMenuButton" 
                aria-expanded="false" 
                style="color:var(--secondary-color); font-weight:bold">
            Ενέργειες
        </button>
        <ul class="dropdown-menu border-0 shadow p-3" 
            :class="{'show': isDropdownOpen}" 
            :style="dropdownStyle">
            <li><a class="dropdown-item py-2 rounded archiveSelected" 
                   href="#"
                   @click.prevent="archiveGroups">
                   Αρχειοθέτηση επιλεγμένων
                </a>
            </li>
        </ul>
    </div>
</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix g-3">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <div class="card-body table-responsive">
                                <table id="groups" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th style="width:10%;"><div>Id<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('servicegroups.id')"></i></div></th>
                                            <th style="width:30%;"><div>name<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('servicegroups.groupName')"></i></div></th>
                                            <th style="width:20%;"><div>code<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('servicegroups.groupCode')"></i></div></th>
                                            <th style="width:20%;"><div>creator<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('servicegroups.creator.username')"></i></div></th>

                                            <th class = "no-after" style="width:5%; background-color:white; border:none"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(group, index) in data" :key="group.id" :class="{ 'selected': selectedGroupIds.includes(group.id) }" @click="toggleGroupSelection(group.id, $event)">
                                            <td>{{ group.id }}</td>
                                            <td><span class="fw-bold ms-1">{{ group.groupName }}</span></td>
                                            <td><span class="fw-bold ms-1">{{ group.groupCode }}</span></td>
                                            <td v-if="group.creator?.avatar_file_name" class="small-column">
                                                <img :src="`/storage/avatars/${group.creator.avatar_file_name}`"
                                                    width="30" style="border-radius:50%;" :title="group.creator.username"> <!-- Add title attribute here -->
                                                    
                                            </td>
                                            <td v-else class="small-column">
                                                {{ group.creator?.username }}
                                            </td>

                                            <td style="background-color:white">
                                                <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                    <button type="button" class="btn edit-service" style="color:var(--secondary-color); font-weight:bold" 

                                                    @click.prevent="editGroup(group.id)" v-if="canEditGroup(group) || canEditAllGroups || superUser">
                                                    
                                                    <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                    class="menu-img" style="width:1.5em"></button>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!data || !data.length">
                                            <td colspan="3">No data</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation" v-if="data && data.length">
                                    <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                        <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                                            <router-link :to="{ path: 'groups', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                                                <span class="page-link" v-html="paginationLink.label"></span>
                                            </router-link>
                                            <span class="page-link" v-else v-html="paginationLink.label"></span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>

        <!-- Modal for creating a new user -->
        <div class="modal fade" id="create-group-form" tabindex="-1" aria-labelledby="createprojectLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold" id="createprojectLabel">Group Data</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input name="id" id="id" type="hidden" v-model="groupData.id" />
                        <div class="mb-3">
                            <label for="title" class="form-label">Name</label>
                            <input type="text" class="form-control" id="title" v-model="groupData.groupName" placeholder="Name">
                        </div>
                        <div class="mb-3">
                            <label for="code" class="form-label">Code</label>
                            <input type="text" class="form-control" id="code" v-model="groupData.groupCode" placeholder="Code">
                        </div>
                    </div>
                    <div class="modal-footer">
<!--                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
 -->                        <button type="button" class="btn btn-secondary" @click="submitForm">Αποθήκευση</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveGroups } from '@/services/groups';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { mapGetters } from 'vuex';


const defaultGroupData = {
    id: '',
    groupName: '',
    groupCode: '',

};

export default {
    name: 'Groups',
    methods: {
        
        canEditGroup(group) {
            if (!group) { return false; }
            return localStorage.getItem('current_user_id') == group?.createdBy && this.userPermissions?.edit === 1;
        },
       
    },
    computed: {
    ...mapGetters({
        getUserPermission: 'getUserPermission',
        getSuperUserPermission: 'getSuperUserPermission', // Add this line

    }),
    
    
    superUser() {
        return this.getSuperUserPermission === 1;
    },
     userPermissions() {
        return this.getUserPermission('Groups');
    },
    
    canEditAllGroups() {
        return this.userPermissions?.editAll === 1;
    },
    canCreateGroup() {
        return this.userPermissions?.create === 1;
    },
    canArchive() {
        return this.userPermissions?.archive === 1;
    },
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        
        const toast = useToast();

        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

        const groupData = ref(defaultGroupData);
        const selectedGroupIds = ref([]);
        const isDropdownOpen = ref(false);

        const toggleGroupSelection = (groupId, event) => {
            if (!selectedGroupIds.value.includes(groupId)) {
                selectedGroupIds.value.push(groupId);
            } else {
                selectedGroupIds.value = selectedGroupIds.value.filter(id => id !== groupId);
            }
        };

        const archiveGroups = async () => {
            if (!selectedGroupIds.value.length) {
                toast.error('Δεν έχετε επιλέξει groups');
                return;
            }
            const response = await massArchiveGroups({ ids: selectedGroupIds.value });
            if (response.data.success) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        };

        const editGroup = async (id) => {
            try {
                const groupInfo = await get({ id });
                if (groupInfo.data.success) {
                    groupData.value = { ...groupInfo.data.data };
                    $('#create-group-form').modal('toggle');
                } else {
                    toast.error('Failed to fetch group data');
                }
            } catch (error) {
                console.error('Error fetching group data:', error);
                toast.error('Error fetching group data');
            }
        };

        const createGroup = () => {
            groupData.value = { ...defaultGroupData };
            $('#create-group-form').modal('toggle');
        };

        const submitForm = async () => {
            try {
                const response = await createOrUpdate(groupData.value);
                if (response.data.success) {
                    toast.success(response.data.message);
                    $('#create-group-form').modal('toggle');
                    fetchData();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const closeMenu = (event) => {
            const dropdownMenu = document.querySelector('.dropdown-menu');
            const dropdownToggler = document.querySelector('.dropdown-menu-toggler');

            if (dropdownMenu && dropdownToggler) {
                if (!dropdownMenu.contains(event.target) && !dropdownToggler.contains(event.target)) {
                    isDropdownOpen.value = false;
                }
            } else {
                isDropdownOpen.value = false;
            }
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({path: '/groups', query: {...route.query, search: $("#main-search-input").val()}});
            }, 300);
        };

        onMounted(async () => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            document.addEventListener('click', closeMenu);
            await fetchData();     // Fetch data
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
            document.removeEventListener('click', closeMenu);
        });

        return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, groupData, submitForm, editGroup, createGroup, selectedGroupIds, toggleGroupSelection, archiveGroups, dropdownStyle, closeDropdown, isDropdownOpen, toggleDropdown, sortBy };
    },
    metaInfo: {
        title: 'Χρήστες',
        meta: [
            { name: 'description', content: 'MIS - Groups' },
            { property: 'og:title', content: 'MIS - Groups' },
            { property: 'og:description', content: 'This is the groups page.' }
        ]
    }
};
</script>


<style scoped>
.dropdown-menu.show {
    display: block;
}
tr.selected, div.selected {
    background: #b1afaf;
}
</style>
