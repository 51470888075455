<template>
    <div class="row clearfix g-3">
        <div style="display: flex; flex: 1; align-items: flex-end; flex-direction: column;">
            <button type="button" class="btn btn-dark" @click="goToDashboard">
                <i class="icofont-chart-bar-graph fs-5 me-3"></i>Dashboard
            </button>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="row row-cols-1 row-cols-md-3">
                <div class="col" v-for="(widget, index) in widgets" :key="index">
                    <div class="card mb-3">
                        <div class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 class="m-0 fw-bold">{{ widget.title }}</h6>
                            <input type="checkbox" v-model="widget.enabled" @change="toggleWidget(widget)">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !widget.enabled }" :data-widget-code="widget.code">
                            <div :id="widget.graphId" style="min-height: 228.7px;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12 flex-column">
            <div class="row g-3">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold">Overview</h6>
                            <input type="checkbox" v-model="overviewEnabled" @change="toggleOverview">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !overviewEnabled }" data-widget-code="overview">
                            <div class="d-flex py-2 project-tab flex-wrap w-sm-100">
                                <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeOverviewTab === 'project' }" href="#" role="tab"
                                            @click.prevent="setActiveOverviewTab('project')">Projects</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeOverviewTab === 'task' }" href="#"
                                            role="tab" @click.prevent="setActiveOverviewTab('task')">Tasks</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeOverviewTab === 'subtask' }"
                                            href="#" role="tab" @click.prevent="setActiveOverviewTab('subtask')">Subtasks</a>
                                    </li>
                                </ul>
                            </div>
                            <div id="hiringsources" style="min-height: 315px;"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold">Hosting status</h6>
                            <input type="checkbox" v-model="hostingStatusEnabled" @change="toggleHostingStatus">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !hostingStatusEnabled }" data-widget-code="hosting_status">
                            <div class="row g-2 row-deck">
                                <div class="col-md-4 col-sm-4">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <i class="icofont-sand-clock fs-3"></i>
                                            <h6 class="mt-3 mb-0 fw-bold small-14">Expiring soon</h6>
                                            <span><b style="color:orange;font-size: 18px;">{{ hostingStatus.expires_soon }}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <i class="icofont-clock-time fs-3"></i>
                                            <h6 class="mt-3 mb-0 fw-bold small-14">Expired</h6>
                                            <span><b style="color:red;font-size: 18px;">{{ hostingStatus.expired }}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <i class="icofont-server fs-3"></i>
                                            <h6 class="mt-3 mb-0 fw-bold small-14">Active</h6>
                                            <span><b style="color:green;font-size: 18px;">{{ hostingStatus.active }}</b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold">Todo List</h6>
                            <input type="checkbox" v-model="todoEnabled" @change="toggleTodo">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !todoEnabled }" data-widget-code="todo">
                            <div class="d-flex py-2 todo-tab flex-wrap w-sm-100">
                                <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoTab === 'projects' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoTab('projects')">Projects</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoTab === 'tasks' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoTab('tasks')">Tasks</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoTab === 'subtasks' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoTab('subtasks')">Subtasks</a>
                                    </li>
                                </ul>
                                <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoStatusTab === 'on_hold' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoStatusTab('on_hold')">On Hold</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoStatusTab === 'on_progress' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoStatusTab('on_progress')">On Progress</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoStatusTab === 'done' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoStatusTab('done')">Done</a>
                                    </li>
                                    <li class="nav-item col">
                                        <a class="nav-link" :class="{ active: activeTodoStatusTab === 'ongoing' }" href="#"
                                            role="tab" @click.prevent="setActiveTodoStatusTab('ongoing')">On Going</a>
                                    </li>
                                </ul>
                            </div>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Creator</th>
                                        <th>Manager</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Client</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="todoItems.length === 0">
                                        <td colspan="7">No data!</td>
                                    </tr>
                                    <tr v-for="item in todoItems" :key="item.id">
                                        <td class="d-flex flex-column">
                                            <a class="project-link" :href="getLink(item)">{{ item.code || item.title || '' }}</a>
                                        </td>
                                        <td>{{ item.creator?.username || '-' }}</td>
                                        <td>{{ item.manager?.username || '-' }}</td>
                                        <td>{{ item.startDate || '-' }}</td>
                                        <td>{{ item.endDate || '-' }}</td>
                                        <td>{{ item.client?.nickname || '-' }}</td>
                                        <td><span :class="getStatusClass(item.statusId)">{{ getStatusText(item.statusId) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold ">Follow up</h6>
                            <input type="checkbox" v-model="followUpEnabled" @change="toggleFollowUp">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !followUpEnabled }" data-widget-code="followUp">
                            <div class="row g-3 align-items-center">
                                <div class="row align-items-center">
                                    <div class="d-flex py-2 project-tab flex-wrap w-sm-100">
                                        <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpTab === 'projects' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpTab('projects')">Projects</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpTab === 'tasks' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpTab('tasks')">Tasks</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpTab === 'subtasks' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpTab('subtasks')">Subtasks</a>
                                            </li>
                                        </ul>
                                        <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpStatusTab === 'on_hold' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpStatusTab('on_hold')">On Hold</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpStatusTab === 'on_progress' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpStatusTab('on_progress')">On Progress</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpStatusTab === 'done' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpStatusTab('done')">Done</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeFollowUpStatusTab === 'ongoing' }" href="#"
                                                    role="tab" @click.prevent="setActiveFollowUpStatusTab('ongoing')">On Going</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-12 col-md-12 flex-column">
                                        <div class="tab-content mt-4">
                                            <div class="tab-pane fade show active">
                                                <div class="card-body table-responsive">
                                                    <table id="followupTable" class="table table-hover align-middle mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Creator</th>
                                                                <th>Manager</th>
                                                                <th>Start date</th>
                                                                <th>End date</th>
                                                                <th>Client</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="followUpItems.length === 0">
                                                                <td colspan="7">No data!</td>
                                                            </tr>
                                                            <tr v-for="item in followUpItems" :key="item.id">
                                                                <td class="d-flex flex-column">
                                                                    <a class="project-link" :href="getLink(item)">{{ item.code || item.title || '' }}</a>
                                                                </td>
                                                                <td>{{ item.creator?.username || '-' }}</td>
                                                                <td>{{ item.manager?.username || '-' }}</td>
                                                                <td>{{ item.startDate || '-' }}</td>
                                                                <td>{{ item.endDate || '-' }}</td>
                                                                <td>{{ item.client?.nickname || '-' }}</td>
                                                                <td><span :class="getStatusClass(item.statusId)">{{ getStatusText(item.statusId) }}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold ">Deadline coming soon</h6>
                            <input type="checkbox" v-model="deadlineEnabled" @change="toggleDeadline">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !deadlineEnabled }" data-widget-code="deadline_coming_soon">
                            <div class="row g-3 align-items-center">
                                <div class="row align-items-center">
                                    <div class="d-flex py-2 project-tab flex-wrap w-sm-100">
                                        <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeDeadlineTab === 'projects' }" href="#"
                                                    role="tab" @click.prevent="setActiveDeadlineTab('projects')">Projects</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeDeadlineTab === 'tasks' }" href="#"
                                                    role="tab" @click.prevent="setActiveDeadlineTab('tasks')">Tasks</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeDeadlineTab === 'subtasks' }" href="#"
                                                    role="tab" @click.prevent="setActiveDeadlineTab('subtasks')">Subtasks</a>
                                            </li>
                                        </ul>
                                        <ul class="nav nav-tabs tab-body-header rounded ms-3 prtab-set w-sm-100" role="tablist">
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeDeadlineStatusTab === 'on_hold' }" href="#"
                                                    role="tab" @click.prevent="setActiveDeadlineStatusTab('on_hold')">On Hold</a>
                                            </li>
                                            <li class="nav-item col">
                                                <a class="nav-link" :class="{ active: activeDeadlineStatusTab === 'on_progress' }" href="#"
                                                    role="tab" @click.prevent="setActiveDeadlineStatusTab('on_progress')">On Progress</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-12 col-md-12 flex-column">
                                        <div class="tab-content mt-4">
                                            <div class="tab-pane fade show active">
                                                <div class="card-body table-responsive">
                                                    <table id="deadlineTable" class="table table-hover align-middle mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Creator</th>
                                                                <th>Manager</th>
                                                                <th>Start date</th>
                                                                <th>End date</th>
                                                                <th>Client</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="deadlineItems.length === 0">
                                                                <td colspan="7">No data!</td>
                                                            </tr>
                                                            <tr v-for="item in deadlineItems" :key="item.id">
                                                                <td class="d-flex flex-column">
                                                                    <a class="project-link" :href="getLink(item)">{{ item.code || item.title || '' }}</a>
                                                                </td>
                                                                <td>{{ item.creator?.username || '-' }}</td>
                                                                <td>{{ item.manager?.username || '-' }}</td>
                                                                <td>{{ item.startDate || '-' }}</td>
                                                                <td>{{ item.endDate || '-' }}</td>
                                                                <td>{{ item.client?.nickname || '-' }}</td>
                                                                <td><span :class="getStatusClass(item.statusId)">{{ getStatusText(item.statusId) }}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="row g-3 row-deck">
                <div class="col-md-6 col-lg-6 col-xl-12">
                    <div class="card">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold ">Upcoming Holiday</h6>
                            <input type="checkbox" v-model="upcomingHolidayEnabled" @change="toggleUpcomingHoliday">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !upcomingHolidayEnabled }" data-widget-code="upcoming_holiday">
                            <div class="row g-2 row-deck">
                                <div class="col-md-12 col-sm-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <span>Η επόμενη αργία είναι: <b>{{ upcomingHoliday.title }}</b>, σε <b>{{ upcomingHoliday.days_left }}</b> ημέρες και σήμερα.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-12">
                    <div class="card bg-primary">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold" style="color: white;">Days off</h6>
                            <input type="checkbox" v-model="daysOffEnabled" @change="toggleDaysOff">
                        </div>
                        <div class="card-body row widget-content" :class="{ disabled: !daysOffEnabled }" data-widget-code="days_off">
                            <div class="col">
                                <h1 class="mt-3 mb-0 fw-bold text-white">{{ daysOff.total_days_off }}</h1>
                                <span class="text-white">Total days off</span>
                            </div>
                            <div class="col">
                                <h1 class="mt-3 mb-0 fw-bold text-white">{{ daysOff.remaining_days }}</h1>
                                <span class="text-white">Total avail. days</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-12">
                    <div class="card bg-primary">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold" style="color: white;">Participation</h6>
                            <input type="checkbox" v-model="participationEnabled" @change="toggleParticipation">
                        </div>
                        <div class="card-body row widget-content" :class="{ disabled: !participationEnabled }" data-widget-code="participation">
                            <div class="col">
                                <h1 class="mt-3 mb-0 fw-bold text-white">{{ participation.projects_participated }}</h1>
                                <span class="text-white">Projects participated</span>
                            </div>
                            <div class="col">
                                <h1 class="mt-3 mb-0 fw-bold text-white">{{ participation.completed_tasks }}</h1>
                                <span class="text-white">Completed tasks</span>
                            </div>
                            <div class="col">
                                <h1 class="mt-3 mb-0 fw-bold text-white">{{ participation.completed_subtasks }}</h1>
                                <span class="text-white">Completed subtasks</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-12  flex-column">
                    <div class="card mb-3">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold ">Employees status</h6>
                            <input type="checkbox" v-model="employeesStatusEnabled" @change="toggleEmployeesStatus">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !employeesStatusEnabled }" data-widget-code="employees_status">
                            <div class="row g-2 row-deck">
                                <div class="col-md-6 col-sm-6">
                                    <div class="card">
                                        <div class="card-body ">
                                            <i class="icofont-live-support fs-3"></i>
                                            <h6 class="mt-3 mb-0 fw-bold small-14">Working</h6>
                                            <span>{{ employeesStatus.available_count }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="card">
                                        <div class="card-body ">
                                            <i class="icofont-beach-bed fs-3"></i>
                                            <h6 class="mt-3 mb-0 fw-bold small-14">On vacation</h6>
                                            <span>{{ employeesStatus.in_vacation_count }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-12">
                    <div class="card">
                        <div class="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 class="mb-0 fw-bold ">Top worked projects last month</h6>
                            <input type="checkbox" v-model="topWorkedProjectsEnabled" @change="toggleTopWorkedProjects">
                        </div>
                        <div class="card-body widget-content" :class="{ disabled: !topWorkedProjectsEnabled }" data-widget-code="top_worked_projects_last_month">
                            <div class="flex-grow-1">
                                <div v-for="project in topWorkedProjects" :key="project.project_name" class="py-2 d-flex align-items-center border-bottom flex-wrap">
                                    <div class="d-flex align-items-center flex-fill">
                                        <div class="d-flex flex-column ps-3">
                                            <h6 class="fw-bold mb-0 small-14">{{ project.project_name }}</h6>
                                        </div>
                                    </div>
                                    <div class="time-block text-truncate">
                                        {{ project.hours }} <i class="icofont-clock-time"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import ApexCharts from 'apexcharts';
import { useToast } from 'vue-toastification';
import apiClient from '@/services/api';

export default {
    data() {
        return {
            widgets: [],
            overviewEnabled: false,
            hostingStatusEnabled: false,
            todoEnabled: false,
            followUpEnabled: false,
            deadlineEnabled: false,
            upcomingHolidayEnabled: false,
            daysOffEnabled: false,
            participationEnabled: false,
            employeesStatusEnabled: false,
            topWorkedProjectsEnabled: false,
            overviewChart: null,
            todoItems: [],
            followUpItems: [],
            deadlineItems: [],
            hostingStatus: {},
            upcomingHoliday: {},
            daysOff: {},
            participation: {},
            employeesStatus: {},
            topWorkedProjects: [],
            activeOverviewTab: 'project',
            activeTodoTab: 'projects',
            activeTodoStatusTab: 'on_progress',
            activeFollowUpTab: 'projects',
            activeFollowUpStatusTab: 'on_progress',
            activeDeadlineTab: 'projects',
            activeDeadlineStatusTab: 'on_progress',
        };
    },
    methods: {
        goToDashboard() {
            router.push({path: '/dashboard'});
        },
        async fetchStatistics() {
            return await apiClient.get('/dashboard/get-statistics');
        },
        async fetchUserWidgets() {
            return apiClient.get('/fetch-widgets-users');
        },
        async fetchTodoItems() {
            const type = this.getActiveTodoType();
            const status = this.getActiveTodoStatusId();
            const data = await apiClient.get(`/dashboard/get-todo-${type}?statusId=${status}`);
            this.todoItems = data?.data?.data?.todo?.[type] || [];
        },
        async fetchFollowUpItems() {
            const type = this.activeFollowUpTab;
            const status = this.getActiveFollowUpStatusId();
            const data = await apiClient.get(`/dashboard/get-followup-${type}?statusId=${status}`);
            this.followUpItems = data?.data?.data?.followUp?.[type] || [];
        },
        async fetchDeadlineItems() {
            const type = this.activeDeadlineTab;
            const status = this.getActiveDeadlineStatusId();
            const data = await apiClient.get(`/dashboard/get-deadline_coming_soon-${type}?statusId=${status}`);
            this.deadlineItems = data?.data?.data?.deadline_coming_soon?.[type] || [];
        },
        getLink(item) {
            const type = this.getActiveTodoType();
            const linkPath = type === 'projects' ? 'projectId' : type === 'tasks' ? 'taskId' : 'subtaskId';
            return `/project?${linkPath}=${item.id}`;
        },
        getStatusClass(statusId) {
            const statusMap = {
                1: 'on_hold-span',
                2: 'on_progress-span',
                3: 'done-span',
                4: 'archived-span',
                5: 'ongoing-span',
            };
            return statusMap[statusId] || '';
        },
        getStatusText(statusId) {
            const statusMap = {
                1: 'On hold',
                2: 'On progress',
                3: 'Done',
                4: 'Archived',
                5: 'On going',
            };
            return statusMap[statusId] || '';
        },
        setActiveOverviewTab(tab) {
            this.activeOverviewTab = tab;
            this.fetchOverview(tab);
        },
        setActiveTodoTab(tab) {
            this.activeTodoTab = tab;
            this.fetchTodoItems();
        },
        setActiveTodoStatusTab(statusTab) {
            this.activeTodoStatusTab = statusTab;
            this.fetchTodoItems();
        },
        setActiveFollowUpTab(tab) {
            this.activeFollowUpTab = tab;
            this.fetchFollowUpItems();
        },
        setActiveFollowUpStatusTab(statusTab) {
            this.activeFollowUpStatusTab = statusTab;
            this.fetchFollowUpItems();
        },
        setActiveDeadlineTab(tab) {
            this.activeDeadlineTab = tab;
            this.fetchDeadlineItems();
        },
        setActiveDeadlineStatusTab(statusTab) {
            this.activeDeadlineStatusTab = statusTab;
            this.fetchDeadlineItems();
        },
        getActiveTodoType() {
            return this.activeTodoTab;
        },
        getActiveTodoStatusId() {
            const statusMap = {
                on_hold: 1,
                on_progress: 2,
                done: 3,
                ongoing: 5,
            };
            return statusMap[this.activeTodoStatusTab] || 2;
        },
        getActiveFollowUpStatusId() {
            const statusMap = {
                on_hold: 1,
                on_progress: 2,
                done: 3,
                archived: 4,
                ongoing: 5,
            };
            return statusMap[this.activeFollowUpStatusTab] || 2;
        },
        getActiveDeadlineStatusId() {
            const statusMap = {
                on_hold: 1,
                on_progress: 2,
            };
            return statusMap[this.activeDeadlineStatusTab] || 2;
        },
        async fetchOverview(type) {
            const overviewResponse = await apiClient.get(`/dashboard/get-${type}_overview`);
            this.renderChart(type, overviewResponse.data);
        },
        renderChart(type, overviewResponse) {
            const options = {
                series: overviewResponse.data[`${type}_overview`].data,
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: { show: false },
                    zoom: { enabled: true },
                },
                colors: ['var(--chart-color1)', 'var(--chart-color2)', 'var(--chart-color3)', 'var(--chart-color4)'],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: { position: 'bottom', offsetX: -10, offsetY: 0 },
                        },
                    },
                ],
                xaxis: {
                    categories: overviewResponse.data[`${type}_overview`].months,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                },
            };

            if (!this.overviewChart) {
                this.overviewChart = new ApexCharts(document.querySelector('#hiringsources'), options);
                this.overviewChart.render();
            } else {
                this.overviewChart.updateSeries(overviewResponse.data[`${type}_overview`].data);
            }
        },
        renderPercentageChart(id, percentage) {
            const options = {
                chart: {
                    height: 250,
                    type: 'radialBar',
                    toolbar: { show: false },
                },
                colors: ['var(--chart-color1)'],
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#fff',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24,
                            },
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0,
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35,
                            },
                        },
                        dataLabels: {
                            showOn: 'always',
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '17px',
                            },
                            value: {
                                formatter: (val) => parseInt(val),
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            },
                        },
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['var(--chart-color2)'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                    },
                },
                series: [percentage],
                stroke: { lineCap: 'round' },
                labels: ['Percent'],
            };

            const chart = new ApexCharts(document.getElementById(`${id}_graph`), options);
            chart.render();
        },
        async toggleWidget(widget) {
            const data = await apiClient.post('/update-widget', { widget_code: widget.code, isActive: widget.enabled });
            if (data.data.success) {
                useToast().success(data.data.message);
            } else {
                useToast().error(data.data.message);
            }
        },
        async toggleOverview() {
            const data = await apiClient.post('/update-widget', { widget_code: 'overview', isActive: this.overviewEnabled });
            if (data.data.success) {
                useToast().success('Overview widget updated successfully');
            } else {
                useToast().error('Failed to update overview widget');
            }
        },
        async toggleHostingStatus() {
            const data = await apiClient.post('/update-widget', { widget_code: 'hosting_status', isActive: this.hostingStatusEnabled });
            if (data.data.success) {
                useToast().success('Hosting status widget updated successfully');
            } else {
                useToast().error('Failed to update hosting status widget');
            }
        },
        async toggleTodo() {
            const data = await apiClient.get('/update-widget', { widget_code: 'todo', isActive: this.todoEnabled });
            if (data.data.success) {
                useToast().success('Todo widget updated successfully');
            } else {
                useToast().error('Failed to update todo widget');
            }
        },
        async toggleFollowUp() {
            const data = await apiClient.post('/update-widget', { widget_code: 'followUp', isActive: this.followUpEnabled });
            if (data.data.success) {
                useToast().success('Follow up widget updated successfully');
            } else {
                useToast().error('Failed to update follow up widget');
            }
        },
        async toggleDeadline() {
            const data = await apiClient.post('/update-widget', { widget_code: 'deadline_coming_soon', isActive: this.deadlineEnabled });
            if (data.data.success) {
                useToast().success('Deadline widget updated successfully');
            } else {
                useToast().error('Failed to update deadline widget');
            }
        },
        async toggleUpcomingHoliday() {
            const data = await apiClient.post('/update-widget', { widget_code: 'upcoming_holiday', isActive: this.upcomingHolidayEnabled });
            if (data.data.success) {
                useToast().success('Upcoming holiday widget updated successfully');
            } else {
                useToast().error('Failed to update upcoming holiday widget');
            }
        },
        async toggleDaysOff() {
            const data = await apiClient.post('/update-widget', { widget_code: 'days_off', isActive: this.daysOffEnabled });
            if (data.success) {
                useToast().success('Days off widget updated successfully');
            } else {
                useToast().error('Failed to update days off widget');
            }
        },
        async toggleParticipation() {
            const data = await apiClient.post('/update-widget', { widget_code: 'participation', isActive: this.participationEnabled });
            if (data.data.success) {
                useToast().success('Participation widget updated successfully');
            } else {
                useToast().error('Failed to update participation widget');
            }
        },
        async toggleEmployeesStatus() {
            const data = await apiClient.post('/update-widget', { widget_code: 'employees_status', isActive: this.employeesStatusEnabled });
            if (data.data.success) {
                useToast().success('Employees status widget updated successfully');
            } else {
                useToast().error('Failed to update employees status widget');
            }
        },
        async toggleTopWorkedProjects() {
            const data = await apiClient.post('/update-widget', { widget_code: 'top_worked_projects_last_month', isActive: this.topWorkedProjectsEnabled });
            if (data.data.success) {
                useToast().success('Top worked projects widget updated successfully');
            } else {
                useToast().error('Failed to update top worked projects widget');
            }
        },
    },
    async mounted() {
        let response = await this.fetchStatistics();

        const data = response.data?.data || {};
        data.top_worked_projects_last_month_show = data.top_worked_projects_last_month && data.top_worked_projects_last_month.length ? true : false;

        this.hostingStatus = data.hosting_status || {};
        this.upcomingHoliday = data.upcoming_holiday || {};
        this.daysOff = data.days_off || {};
        this.participation = data.participation || {};
        this.employeesStatus = data.employees_status || {};
        this.topWorkedProjects = data.top_worked_projects_last_month || [];

        this.widgets = [
            {
                title: 'Projects completed',
                code: 'percent_projects_completed',
                graphId: 'percent_projects_completed_graph',
                percentage: data.percent_projects_completed,
                enabled: true,
            },
            {
                title: 'Tasks completed',
                code: 'percent_tasks_completed',
                graphId: 'percent_tasks_completed_graph',
                percentage: data.percent_tasks_completed,
                enabled: true,
            },
            {
                title: 'Subtasks completed',
                code: 'percent_subtasks_completed',
                graphId: 'percent_subtasks_completed_graph',
                percentage: data.percent_subtasks_completed,
                enabled: true,
            },
        ];

        this.$nextTick(() => {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

            this.fetchOverview('project');

            if (data.percent_projects_completed) {
                this.renderPercentageChart('percent_projects_completed', data.percent_projects_completed);
            }
            if (data.percent_tasks_completed) {
                this.renderPercentageChart('percent_tasks_completed', data.percent_tasks_completed);
            }
            if (data.percent_subtasks_completed) {
                this.renderPercentageChart('percent_subtasks_completed', data.percent_subtasks_completed);
            }
        });
        this.fetchTodoItems();
        this.fetchFollowUpItems();
        this.fetchDeadlineItems();
        response = await this.fetchUserWidgets();
        if (response.data.success && response.data.data && response.data.data.length) {
            response.data.data.forEach(widget => {
                switch (widget.widget_code) {
                    case 'overview':
                        this.overviewEnabled = widget.active;
                        break;
                    case 'hosting_status':
                        this.hostingStatusEnabled = widget.active;
                        break;
                    case 'todo':
                        this.todoEnabled = widget.active;
                        break;
                    case 'followUp':
                        this.followUpEnabled = widget.active;
                        break;
                    case 'deadline_coming_soon':
                        this.deadlineEnabled = widget.active;
                        break;
                    case 'upcoming_holiday':
                        this.upcomingHolidayEnabled = widget.active;
                        break;
                    case 'days_off':
                        this.daysOffEnabled = widget.active;
                        break;
                    case 'participation':
                        this.participationEnabled = widget.active;
                        break;
                    case 'employees_status':
                        this.employeesStatusEnabled = widget.active;
                        break;
                    case 'top_worked_projects_last_month':
                        this.topWorkedProjectsEnabled = widget.active;
                        break;
                    default:
                        const foundWidget = this.widgets.find(w => w.code === widget.widget_code);
                        if (foundWidget) {
                            foundWidget.enabled = widget.active;
                        }
                        break;
                }
            });
        }
    },
};
</script>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
