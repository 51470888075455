<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        <div
                            class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                            <div class="header-section">
                                <div class="search-area mt-2">
                                    <input id="main-search-input" type="search" class="form-control"
                                        placeholder="Αναζήτηση..." aria-label="search"
                                        aria-describedby="addon-wrapping" />
                                </div>
                            </div>
                            <div class="d-flex mt-sm-0 mt-3 justify-content-between">
                                <button type="button" class="ms-0 ms-sm-3 btn" style="color:var(--secondary-color); font-weight:bold" 
                                    @click.prevent="createService" v-if="canCreateService || superUser"><i class="icofont-plus me-2 fs-6" ></i>New
                                    service</button>
                                    <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0" v-if="canArchive || superUser">
                                    <button class="ms-0 ms-sm-3 btn btn-primary dropdown-menu-toggler" type="button" style="color:var(--secondary-color); font-weight:bold" >
                                        Ενέργειες
                                    </button>
                                    <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': isDropdownOpen}"
                                        :style="dropdownStyle">
                                        <li><a class="dropdown-item py-2 rounded archiveSelected" href="#"
                                                @click.prevent="archiveServices">Αρχειοθέτηση
                                                επιλεγμένων</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix g-3">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div class="card-body table-responsive">
                            <table id="services" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:10%;"><div>Id<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('services.id')"></i></div></th>
                                        <th style="width:25%;"><div>Service<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('services.title')"></i></div></th>
                                        <th style="width:15%;"><div>Code<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('services.serviceCode')"></i></div></th>
                                        <th style="width:15%;"><div>Group<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('servicegroups.groupName')"></i></div></th>
                                        <th style="width:10%;"><div>Creator<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('services.creator.username')"></i></div></th>
                                        <th style="width:5%; background-color:white; border:none" class="no-after"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- {{ #services }} -->
                                    <tr v-for="(service, index) in data" :key="service.info.id" :class="{ 'selected': selectedServiceIds.includes(service.info.id) }" @click="toggleServiceSelection(service.info.id, $event)">
                                        <td>
                                            {{ service.info.id }}
                                        </td>
                                        <td>
                                            <span class="fw-bold ms-1">
                                                {{ service.info.title }}
                                            </span>
                                        </td>
                                        <td>
                                            {{ service.info.serviceCode }}
                                        </td>
                                        <td>
                                            {{ service.group.name }}
                                        </td>
                                        <td v-if="service.info.creator?.avatar_file_name" class="small-column">
                                                <img :src="`/storage/avatars/${service.info.creator.avatar_file_name}`"
                                                    width="30" style="border-radius:50%;" :title="service.info.creator.username"> <!-- Add title attribute here -->
                                                    
                                            </td>
                                        <td v-else class="small-column">
                                            {{ service.info.creator?.username }}
                                        </td>
                                        <td style="background-color:white">
                                            <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" class="btn btn-dark edit-service" style="color:var(--secondary-color); font-weight:bold" 
                                                    @click.prevent="editService(service.info.id)" v-if="canEditService(service) || canEditAllServices || superUser"><img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                    class="menu-img" style="width:1.5em"></button>
                                                        
                                            </div>
                                        </td>
                                       
                                    </tr>
                                    <!-- {{ /services }} -->
                                    <!-- {{^ services }} -->
                                    <tr class="col" v-if="!data || !data.length">
                                        <td>
                                            
                                        </td>
                                        <td>No data
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                    <!-- {{ /services}} -->
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation" v-if="data && data.length">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks"
                                        :key="index" :class="{ active: paginationLink.active }">
                                        <router-link
                                            :to="{ path: 'services', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                            v-if="paginationLink.url">
                                            <span class="page-link" v-html="paginationLink.label"></span>
                                        </router-link>
                                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>

        <!-- Modal for creating a new user -->
        <div class="modal fade" id="create-service-form" tabindex="-1" aria-labelledby="createprojectlLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold" id="createprojectlLabel">Στοιχεία υπηρεσίας</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input name="id" id="id" type="hidden" v-model="serviceData.id" />
                        <div class="mb-3">
                            <label for="title" class="form-label">Title</label>
                            <input type="title" class="form-control" id="title" v-model="serviceData.title"
                                placeholder="Title">
                        </div>
                        <div class="mb-3">
                            <label for="code" class="form-label">Service code</label>
                            <input type="text" class="form-control" id="serviceCode" v-model="serviceData.serviceCode"
                                placeholder="Service code">
                        </div>
                        <div class="mb-3">
                            <label for="serviceGroupId" class="form-label">Group</label>
                            <select id="serviceGroupId" class="form-select" v-model="serviceData.serviceGroupId"
                                aria-label="Group">
                                <option value="">Παρακαλώ επιλέξτε</option>
                                <option v-for="group in serviceGroups" :value="group.id">{{ group.groupName }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
<!--                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
 -->                        <button type="button" class="btn btn-secondary" @click="submitForm">Αποθήκευση</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveServices, fetchAllServiceGroups } from '@/services/services';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { mapGetters } from 'vuex';


const defaultServiceData = {
    id: '',
    title: '',
    serviceCode: '',
    serviceGroupId: ''
};

export default {
    name: 'Services',
    methods: {
        
        canEditService(service) {
            if (!service) { return false; }
            return localStorage.getItem('current_user_id') == service?.createdBy && this.userPermissions?.edit === 1;
        },
       
    },
    computed: {
    ...mapGetters({
        getUserPermission: 'getUserPermission',
        getSuperUserPermission: 'getSuperUserPermission', // Add this line

    }),
    
    
    superUser() {
        return this.getSuperUserPermission === 1;
    },
     userPermissions() {
        return this.getUserPermission('Services');
    },
    
    canEditAllServices() {
        return this.userPermissions?.editAll === 1;
    },
    canCreateService() {
        return this.userPermissions?.create === 1;
    },
    canArchive() {
        return this.userPermissions?.archive === 1;
    },
  },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

        const serviceData = ref(defaultServiceData);
        const selectedServiceIds = ref([]);
        const serviceGroups = ref([]);

        const isDropdownOpen = ref(false);

        const toggleServiceSelection = (serviceId, event) => {
            if (!selectedServiceIds.value.includes(serviceId)) {
                selectedServiceIds.value.push(serviceId);
            } else {
                selectedServiceIds.value = selectedServiceIds.value.filter(id => id !== serviceId);
            }
        };

        const archiveServices = async () => {
            if (!selectedServiceIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει υπηρεσίες');
                return;
            }
            const response = await massArchiveServices({ ids: selectedServiceIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const editService = async (id) => {
            const serviceInfo = await get({ "id": id });
            console.log(serviceInfo.data.data.title)
            if (!serviceGroups.value.length) {
                const groups = await fetchAllServiceGroups();
                if (groups.data.success) {
                    serviceGroups.value = groups.data.data.data;
                }
            }
            if (serviceInfo.data.success) {
                serviceData.value.id = serviceInfo.data.data.id;
                serviceData.value.title = serviceInfo.data.data.title;
                serviceData.value.serviceCode = serviceInfo.data.data.code;
                serviceData.value.serviceGroupId = serviceInfo.data.data.groupId;
            }else{
                useToast().error(serviceInfo.data.message);
            }
            $("#create-service-form").modal('toggle');
        };

        const createService = async () => {
            if (!serviceGroups.value.length) {
                const groups = await fetchAllServiceGroups();
                if (groups.data.success) {
                    serviceGroups.value = groups.data.data.data;
                }
            }
            serviceData.value = {
                id: '',
                title: '',
                serviceCode: '',
                serviceGroupId: ''
            };
            $("#create-service-form").modal('toggle');
        };

        const submitForm = async () => {
            const response = await createOrUpdate(serviceData.value);
            if (response.data.success) {
                useToast().success(response.data.message);
                $("#create-service-form").modal('toggle');
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const closeMenu = (event) => {
            if (document.querySelector('.dropdown-menu').length && !document.querySelector('.dropdown-menu').contains(event.target) && !document.querySelector('.dropdown-menu-toggler').contains(event.target)) {
                isDropdownOpen.value = false;
            }
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({path: '/services', query: {...route.query, search: $("#main-search-input").val()}});
            }, 300);
        };

        onMounted(() => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            document.addEventListener('click', closeMenu);
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
            document.removeEventListener('click', closeMenu);
        });

        return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, serviceData, submitForm, editService, createService, selectedServiceIds, toggleServiceSelection, archiveServices, dropdownStyle, closeDropdown, isDropdownOpen, toggleDropdown, serviceGroups, sortBy };
    },
    metaInfo: {
        title: 'Χρήστες',
        meta: [
            { name: 'description', content: 'MIS - Services' },
            { property: 'og:title', content: 'MIS - Services' },
            { property: 'og:description', content: 'This is the services page.' }
        ]
    }
};
</script>

<style scoped>
.dropdown-menu.show {
    display: block;
}
tr.selected, div.selected {
    background: #b1afaf;
}
</style>
