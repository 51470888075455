<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    
                </div>
            </div>
            <div class="card-body table-responsive">
                <table id="projectTable" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                    <thead>
                        <tr>
                            <th style="width:10%;">
                                <div v-if="superUser" class="d-flex align-items-center">
                                    <select class="nav-link rounded" name="" id="dropdownCreators"
                                        @change="updateClientFilter">
                                        <option value="" selected>CLIENT</option>
                                        <option v-for="client in clients" :key="client.id"
                                            :value="client.id">
                                            {{ client.nickname }}
                                        </option>
                                    </select>
                                    <img class="menu-img" src="../assets/images/icons8-superman.svg"
                                        alt="Citrine Logo" style="width:2em" >
                                </div>
                                <div v-else>
                                    CLIENT
                                    <i class="fa fa-sort sorting-icon"
                                        @click.prevent="sortBy('client1.username')"></i>
                                </div>
                            </th>
                            <th style="width:10%;">
                                <div v-if="superUser" class="d-flex align-items-center">
                                    <select class="nav-link rounded" name="" id="dropdownCreators"
                                        @change="updateCreatorFilter">
                                        <option value="" selected>CHANGED BY</option>
                                        <option v-for="creator in creators" :key="creator.id"
                                            :value="creator.id">
                                            {{ creator.username }}
                                        </option>
                                    </select>
                                    <img class="menu-img" src="../assets/images/icons8-superman.svg"
                                        alt="Citrine Logo" style="width:2em" >
                                </div>
                                <div v-else>
                                    CHANGED BY
                                    <i class="fa fa-sort sorting-icon"
                                        @click.prevent="sortBy('user1.username')"></i>
                                </div>
                            </th>
                            <th style="width:10%;"><div>Potential Before <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('potential_before')"></i></div></th>
                            <th style="width:10%;"><div>Potential After <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('potential_after')"></i></div></th>
                            <th style="width:10%;"><div>Paid Before <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('paid_before')"></i></div></th>
                            <th style="width:10%;"><div>Paid After <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('paid_after')"></i></div></th>
                            <th style="width:10%;" class="no-after"><div>Date changed <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('created_at')"></i></div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(change, index) in data" :key="index">
                           <td v-if="change.client?.avatar_file_name">
                                <img :src="`/storage/clients_avatars/${change.client.avatar_file_name}`"
                                    style="width:10em">
                            </td>
                            <td v-else>
                                <p v-if="change.client?.title">{{ change.client.title }}</p>
                            </td>

                           <td v-if="change.creator?.avatar_file_name" class="small-column">
                                <img :src="`/storage/avatars/${change.creator.avatar_file_name}`"
                                    width="30" style="border-radius:50%;" :title="change.creator.username">
                            </td>
                            <td v-else class="small-column">
                                {{ change.creator?.username }}
                            </td>
    
                           <!-- Check if potential_before is 0 or 1 -->
                           <td>{{ change.potential_before === 1 ? 'ΝΑΙ' : 'ΟΧΙ' }}</td>

                           <!-- Check if potential_after is 0 or 1 -->
                           <td>{{ change.potential_after === 1 ? 'ΝΑΙ' : 'ΟΧΙ' }}</td>

                           <!-- Check if paid_before is 0 or 1 -->
                           <td>{{ change.paid_before === 1 ? 'ΝΑΙ' : 'ΟΧΙ' }}</td>

                           <!-- Check if paid_after is 0 or 1 -->
                           <td>{{ change.paid_after === 1 ? 'ΝΑΙ' : 'ΟΧΙ' }}</td>
    
                           <td>{{ change.formatted_created_at }}</td>
                        </tr>

                        <tr class="col" v-if="!data || !data.length">
                            <td>No data</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation" v-if="data && data.length">
                    <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                        <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                            <router-link :to="{ path: 'history-changes', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                                <span class="page-link" v-html="paginationLink.label"></span>
                            </router-link>
                            <span class="page-link" v-else v-html="paginationLink.label"></span>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>



<script>
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, fetchClients } from '@/services/history-changes';
import { useRoute, useRouter } from 'vue-router';
import 'vue-toastification/dist/index.css';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';
import { fetchManagers } from '@/services/followup';


const defaultClientData = {
    title: '',
    description: '',
    clientId: '',
    assignedUsers: [],
    serviceId: '',
    clientId: '',
    statusId: '',
    payable: '0',
    payclientId: '',
    invoiced: '0',
    paid: '1',
    estimatedTime: '',
    startDate: '',
    endDate: ''
};

export default defineComponent({
    name: 'HistoryChange',
    components: {
        Editor,
        Select2
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Projects');
        },
        canEditProjectCreator() {
            return this.userPermissions?.editCreator === 1;
        },
        canEditAllProjects() {
            return this.userPermissions?.editAll === 1;
        },
        canCreateProject() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
  },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { data, paginationLinks, filters, updateFilters, fetchData, count, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1, active: route.query.active || 1, archived: route.query.archived || 0 }, { page: 1, active: 1, archived: 0 });
        const showClientsContainer = ref(true); // Ref to control visibility of users container
        const clients = ref([]);
        const selectedClientId = ref(null);
        const creators = ref([]);


        const fetchClientsData = async () => {
            try {
                const response = await fetchClients();
                if (response.data.success) {
                    clients.value = response.data.data;
                } else {
                    console.error("Failed to fetch clients:", response.data.message);
                }
            } catch (error) {
                showClientsContainer.value = false;
                console.error("Failed to fetch clients:", error);
            }
        };

       
        fetchClientsData();

        const clientData = ref(defaultClientData);
        const selectedProjectIds = ref([]);
        const isDropdownOpen = ref(false);

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const updateClientFilter = async (event) => {
            const newClientId = event.target.value;
            selectedClientId.value = newClientId;
            updateFilters({ ...filters, clientFilter: newClientId, page: 1 });
        };

        const updateCreatorFilter = (event) => {
            const newCreatorId = event.target.value;
            updateFilters({ ...filters.value, creatorFilter: newCreatorId, page: 1 });
        };

        const fetchCreatorsData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    creators.value = response.data.data;
                } else {
                    console.error("Failed to fetch creators:", response.data.message);
                }
            } catch (error) {
                console.error("Failed to fetch creators:", error);
            }
        };


        const tinymceInit = {
            height: 300,
            license_key: 'gpl',
            skin_url: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/ui/oxide',
            content_css: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/content/default/content.css'
        };

        const select2Settings = {
            dropdownParent: '#create-project-form .modal-content',
            placeholder: "Please select"
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({path: '/history-changes', query: {...route.query, search: $("#main-search-input").val()}});
            }, 300);
        };

        onMounted(() => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            fetchCreatorsData();
            fetchClientsData();

        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
        });


        return { sortBy, data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, clientData, toggleDropdown, closeDropdown, isDropdownOpen, updateCreatorFilter, creators, dropdownStyle, tinymceInit, select2Settings, count, clients,
            selectedClientId,
            updateClientFilter,
            showClientsContainer };
    },
    metaInfo: {
        title: 'Projects',
        meta: [
            { name: 'description', content: 'MIS - Projects' },
            { property: 'og:title', content: 'MIS - Projects' },
            { property: 'og:description', content: 'This is the projects page.' }
        ]
    }
});
</script>


