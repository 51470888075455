import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/get-hour-requests', data || {});
    return response;
  } catch (error) {
    console.error('Error fetching all days off:', error);
    throw error;
  }
}

async function accept(data) {
    try {
      const response = await apiClient.post('/review-request', {
        id: data.id,  // Use info.id here
        accepted: 1
      });
      return response;
    } catch (error) {
      console.error('Error accepting request:', error);
      throw error;
    }
  }
  
  async function reject(data) {
    try {
      const response = await apiClient.post('/review-request', {
        id: data.id,  // Use info.id here
        accepted: 0
      });
      return response;
    } catch (error) {
      console.error('Error rejecting request:', error);
      throw error;
    }
  }
  
  
  
  


export { fetchAll, accept, reject };
