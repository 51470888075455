import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/get-insufficient-hours-days', data || {});
    return response;
  } catch (error) {
    console.error('Error fetching all days off:', error);
    throw error;
  }
}

export { fetchAll};
