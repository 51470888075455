<template>
  <div class="body d-flex py-lg-3 py-md-2">
    <div class="container-xxl">
      <!-- Existing Content -->
      <div class="row clearfix">
        <div class="col-md-12">
          <!-- Header Section -->
          <div class="card border-0 mb-2 no-bg">
            <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
              <div class="d-flex mt-sm-0 mt-3 justify-content-between">
                <button type="button" class="ms-0 ms-sm-3 btn" style="background-color: rgb(107, 219, 107);" id="acceptSelectedHoursBtn" @click.prevent="acceptSelectedHours">Accept Selected Hours</button>
                <button class="ms-0 ms-sm-3 btn" type="button" style="background-color: rgb(241, 111, 111);" id="rejectSelectedHoursBtn" @click.prevent="rejectSelectedHours">Reject Selected Hours</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- hours requests Table -->
      <div class="row clearfix g-3">
        <div class="col-sm-12">
          <div class="card mb-3">
            <div class="card-body table-responsive" style="width: 95%; margin: 0 auto">
              <table id="myProjectTable" class="table table-hover align-middle mb-0 mis_table" style="width: 100%">
                <thead>
                  <tr>
                    <th style="width:8%;"><div>ΕΠΙΛΟΓΗ</div></th>
                    <th style="width:10%;"><div>User<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('users.username')"></i></div></th>
                    <th style="width:10%;"><div>Request Date<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('created_at')"></i></div></th>
                    <th style="width:10%;"><div>Work Date<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('day')"></i></div></th>
                    <th style="width:10%;"><div>Project</div></th>
                    <th style="width:10%;"><div>Project New</div></th>
                    <th style="width:10%;"><div>Task</div></th>
                    <th style="width:10%;"><div>Task New</div></th>
                    <th style="width:10%;"><div>Hours</div></th>
                    <th style="width:10%;"><div>Hours New</div></th>
                    <th style="width:10%;"><div>Description</div></th>
                    <th style="width:10%;"><div>Actions</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(hours, index) in data" :key="hours.id"
                      :class="{ 'approved': hours.accepted === 1, 'not-approved': hours.accepted === 0 }">
                    <td v-if="hours.accepted === null">
                      <input type="checkbox" v-model="selectedRows" :value="hours.id">
                    </td>
                    <td v-else></td> <!-- Empty cell to maintain layout -->
                    <td>{{ hours.username }}</td>
                    <td>{{ hours.created_at }}</td>
                    <td>{{ hours.day }}</td>
                    <td>{{ hours.previousProject_title }}</td>
                    <td>{{ hours.requestedProject.title }}</td>
                    <td>{{ hours.hourTask.title }}</td>
                    <td>{{ hours.requestedTask.title }}</td>
                    <td>{{ hours.hour_from }} έως {{ hours.hour_to }}</td>
                    <td>{{ hours.info_from }} έως {{ hours.info_to }}</td>
                    <td>{{ hours.body }}</td>
                    <td>
                      <template v-if="hours.accepted === null">
                        <button type="button" class="btn btn-outline-secondary"
                                @click="acceptHourChange(hours.id)">
                          <i class="icofont-tick-mark text-success"></i>
                        </button>
                        <button type="button" class="btn btn-outline-secondary"
                                @click="rejectHourChange(hours.id)">
                          <i class="icofont-close text-danger"></i>
                        </button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                  <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index"
                      :class="{ active: paginationLink.active }">
                    <router-link :to="{ path: $route.path, query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                 v-if="paginationLink.url">
                      <span class="page-link" v-html="paginationLink.label"></span>
                    </router-link>
                    <span class="page-link" v-else v-html="paginationLink.label"></span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <!-- Accept Day Off Modal -->
      <div class="modal fade" id="accept-hourchange-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="acceptHoursRequestsLabel">Accept Hour Change?</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input name="id" id="id" type="hidden" v-model="hoursData.id" />
              <div class="mb-3">
                The changes will be finalized.
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="confirmAcceptHourChange">Accept</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Reject Day Off Modal -->
      <div class="modal fade" id="reject-hourchange-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="rejectHoursRequestsLabel">Reject Hour Change?</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input name="id" id="id" type="hidden" v-model="hoursData.id" />
              <div class="mb-3">
                The changes will be rejected, and the hour entry will remain unchanged.
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="confirmRejectHourChange">Reject</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, accept, reject } from '@/services/hoursrequests';
import { useToast } from 'vue-toastification';

const defaultHoursData = {
  id: '',
  user: '',
  from: '',
  to: '',
  description: ''
};

export default {
  name: 'HoursRequests',
  setup() {
    const route = useRoute();
    const router = useRouter();

    const toast = useToast();

    const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

    const hoursData = ref(defaultHoursData);
    const selectedRows = ref([]); // Track selected rows

    // Methods
    const acceptHourChange = (infoId) => {
      hoursData.value.id = infoId;
      $('#accept-hourchange-form').modal('toggle');
    };

    const rejectHourChange = (infoId) => {
      hoursData.value.id = infoId;
      $('#reject-hourchange-form').modal('toggle');
    };

    const confirmAcceptHourChange = async () => {
      try {
        const response = await accept(hoursData.value);
        if (response.data.success) {
          toast.success(response.data.message);
          $('#accept-hourchange-form').modal('toggle');
          fetchData();
          selectedRows.value = []; // Reset selected rows
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('Error accepting request');
      }
    };

    const confirmRejectHourChange = async () => {
      try {
        const response = await reject(hoursData.value);
        if (response.data.success) {
          toast.success(response.data.message);
          $('#reject-hourchange-form').modal('toggle');
          fetchData();
          selectedRows.value = []; // Reset selected rows
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('Error rejecting request');
      }
    };

    const acceptSelectedHours = async () => {
      if (!selectedRows.value.length) {
        toast.error('Δεν έχετε επιλέξει ώρες');
        return;
      }

      try {
        const promises = selectedRows.value.map(id => accept({ id, accepted: 1 }));
        const responses = await Promise.all(promises);

        const successCount = responses.filter(response => response.data.success).length;
        const failureCount = responses.length - successCount;

        if (successCount > 0) {
          toast.success(`${successCount} requests accepted successfully.`);
        }

        if (failureCount > 0) {
          toast.error(`${failureCount} requests failed to accept.`);
        }

        fetchData();
        selectedRows.value = []; // Reset selected rows
      } catch (error) {
        toast.error('Error accepting selected requests');
      }
    };

    const rejectSelectedHours = async () => {
      if (!selectedRows.value.length) {
        toast.error('Δεν έχετε επιλέξει ώρες για απόρριψη');
        return;
      }

      try {
        const promises = selectedRows.value.map(id => reject({ id, accepted: 0 }));
        const responses = await Promise.all(promises);

        const successCount = responses.filter(response => response.data.success).length;
        const failureCount = responses.length - successCount;

        if (successCount > 0) {
          toast.success(`${successCount} hours requests rejected successfully.`);
        }

        if (failureCount > 0) {
          toast.error(`${failureCount} hours requests failed to reject.`);
        }

        fetchData();
        selectedRows.value = []; // Reset selected rows
      } catch (error) {
        toast.error('Error rejecting selected requests');
      }
    };

    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    const searchAction = () => {
      setTimeout(() => {
        router.push({path: '/hours-requests', query: {...route.query, search: $("#main-search-input").val()}});
      }, 300);
    };

    // Fetch data on component mount
    onMounted(async () => {
        $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
        await fetchData();
    });

    onUnmounted(() => {
        $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
    });

    return {
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      hoursData,
      selectedRows,
      getPageFromUrl,
      acceptHourChange,
      confirmAcceptHourChange,
      rejectHourChange,
      confirmRejectHourChange,
      acceptSelectedHours,
      rejectSelectedHours, sortBy
    };
  },
  metaInfo: {
    title: 'Hours Requests',
    meta: [
      { name: 'description', content: 'MIS - Hours Requests' },
      { property: 'og:title', content: 'MIS - Hours Requests' },
      { property: 'og:description', content: 'This is the hours requests page.' }
    ]
  }
};
</script>



<style scoped>
.dropdown-menu.show {
  display: block;
}

.approved{
  background-color: rgb(107, 219, 107);
}

.not-approved{
  background-color: rgb(241, 111, 111);
}
</style>