import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/get-notification-settings', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function fetchAllServiceGroups() {
  try {
    const response = await apiClient.get('/get-all-servicegroups'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function update(data) {
  try {
    const response = await apiClient.post('/update-notification-settings', {
        key: data.key,
        value: data.value
    });
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function updateAll(data) {
    try {
      const response = await apiClient.post('/update-notification-settings', {
        updateData: data
      });
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response;
      } else if (error.request) {
        // The request was made but no response was received
        return { success: false, message: "No response received", details: error.request };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }


export {fetchAll, update, updateAll}