

<template>
  <div class="login-wrapper d-flex justify-content-center w-100 h-100">
    <!-- Left Section: Logo and App Title -->
    <div class="col-xl-6 p-3 mb-3 mb-xl-0 col-12 d-flex flex-column justify-content-center align-items-center text-center" style="background: #ECECEC;">
      <img src="../assets/images/logo.png" class="logo-img" style="width:30em" alt="Citrine Logo" >

    </div>
    <!-- Right Section: Login Form -->
    <div class="col-xl-6 col-12 d-flex flex-column justify-content-center align-items-center">
      <div class="form-container">
        <h2 class="fs-1 text-center loginTitle">ΚΑΛΩΣ </h2>
        <h2 class="fs-1 text-center loginTitle"> ΗΡΘΑΤΕ</h2>
        <small class="mb-3 d-block text-center">Συνδεθείτε στο λογαριασμό σας.</small>
        <form class="row g-1 p-3 p-md-4 justify-content-center align-items-center" @submit.prevent="login" @keydown.enter.prevent="login" method="POST">
          <div class="col-8" v-if="twoFactor">
            <div class="mb-2">
              <label class="form-label color-600">Κωδικός επιβεβαίωσης</label>
              <input type="text" class="form-control form-control-md login-email" placeholder="123456" name="twoFactor" v-model="twoFactorCode">
            </div>
          </div>
          <div class="col-8" v-if="!twoFactor">
            <div class="mb-2">
              <label class="form-label color-600">Email</label>
              <input type="email" class="form-control form-control-md login-email" placeholder="name@example.com" name="username" v-model="username">
            </div>
          </div>
          
          <div class="col-8" v-if="!twoFactor">
            <div class="mb-2">
              <div class="form-label">
                <span class="d-flex color-600 justify-content-between align-items-center">
                  Κωδικός πρόσβασης
                </span>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <input id="showPassword" type="password" v-model="password" class="form-control form-control-md login-password" placeholder="********">
                <span class="showPasswordIcon" @click="showPass()"><i class="icofont-eye-alt"></i></span>
              </div>
            </div>
          </div>

          <div class="col-8" v-if="!twoFactor">
            <div class="form-check">
              <span class="d-flex justify-content-between align-items-center">
             <div><input class="form-check-input" type="checkbox" v-model="remember_me" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Nα με θυμάσαι
              </label> </div>
              
              <a class="text-secondary" @click.prevent="goToResetPassword()" href="#">Ξέχασα τον κωδικό μου</a>
                </span>

            </div>

          </div>


          <div class="col-12 text-center mt-4" v-if="!twoFactor">
            <button type="button" class="w-sm-100 ms-0 btn btn-secondary" alt="signin" @click="login">ΣΥΝΔΕΣΗ</button>
          </div>

          <div class="col-12 text-center mt-4" v-if="twoFactor">
            <button class="fw-bold btn px-4 py-2 text-uppercase btn-validate-2fa-code" @click="verifyCode" style="background-color: #828282; color:white">ΕΠΑΛΗΘΕΥΣΗ</button>
          </div>

        </form>

        <div class="error-div" v-if="errorMessage">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, onUnmounted } from 'vue';
import { login as authLogin } from '../services/auth.js';
import apiClient from '@/services/api.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      remember_me: 0,
      errorMessage: null,
      twoFactor: false,
      twoFactorCode: '',
      browserFingerprint: null,
    };
  },
  setup() {
    const handleKeyPressed = (event) => {
      if (event.key === 'Enter') {
        $('.btn-login').trigger('click');
      }
    };

    onMounted(() => {
      document.addEventListener('keyup', handleKeyPressed);
    });

    onUnmounted(() => {
      document.removeEventListener('keyup', handleKeyPressed);
    });
  },
  async mounted() {
    // Load the FingerprintJS agent
    const fp = await FingerprintJS.load();

    // Get the fingerprint data asynchronously
    const result = await fp.get();
    this.browserFingerprint = result.visitorId;  // Save the browser fingerprint
  },
  methods: {
    goToResetPassword() {
      this.$router.push('/reset-password');
    },
    async send2faVerificationCode() {
      const response = await apiClient.post('/send-2fa-code', {
        username: this.username,
        password: this.password,
        b_id: this.browserFingerprint // Use the browser fingerprint here
      });
      return response.data.success;
    },
    async login() {
      this.errorMessage = null;
      if (this.username && this.password) {
        try {
          const response = await authLogin(this.username, this.password, this.browserFingerprint);
          if (response.data?.success) {
            if (response.data.data['2fa'] && !this.twoFactor) {
              this.twoFactor = true;
              const codeSent = await this.send2faVerificationCode();
              if (!codeSent) {
                this.errorMessage = "Failed to send 2FA code";
              }
            } else if (response.data?.data?.token?.plainTextToken) {
              localStorage.setItem('api_token', response.data?.data?.token?.plainTextToken);
              localStorage.setItem('current_user_id', response.data?.data.info.id);
              this.$router.push('/projects');
            }
          } else {
            this.errorMessage = response.data.message;
          }
        } catch (e) {
          this.errorMessage = "Λάθος στοιχεία σύνδεσης";
        }
      }
    },
    async verifyCode() {
      try {
        const response = await apiClient.post('/validate-2fa-code', {
          username: this.username,
          password: this.password,
          remember_me: this.remember_me ? 1 : 0,
          code: this.twoFactorCode,
          b_id: this.browserFingerprint // Use the browser fingerprint here
        });
        if (response.data.success) {
          // Manually set the cookie
          const token = response.data.data.token;
          const prefix = response.data.data.prefix;
          const expires = new Date();
          expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days expiration time
          
          // Setting the cookie
          document.cookie = `${prefix}=${token}; expires=${expires.toUTCString()}; path=/; secure`;

          setTimeout(this.login, 500);
        } else {
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        this.errorMessage = "Verification failed";
      }
    },
    showPass() {
      const passField = document.getElementById('showPassword');
      passField.type = passField.type === 'password' ? 'text' : 'password';
    }
  }
};
</script>

<style>
.loginTitle{
  color:#5C5C5C;
  font-size:20em;
  font-weight: bolder;
}
</style>
