import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/dashboard/get-follow-up', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function fetchStatusesForEntity(entity) {
  try {
    const response = await apiClient.get('/get-clients-services', { params: {type: entity }}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function updateProjectStatus(projectId, statusId) {
  try {
    const response = await apiClient.post('/update-project-status/' + projectId, {statusId: statusId}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function updateTaskStatus(taskId, statusId) {
  try {
    const response = await apiClient.post('/update-task-status/' + taskId, {statusId: statusId}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function updateSubtaskStatus(subtaskId, statusId) {
  try {
    const response = await apiClient.post('/update-subtask-status/' + subtaskId, {statusId: statusId}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function get(data) {
  try {
    const response = await apiClient.get('/get-project', { params: data || {} }); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getClientsServicesStatusesUsers(data) {
  try {
      const response = await apiClient.get('/get-clients-services', data || {}); // Replace with your endpoint
      return response;
  } catch (error) {
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return error.response;
      } else if (error.request) {
          // The request was made but no response was received
          return { success: false, message: "No response received", details: error.request };
      } else {
          // Something happened in setting up the request that triggered an Error
          return { success: false, message: "Request setup error", details: error.message };
      }
  } 
}

async function fetchManagers() {
  try {
    const response = await apiClient.get('/all-users'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function changeManager(managerId) {
  try {
    const response = await apiClient.get('/dashboard/get-follow-up', {
      managerFilter: managerId
    });
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export { fetchAll, get, fetchManagers, changeManager, getClientsServicesStatusesUsers, updateProjectStatus, updateSubtaskStatus, updateTaskStatus, fetchStatusesForEntity };
