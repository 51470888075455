<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        <div
                            class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                            <div class="header-section">
                                <div class="search-area mt-2">
                                    <input id="main-search-input" type="search" class="form-control"
                                        placeholder="Αναζήτηση..." aria-label="search"
                                        aria-describedby="addon-wrapping" />
                                </div>
                            </div>
                            <div class="d-flex mt-sm-0 mt-3 justify-content-between">
                                <button type="button" class="ms-0 ms-sm-3 btn btn-dark" style="color:var(--secondary-color); font-weight:bold"
                                    @click.prevent="createClient" v-if="canCreateContact || superUser"><i class="icofont-plus me-2 fs-6" ></i>New contact</button>
                                    <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0" v-if="canArchive">
                                    <button class="ms-0 ms-sm-3 btn btn-primary dropdown-menu-toggler" type="button" style="color:var(--secondary-color); font-weight:bold">
                                        Ενέργειες
                                    </button>
                                    <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': isDropdownOpen}"
                                        :style="dropdownStyle">
                                        <li><a class="dropdown-item py-2 rounded archiveSelected" href="#"
                                                @click.prevent="archiveClients">Αρχειοθέτηση
                                                επιλεγμένων</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix g-3">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div class="card-body table-responsive">
                            <table id="clients" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:25%;"><div>Name<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('name')"></i></div></th>
                                        <th style="width:25%;"><div>Supplier<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('suppliers.nickname')"></i></div></th>
                                        <th style="width:25%;"><div>Phone<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('phone')"></i></div></th>
                                        <th style="width:25%;"><div>Email<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('email')"></i></div></th>
                                        <th style="width:5%; background-color:white; border:none" class="no-after"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contactData, index) in data" :key="contactData.id" :class="{ 'selected': selectedClientIds.includes(contactData.id) }" @click="toggleClientSelection(contactData.id, $event)">
                                        <td>
                                            {{ contactData.name }}
                                        </td>
                                        <td>
                                            {{ contactData.supplierNickname }}
                                        </td>
                                        <td>
                                            {{ contactData.phone }}
                                        </td>
                                        <td>
                                            {{ contactData.email }}
                                        </td>
                                        
                                        <td style="background-color:white">
                                            <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" class="btn btn-dark edit-client"
                                                    @click.prevent="editClient(contactData.id)" v-if="canEditContact(contactData) || canEditAllContacts || superUser">
                                                    <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                    class="menu-img" style="width:1.5em"></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="col" v-if="!data || !data.length">
                                        <td>
                                            No data
                                        </td>
                                        <td>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation" v-if="data && data.length">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                        <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                                            <router-link :to="{ path: 'contacts-suppliers', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                                                <span class="page-link" v-html="paginationLink.label"></span>
                                            </router-link>
                                            <span class="page-link" v-else v-html="paginationLink.label"></span>
                                        </li>
                                    </ul>
                                </nav>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="modal fade" id="create-client-form" tabindex="-1" aria-labelledby="createprojectlLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold" id="createprojectlLabel">Στοιχεία επαφής</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input name="id" id="id" type="hidden" v-model="contactData.id" />
                       
                        <div class="mb-3">
                            <label for="name" class="form-label">Name</label>
                            <input type="text" class="form-control" id="name" v-model="contactData.name"
                                placeholder="Name">
                        </div>
                        <div class="mb-3">
                            <label for="occupation" class="form-label">Occupation</label>
                            <input type="text" class="form-control" id="occupation" v-model="contactData.occupation"
                                placeholder="Occupation">
                        </div>
                        <div class="mb-3">
                            <label for="description" class="form-label">Description</label>
                            <input type="text" class="form-control" id="description" v-model="contactData.description"
                                placeholder="Description">
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" v-model="contactData.email"
                                placeholder="Email">
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label">Phone</label>
                            <input type="text" class="form-control" id="phone" v-model="contactData.phone"
                                placeholder="Phone">
                        </div>
                        <div class="mb-3">
                            <label for="address" class="form-label">Address</label>
                            <input type="text" class="form-control" id="address" v-model="contactData.address"
                                placeholder="Address">
                        </div>
                        <div class="mb-3">
                            <label for="postal" class="form-label">Postal code</label>
                            <input type="text" class="form-control" id="postal" v-model="contactData.postal"
                                placeholder="Postal">
                        </div>
                        <div class="mb-3">
                            <label for="afm" class="form-label">AFM</label>
                            <input type="text" class="form-control" id="afm" v-model="contactData.afm"
                                placeholder="AFM">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Client</label>
                            <Select2 :options="allData.clients" v-model="contactData.clientId" :settings="select2Settings" />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Supplier</label>
                            <Select2 :options="allData.suppliers" v-model="contactData.supplierId" :settings="select2Settings" />
                        </div>
                        
                    </div>
                    <div class="modal-footer">
<!--                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
 -->                        <button type="button" class="btn btn-secondary" @click="submitForm">Αποθήκευση</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveContacts, getClientsServicesStatusesUsers} from '@/services/contacts-suppliers';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';


const defaultcontactData = {
    id: '',
    description: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    postal: '',
    occupation: '',
    afm: '',
    potential: '',
    foundUs: '',
    notes: '',
    paid: '',
    supplierId: '',
    clientId: ''
};

export default {
    name: 'Clients',
    components: {
        Editor,
        Select2
    },
    methods: {
        
        canEditContact(contact) {
            if (!contact) { return false; }
            return localStorage.getItem('current_user_id') == contact?.createdBy && this.userPermissions?.edit === 1;
        },
       
    },
    computed: {
    ...mapGetters({
        getUserPermission: 'getUserPermission',
        getSuperUserPermission: 'getSuperUserPermission', // Add this line

    }),
    
    
    superUser() {
        return this.getSuperUserPermission === 1;
    },
     userPermissions() {
        return this.getUserPermission('Contacts');
    },
    
    canEditAllContacts() {
        return this.userPermissions?.editAll === 1;
    },
    canCreateContact() {
        return this.userPermissions?.create === 1;
    },
    canArchive() {
        return this.userPermissions?.archive === 1;
    },
  },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const allData = ref({});


        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1,  active:1, archived:0 }, { page: 1, active:1, archived:0 });

        const contactData = ref(defaultcontactData);
        const selectedClientIds = ref([]);

        const isDropdownOpen = ref(false);

        const toggleClientSelection = (clientId, event) => {
            if (!selectedClientIds.value.includes(clientId)) {
                selectedClientIds.value.push(clientId);
            } else {
                selectedClientIds.value = selectedClientIds.value.filter(id => id !== clientId);
            }
        };

        const archiveClients = async () => {
            if (!selectedClientIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει επαφές');
                return;
            }
            const response = await massArchiveContacts({ ids: selectedClientIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const editClient = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers();

            let newData = {clients: [], suppliers: []};
           
            
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title});
            }
            for (let i = 0; i < tmpAllData.data.suppliers.length; i++) {
                newData.suppliers.push({id: tmpAllData.data.suppliers[i].id, text: tmpAllData.data.suppliers[i].title});
            }
            allData.value = newData;
            const clientInfo = await get({ "id": id });
            if (clientInfo.data.success) {
                contactData.value.id = clientInfo.data.data.id;
                contactData.value.name = clientInfo.data.data.name;
                contactData.value.description = clientInfo.data.data.description;
                contactData.value.email = clientInfo.data.data.email;
                contactData.value.phone = clientInfo.data.data.phone;
                contactData.value.address = clientInfo.data.data.address;
                contactData.value.postal = clientInfo.data.data.postal;
                contactData.value.occupation = clientInfo.data.data.occupation;
                contactData.value.afm = clientInfo.data.data.afm;
                contactData.value.clientId = clientInfo.data.data.clientId;
                contactData.value.supplierId = clientInfo.data.data.supplierId;
                contactData.value.notes = clientInfo.data.data.notes;
                contactData.value.paid = clientInfo.data.data.paid;
            }
            $("#create-client-form").modal('toggle');
        };

        const createClient = async () => {
            const tmpAllData = await getClientsServicesStatusesUsers();

            let newData = {clients: [], suppliers: []};
           
            
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title});
            }
            for (let i = 0; i < tmpAllData.data.suppliers.length; i++) {
                newData.suppliers.push({id: tmpAllData.data.suppliers[i].id, text: tmpAllData.data.suppliers[i].title});
            }
            allData.value = newData;
            contactData.value = {
                id: '',
                name: '',
                description: '',
                email: '',
                phone: '',
                address: '',
                postal: '',
                occupation: '',
                afm: '',
                potential: '',
                foundUs: '',
                notes: '',
                paid: ''
            };
            $("#create-client-form").modal('toggle');
        };

        const submitForm = async () => {
            const response = await createOrUpdate(contactData.value);
            if (response.data.success) {
                useToast().success(response.data.message);
                $("#create-client-form").modal('toggle');
                fetchData();
            } else {
                useToast().error('An error occurred');
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const closeMenu = (event) => {
            if (document.querySelector('.dropdown-menu').length && !document.querySelector('.dropdown-menu').contains(event.target) && !document.querySelector('.dropdown-menu-toggler').contains(event.target)) {
                isDropdownOpen.value = false;
            }
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const select2Settings = {
            dropdownParent: '#create-client-form .modal-content',
            placeholder: "Please select"
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/contacts-suppliers', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        onMounted(() => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            document.addEventListener('click', closeMenu);
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
            document.removeEventListener('click', closeMenu);
        });

        return { data, allData, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, contactData, submitForm, editClient, createClient, selectedClientIds, toggleClientSelection, archiveClients, dropdownStyle, closeDropdown, isDropdownOpen, toggleDropdown, select2Settings, sortBy };
    },
    metaInfo: {
        title: 'Επαφές',
        meta: [
            { name: 'description', content: 'MIS - Clients' },
            { property: 'og:title', content: 'MIS - Clients' },
            { property: 'og:description', content: 'This is the clients page.' }
        ]
    }
};
</script>

<style scoped>
.dropdown-menu.show {
    display: block;
}
tr.selected, div.selected {
    background: #b1afaf;
}
</style>
