<template>
  <div id="mytask-layout" class="theme-indigo">
    <!--Menu Sidebar-->
    <div id="main-menu">
      <div class="sidebar px-2  py-md-4 me-0">
        <div class="d-flex flex-column h-100">
          <RouterLink v-if="superUser || canViewDashboard" to="/dashboard">
            <a href="#" class="mb-3 brand-icon dashboard-link justify-content-center">
              <span class="logo-icon">
                <img src="../assets/images/logo.png" class="logo-img" style="width:15em" alt="Citrine Logo" >
              </span>
            </a>
          </RouterLink>
          <RouterLink v-if="!superUser && !canViewDashboard" to="/projects">
            <a href="#" class="mb-3 mt-1 brand-icon dashboard-link justify-content-center">
              <span class="logo-icon">
                <img src="../assets/images/logo.png" class="logo-img " style="width:15em" alt="Citrine Logo" >
              </span>
            </a>
          </RouterLink>
          <!-- Menu: main ul -->
          <ul class="menu-list flex-grow-1 mt-3">
            <li :class="{ collapsed: menuItemsCollapsed.includes('dashboard') }">
              <a class="m-link" style="cursor:pointer" @click.prevent="openMainMenuItem('dashboard')">
                <img src="../assets/images/icons-01.png" alt="Citrine Logo" class="menu-img" style="width:2em"> <span>Dashboard</span>
                <span :class="{
                  'arrow icofont-dotted-down ms-auto text-end fs-5': !menuItemsCollapsed.includes('dashboard'),
                  'arrow icofont-dotted-up ms-auto text-end fs-5': menuItemsCollapsed.includes('dashboard')
                }"></span>
              </a>
              <ul :class="{
                'submenu collapsed': menuItemsCollapsed.includes('dashboard'),
                'submenu collapse': !menuItemsCollapsed.includes('dashboard')
              }" id="dashboard-Components" style="list-style: none">
                <li>
                  <RouterLink to="/follow-up"><a class="ms-link"><i class="icofont-tasks fs-5"></i> <span>Follow
                        Up</span></a></RouterLink>
                </li>
                <li>
                  <RouterLink to="/to-do"><a class="ms-link"><i class="icofont-tick-mark fs-5"></i>  <span>To
                        Do</span></a></RouterLink>
                </li>
                <li v-if="userInfo && userInfo.superUser === 1">
  <div class="d-flex align-items-center justify-content-between">
    <RouterLink to="/hours-requests">
      <a class="ms-link"><i class="icofont-ui-clock"></i> <span>Hours Requests</span></a>
    </RouterLink>
    <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo" class="menu-img" style="width:1.3em">
  </div>
</li>
<li v-if="userInfo && userInfo.superUser === 1">
  <div class="d-flex align-items-center justify-content-between">
    <RouterLink to="/days-off-requests">
      <a class="ms-link"><i class="icofont-beach-bed"></i> <span>Days off Requests</span></a>
    </RouterLink>
    <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo" class="menu-img" style="width:1.3em">
  </div>
</li>
<li v-if="userInfo && userInfo.superUser === 1">
  <div class="d-flex align-items-center justify-content-between">
    <RouterLink to="/missing-hours">
      <a class="ms-link"><i class="icofont-beach-bed"></i> <span>Missing Hours</span></a>
    </RouterLink>
    <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo" class="menu-img" style="width:1.3em">
  </div>
</li>

              </ul>
            </li>

            <li v-if="canViewUsers || superUser">
              <RouterLink to="/users"><a class="m-link users-link"><img src="../assets/images/icons-03.png" class="menu-img" alt="Citrine Logo" style="width:2em">
                  <span>Users</span></a></RouterLink>
            </li>
            <li v-if="canViewProjects || superUser">
              <RouterLink to="/projects"><a class="m-link"><img src="../assets/images/icons-02.png" class="menu-img" alt="Citrine Logo" style="width:2em"> <span>Projects</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewProjects || superUser">
              <RouterLink to="/hosting"><a class="m-link"><img src="../assets/images/icons-04.png" class="menu-img" alt="Citrine Logo" style="width:2em"> <span>Hosting</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewServices || superUser">
              <RouterLink to="/services"><a class="m-link"><img src="../assets/images/icons-05.png" class="menu-img" alt="Citrine Logo" style="width:2em"> <span>Services</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewGroups || superUser">
              <RouterLink to="/groups"><a class="m-link"><img src="../assets/images/icons-06.png" class="menu-img" alt="Citrine Logo" style="width:2em"> <span>Groups</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewClients || superUser">
              <RouterLink to="/clients"><a class="m-link"><img src="../assets/images/icons-07.png" class="menu-img" alt="Citrine Logo" style="width:2em"> <span>Clients</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewSuppliers || superUser">
              <RouterLink to="/suppliers"><a class="m-link"><img src="../assets/images/icons-08.png" class="menu-img" alt="Citrine Logo" style="width:2em"> <span>Suppliers</span></a>
              </RouterLink>
            </li>
            <li :class="{ collapsed: menuItemsCollapsed.includes('contacts') }" v-if="canViewContacts || superUser">
              <a class="m-link" style="cursor:pointer" @click.prevent="openMainMenuItem('contacts')">
                <img src="../assets/images/icons-06.png" alt="Citrine Logo" class="menu-img" style="width:2em"><span>Contacts</span>
                <span :class="{
                  'arrow icofont-dotted-down ms-auto text-end fs-5': !menuItemsCollapsed.includes('contacts'),
                  'arrow icofont-dotted-up ms-auto text-end fs-5': menuItemsCollapsed.includes('contacts')
                }"></span>
              </a>
              <ul :class="{
                'submenu collapsed': menuItemsCollapsed.includes('contacts'),
                'submenu collapse': !menuItemsCollapsed.includes('contacts')
              }" id="contacts-Components" style="list-style: none">
                <li>
                  <RouterLink to="/contacts-clients"><a class="ms-link"><img src="../assets/images/icons-07.png" alt="Citrine Logo" style="width:2em"><span>Clients</span></a></RouterLink>
                </li>
                <li>
                  <RouterLink to="/contacts-suppliers"><a class="ms-link"><img src="../assets/images/icons-08.png" alt="Citrine Logo" style="width:2em"><span>Suppliers</span></a></RouterLink>
                </li>
                <li>
                  <RouterLink to="/orphaned-contacts"><a class="ms-link"><i class="icofont-ui-remove" style=" margin-left:0.5em"></i><span>Orphaned</span></a></RouterLink>
                </li>
              </ul>
            </li>

            <li v-if="canViewHours || superUser">
              <RouterLink to="/hours"><a class="m-link"><i class="icofont-clock-time" style=" margin-left:0.5em"></i><span>Hours</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewDaysOff || superUser">
              <RouterLink to="/days-off"><a class="m-link"><i class="icofont-beach-bed" style=" margin-left:0.5em"></i><span>Days Off</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewDaysOffReports || superUser">
              <RouterLink to="/days-off-reports"><a class="m-link"><i class="icofont-beach-bed" style=" margin-left:0.5em"></i><span>Days Off
                    Reports</span></a></RouterLink>
            </li>
            <li v-if="canViewHolidays || superUser">
              <RouterLink to="/holidays"><a class="m-link"><i class="icofont-beach-bed" style=" margin-left:0.5em"></i><span>Holidays</span></a>
              </RouterLink>
            </li>
            <li v-if="canViewReporting || superUser">
              <RouterLink to="/reports"><a class="m-link"><i class="icofont-chart-histogram" style=" margin-left:0.5em"></i><span>Reporting</span></a>
              </RouterLink>
            </li>
            <li v-if="superUser">
              <div class="d-flex align-items-center justify-content-between">
                  <RouterLink to="/history-changes"><a class="m-link"><i class="icofont-history" style=" margin-left:0.5em"></i><span>Clients History</span></a>
                  </RouterLink>
                  <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo" class="menu-img" style="width:1.3em">
               </div>
            </li>
            <li>
              <RouterLink to="/notifications"><a class="m-link"><i class="icofont-notification" style=" margin-left:0.5em"></i><span> Notifications</span></a></RouterLink>
            </li>
          </ul>

          <!-- Theme: Switch Theme -->
          <!-- <ul class="list-unstyled mb-0">
            <li class="d-flex align-items-center justify-content-center">
              <div class="form-check form-switch theme-rtl">
                <input class="form-check-input" type="checkbox" id="theme-rtl" v-model="isRtl">
                <label class="form-check-label" for="theme-rtl">Αλλαγή θέσης μενού</label>
              </div>
            </li>
          </ul> -->

          <!-- Menu: menu collapse btn -->
          <button type="button" class="btn btn-link sidebar-mini-btn text-light" style="" @click="toggleSidebarMini">
            <span class="ms-2"><i class="icofont-bubble-right"></i></span>
          </button>

        </div>
      </div>
    </div>
    <!-- End of Menu Sidebar -->

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!--Navigation bar-->
      <div id="main-header">
        <nav class="navbar mt-3">
          <div class="container-xxl mb-0" >
            <!-- header rightbar icon -->
            <div class="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
              <div class="d-flex"></div>
              <div class="form-check form-switch theme-switch">
                <input class="form-check-input" type="checkbox" id="theme-switch"
                  :class="{ 'darkMode': !isLightMode, 'lightMode': isLightMode }" @click="toggleTheme">
              </div>
              <div class="dropdown notifications zindex-popover">
                <a @click.prevent="toggleNotificationMenu()"
                  class="nav-link dropdown-toggle notifications-menu-toggler pulse" href="#" role="button"
                  data-bs-toggle="dropdown">
                  <img src="../assets/images/ring.png" alt="Citrine Logo" class="menu-img" style="width:2em;">
                    <span id="notificationBadge" class="position-absolute top-30 start-30 translate-middle badge rounded-pill bg-danger">  {{ notificationCount }} </span>
                </a>
                <div id="NotificationsDiv"
                  :class="{ 'dropdown-menu notifications-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-sm-end p-0 m-0': !isNotificationsOpen, 'dropdown-menu notifications-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-sm-end p-0 m-0 show': isNotificationsOpen }"
                  :style="notificationStyle">
                  <div class="card border-0 w380">
                    <div class="card-header border-0 p-3">
                      <h5 class="mb-0 font-weight-light d-flex justify-content-between">
                        <span>Notifications</span>
                        <span class="badge" id="notificationsCount"></span>
                        </h5>
                    </div>
                    <div class="tab-content card-body">
                      <div class="tab-pane fade show active">
                        <ul class="list-unstyled list mb-0" id="notifications-list"></ul>
                      </div>
                    </div>
                    <a class="card-footer text-center border-top-0">
                      <RouterLink to="/notifications">All Notifications</RouterLink>
                    </a>
                  </div>
                </div>
              </div>
              <div class="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
                <!-- <div class="u-info me-2">
                  <p class="mb-0 text-end line-height-sm"><span class="font-weight-bold user-name" v-if="userInfo">{{
                    userInfo.name }}</span></p>
                  <small class="user-email" v-if="userInfo">{{ userInfo.email }}</small>
                </div> -->
                <a @click.prevent="toggleMenu()" class="nav-link dropdown-toggle user-menu-toggler pulse p-0" href="#"
                  role="button" data-bs-toggle="dropdown" data-bs-display="static">
                  <img class="avatar lg rounded-circle img-thumbnail" :src="avatarUrl"
                    alt="profile">
                </a>
                <div
                  :class="{ 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0': !isMenuOpen, 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 show': isMenuOpen }"
                  :style="menuStyle">
                  <div class="card border-0 w280">
                    <div class="card-body pb-0">
                      <div class="d-flex py-1">
                        <img class="avatar rounded-circle" :src="avatarUrl" alt="profile">
                        <div class="flex-fill ms-3">
                          <p class="mb-0"><span class="font-weight-bold user-name" v-if="userInfo">{{ userInfo.name
                              }}</span></p>
                          <small class="user-email" v-if="userInfo">{{ userInfo.email }}</small>
                        </div>
                      </div>
                      <div>
                        <hr class="dropdown-divider border-dark">
                      </div>
                    </div>
                    <div class="list-group m-2">
                      <div>
                        <div class="container-xxl users-container mb-1 mt-1" v-if="showUsersContainer">
                          <div class="col-md-12">
                            <label class="form-label" for="user">Αλλαγή πελάτη</label>
                            <select class="form-select" name="user" id="user" aria-label="Πελάτης"
                              aria-placeholder="Πελάτης" placeholder="Πελάτης" v-model="selectedUser"
                              @change="changeUserHandler(selectedUser)">
                              <option value="" selected>Παρακαλώ επιλέξτε</option>
                              <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} {{
                                user.surname }}</option>
                            </select>
                          </div>
                        </div>




                      </div>
                      <RouterLink to="/change-profile"><a class="list-group-item list-group-item-action border-0"><i
                            class="icofont-lock fs-5 me-3"></i>Επεξεργασία λογαριασμού</a></RouterLink>
                      <RouterLink to="/my-permissions"><a class="list-group-item list-group-item-action border-0"><i
                              class="icofont-lock fs-5 me-3"></i>Τα δικαιώματά μου</a></RouterLink>
                      <RouterLink to="/notification-settings"><a
                          class="list-group-item list-group-item-action border-0"><i
                            class="icofont-ui-settings fs-5 me-3"></i>Ρυθμίσεις ειδοποιήσεων</a></RouterLink>
                      <a @click.prevent="logout" href="#"
                        class="list-group-item list-group-item-action border-0 btn-logout"><i
                          class="icofont-logout fs-6 me-3"></i>Αποσύνδεση</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- menu toggler -->
            <button class="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" @click="toggleSidebar">
              <span class="fa fa-bars"></span>
            </button>


            <!-- main menu Search-->
            <div class="d-flex order-0 col-lg-6 col-md-3 col-sm-12 col-12 mb-1 mb-md-0">
              <h3 class="page-header " >{{ pageHeader }} </h3><p class="ms-3" style="font-size: x-large;" v-if="userInfo && pageHeader == 'Days Off'" > (Available Days: {{ userInfo.remaining_days_off }} )</p>
            </div>
          </div>
        </nav>
      </div>
      <!--end of Navigation bar-->

      <div id="main-container">
        <LoadingSpinner :isLoading="isLoading" />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { fetchUserInfo, fetchUsers, changeUser, getAllUnreadNotifications } from '../services/user'; // Adjust this import as needed
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';



// Define a reactive variable to track whether the current mode is light or dark
const isLightMode = ref(false);

export default {
  name: 'MainLayout',
  components: {
    LoadingSpinner
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    ...mapActions(['fetchUserData']),
  },
  computed: {
    ...mapGetters({
      getUserPermission: 'getUserPermission',
      getSuperUserPermission: 'getSuperUserPermission'

    }),
    userPermissionsProjects() {
      return this.getUserPermission('Projects');
    },
    canViewProjects() {
      return this.userPermissionsProjects?.view === 1;
    },
    userPermissionsUsers() {
      return this.getUserPermission('Users');
    },
    canViewUsers() {
      return this.userPermissionsUsers?.view === 1;
    },
    userPermissionsServices() {
      return this.getUserPermission('Services');
    },
    canViewServices() {
      return this.userPermissionsServices?.view === 1;
    },
    userPermissionsSuppliers() {
      return this.getUserPermission('Suppliers');
    },
    canViewSuppliers() {
      return this.userPermissionsSuppliers?.view === 1;
    },
    userPermissionsClients() {
      return this.getUserPermission('Clients');
    },
    canViewClients() {
      return this.userPermissionsClients?.view === 1;
    },
    userPermissionsContacts() {
      return this.getUserPermission('Contacts');
    },
    userPermissionsGroups() {
      return this.getUserPermission('Groups');
    },
    canViewContacts() {
      return this.userPermissionsContacts?.view === 1;
    },
    canViewGroups() {
      return this.userPermissionsGroups?.view === 1;
    },
    userPermissionsHours() {
      return this.getUserPermission('Hours');
    },
    canViewHours() {
      return this.userPermissionsHours?.view === 1;
    },
    userPermissionsHolidays() {
      return this.getUserPermission('Holidays');
    },
    canViewHolidays() {
      return this.userPermissionsHolidays?.view === 1;
    },
    userPermissionsReporting() {
      return this.getUserPermission('Reporting');
    },
    canViewReporting() {
      return this.userPermissionsReporting?.view === 1;
    },
    userPermissionsDaysOff() {
      return this.getUserPermission('Days off');
    },
    canViewDaysOff() {
      return this.userPermissionsDaysOff?.view === 1;
    },
    userPermissionsDaysOffReports() {
      return this.getUserPermission('Days off reports');
    },
    canViewDaysOffReports() {
      return this.userPermissionsDaysOffReports?.view === 1;
    },
    canViewDashboard() {
            return this.userPermissions?.view === 1;
        },
    superUser() {
        return this.getSuperUserPermission === 1;
    },
  },
  setup() {
    const isLoading = ref(false);
    const userInfo = ref(null); // Use cached user info if available
    const users = ref([]); // Ref to store fetched users
    const selectedUser = ref(null); // Ref to store selected user
    const showUsersContainer = ref(true); // Ref to control visibility of users container
    const menuItemsCollapsed = ref(['dashboard', 'contacts']);

    const isMenuOpen = ref(false); // Reactive variable to track menu state
    const isNotificationsOpen = ref(false); // Reactive variable to track notifications state
    const router = useRouter();
    const isSidebarOpen = ref(false);
    const isSidebarMini = ref(false);
    const route = useRoute();
    const showSearch = ref(true);
    const avatarUrl = ref('/img/profile_av.7b0dcddf.png');

    const pageHeader = ref(null);
    const notificationCount = ref(0); // Initialize notification count
    const isFetchingNotifications = ref(false); // Background task loading state


    const fetchNotificationCount = async () => {
  try {
    isFetchingNotifications.value = true; 
    const response = await getAllUnreadNotifications();
    isLoading.value = false; // Access .value for ref modification


    if (response && response.data && response.data.data) {
      notificationCount.value = response.data.data.length;
    } else {
      console.error("Error in response format or no data received.");
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
  } finally {
    isFetchingNotifications.value = false; 
  }
};


    
    watch(
      () => route.fullPath,
      (newPath, oldPath) => {
        if (newPath.indexOf('/dashboard') !== -1 || newPath.indexOf('/holidays') !== -1 || newPath.indexOf('/days-off') !== -1 || newPath.indexOf('/notifications') !== -1 || newPath.indexOf('/days-off-reports') !== -1 || newPath.indexOf('/hours') !== -1 || newPath.indexOf('/missing-hours') !== -1 || newPath.indexOf('/days-off-requests') !== -1 || newPath.indexOf('/hours-requests') !== -1) {
          showSearch.value = false;
        } else {
          showSearch.value = true;
        }

        if(newPath.indexOf('/dashboard') !== -1){
          pageHeader.value = 'Dashboard';
        }
        if(newPath.indexOf('/follow-up') !== -1){
          pageHeader.value = 'Follow Up';

        }
        if(newPath.indexOf('/to-do') !== -1){
          pageHeader.value = 'To Do';
        }
      
      
        if(newPath.indexOf('/missing-hours') !== -1){
          pageHeader.value = 'Missing Hours Panel';
        } 
        if(newPath.indexOf('/users') !== -1){
          pageHeader.value = 'Users';
        } 
        if(newPath.indexOf('/project') !== -1){
          pageHeader.value = 'Project';
        }
        if(newPath.indexOf('/projects') !== -1){
          pageHeader.value = 'Projects';
        }
        if(newPath.indexOf('/hosting') !== -1){
          pageHeader.value = 'Hosting';
        }
        if(newPath.indexOf('/services') !== -1){
          pageHeader.value = 'Services';
        }
        if(newPath.indexOf('/groups') !== -1){
          pageHeader.value = 'Groups';
        }
        if(newPath.indexOf('/clients') !== -1){
          pageHeader.value = 'Clients';
        }
        if(newPath.indexOf('/suppliers') !== -1){
          pageHeader.value = 'Suppliers';
        }
        if(newPath.indexOf('/contacts-clients') !== -1){
          pageHeader.value = 'Contacts Clients';
        }
        if(newPath.indexOf('/contacts-suppliers') !== -1){
          pageHeader.value = 'Contacts Suppliers';
        }
        if(newPath.indexOf('/orphaned-contacts') !== -1){
          pageHeader.value = 'Orphaned Contacts';
        }
        if(newPath.indexOf('/days-off') !== -1){
          pageHeader.value = 'Days Off';
        }
        if(newPath.indexOf('/days-off-reports') !== -1){
          pageHeader.value = 'Days Off Reports';
        }
        if(newPath.indexOf('/holidays') !== -1){
          pageHeader.value = 'Holidays';
        }
        if(newPath.indexOf('/reports') !== -1){
          pageHeader.value = 'Reports';
        }
        if(newPath.indexOf('/hours') !== -1){
          pageHeader.value = 'Hours';
        }
        if(newPath.indexOf('/history-changes') !== -1){
          pageHeader.value = 'Ιστορικό αλλαγών σε clients';
        }
        if(newPath.indexOf('/notifications') !== -1){
          pageHeader.value = 'Notifications';
        }
        if(newPath.indexOf('/change-profile') !== -1){
          pageHeader.value = 'Επεξεργασία Λογαριασμού';
        }
        if(newPath.indexOf('/my-permissions') !== -1){
          pageHeader.value = 'My permissions';
        }

        if(newPath.indexOf('/hours-requests') !== -1){
          pageHeader.value = 'Hours Requests';
        }
         if(newPath.indexOf('/days-off-requests') !== -1){
          pageHeader.value = 'Days Off Requests';
        } 
        
        if(newPath.indexOf('/task') !== -1){
          pageHeader.value = 'Task';
        }
        if(newPath.indexOf('/subtask') !== -1){
          pageHeader.value = 'Subtask';
        }
        if(newPath.indexOf('/notification-settings') !== -1){
          pageHeader.value = 'Notification Settings';
        }
      });

    const openMainMenuItem = (menuItem) => {
      const index = menuItemsCollapsed.value.indexOf(menuItem);
      if (index > -1) {
        menuItemsCollapsed.value.splice(index, 1);
      } else {
        menuItemsCollapsed.value.push(menuItem);
      }
    };

    const toggleSidebarMini = () => {
    isSidebarMini.value = !isSidebarMini.value;
    $('.sidebar').toggleClass('sidebar-mini');

    // Adjust the width of the logo based on `isSidebarMini`
    const logoImg = document.querySelector('.logo-img');
    if (logoImg) {
        logoImg.style.width = isSidebarMini.value ? '6em' : '15em';
    }

     // Add or remove a class to center the images with the `menu-img` class
     const menuImgs = document.querySelectorAll('.menu-img');
    menuImgs.forEach(img => {
        if (isSidebarMini.value) {
            img.classList.add('centered-img');
        } else {
            img.classList.remove('centered-img');
        }
    });

    if (isSidebarMini.value) {
        $('.sidebar').removeClass('open');
    }
};


    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;

      if (isSidebarOpen.value) {
        // Sidebar is open
        $('.sidebar').addClass('open');
        if (isSidebarMini.value) {
          $('.sidebar').addClass('sidebar-mini');
        }
      } else {
        // Sidebar is closed

        $('.sidebar').removeClass('open');
        $('.sidebar').removeClass('sidebar-mini');
      }
    };

    const isRtl = ref(false); // Add this line to define reactive variable for RTL mode

       
    // Watch for changes in RTL mode checkbox
    watch(isRtl, (newValue) => {
      if (newValue) {
        document.body.classList.add('rtl_mode');
      } else {
        document.body.classList.remove('rtl_mode');
      }
    });


    const startLoading = () => {
      isLoading.value = true;
    };

    const stopLoading = () => {
      isLoading.value = false;
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value; // Toggle menu state
      isNotificationsOpen.value = false;
    };

    const getMentionType = (notificationType) => {
      switch (notificationType) {
        case 'DAYS_OFF_REQUEST_ACCEPTED':
          return 'DAYS OFF ▶ ACCEPTED';
        case 'DAYS_OFF_REQUEST_CREATION':
          return 'DAYS OFF ▶ CREATION';
        case 'DAYS_OFF_REQUEST_REJECTED':
          return 'DAYS OFF ▶ REJECTED';
        case 'HOURS_REQUEST_ACCEPTED':
          return 'HOURS ▶ ACCEPTED';
        case 'HOURS_REQUEST_CREATION':
          return 'HOURS ▶ CREATION';
        case 'HOURS_REQUEST_REJECTED':
          return 'HOURS ▶ REJECTED';
        case 'MANAGER_ASSIGNMENT_PROJECT':
          return 'ASSIGNMENT ▶ PROJECT MANAGER';
        case 'MANAGER_ASSIGNMENT_SUBTASK':
          return 'ASSIGNMENT ▶ SUBTASK MANAGER';
        case 'MANAGER_ASSIGNMENT_TASK':
          return 'ASSIGNMENT ▶ TASK MANAGER';
        case 'PROJECT_ASSIGNMENT':
          return 'ASSIGNMENT ▶ PROJECT';
        case 'PROJECT_COMMENT_MENTION':
          return 'PROJECT ▶ MENTION';
        case 'PROJECT_CREATOR_STATUS_CHANGES':
          return 'PROJECT ▶ STATUS CHANGE';
        case 'PROJECT_MANAGER_EDIT':
          return 'PROJECT ▶ CHANGE';
        case 'PROJECT_MANAGER_STATUS_CHANGES':
          return 'PROJECT ▶ STATUS CHANGE';
        case 'SUBTASK_ASSIGNMENT':
          return 'ASSIGNMENT ▶ SUBTASK';
        case 'SUBTASK_COMMENT_MENTION':
          return 'SUBTASK ▶ MENTION';
        case 'SUBTASK_CREATOR_STATUS_CHANGES':
          return 'SUBTASK ▶ STATUS CHANGE';
        case 'SUBTASK_MANAGER_EDIT':
          return 'SUBTASK ▶ CHANGE';
        case 'SUBTASK_MANAGER_STATUS_CHANGES':
          return 'SUBTASK ▶ STATUS CHANGE';
        case 'TASK_ASSIGNMENT':
          return 'ASSIGNMENT ▶ TASK';
        case 'TASK_COMMENT_MENTION':
          return 'TASK ▶ MENTION';
        case 'TASK_CREATOR_STATUS_CHANGES':
          return 'TASK ▶ STATUS CHANGE';
        case 'TASK_MANAGER_EDIT':
          return 'TASK ▶ CHANGE';
        case 'TASK_MANAGER_STATUS_CHANGES':
          return 'TASK ▶ STATUS CHANGE';
        case 'MISSING_HOURS_DAYS':
          return 'HOURS ▶ MISSING';
        case 'POTENTIAL_CLIENT_REMINDER':
          return 'CLIENT ▶ POTENTIAL REMINDER';
        case 'POTENTIAL_PROJECT_REMINDER':
          return 'PROJECT ▶ POTENTIAL REMINDER';
        case 'WRONG_HOSTING_EXPIRATION_DATA':
          return 'HOSTING ▶ WRONG EXPIRATION DATA';
        case 'HOSTING_WILL_EXPIRE':
          return 'HOSTING ▶ WILL EXPIRE';
        case 'HOSTING_EXPIRED':
          return 'HOSTING ▶ EXPIRED';
        default:
          return '-';
      }
    };


    const handleNotificationLinkClick = (event) => {
      event.preventDefault();
      const target = $(event.target);
      const dataId = target.attr('data-id');
      const dataType = target.attr('data-type');

      if (dataId) {
        switch (dataType) {
          case 'project':
            router.push({ path: '/project', query: { id: dataId } });
            break;
          case 'task':
            router.push({ path: '/task', query: { id: dataId } });
            break;
          case 'subtask':
            router.push({ path: '/subtask', query: { id: dataId } });
            break;
          case 'host':
            router.push({ path: '/host', query: { id: dataId } });
            break;
          // Add more cases here for different types
          default:
            console.log('Unknown data-type with ID:', dataType, dataId);
        }
      } else {
        switch (dataType) {
          case 'hours-creation':
            router.push({ path: '/hours-requests', query: {} });
            break;
          case 'days-off-creation':
            router.push({ path: '/days-off-requests', query: {} });
            break;
          case 'missing-hours-days':
            router.push({ path: '/hours', query: {} });
            break;
          // Add more cases here for different types
          default:
            console.log('Unknown data-type without ID:', dataType);
        }
      }
    };

    const getMentionTypeLink = (notification) => {
      let ret = '';

      if (notification.subtask_title) {
        ret = `in subtask <a href="#" class="notification-link" data-type="subtask" data-id="${notification.subtask_id}">${notification.subtask_title}</a>`;
      } else if (notification.task_title) {
        ret = `in task <a href="#" class="notification-link" data-type="task" data-id="${notification.task_id}">${notification.task_title}</a>`;
      } else if (notification.project_title) {
        ret = `in project <a href="#" class="notification-link" data-type="project" data-id="${notification.project_id}">${notification.project_title}</a>`;
      } else {
        let notificationType = '';
        if (notification.notificationType.indexOf('_REQUEST_') !== -1) {
          if (notification.notificationType.indexOf('CREATION') !== -1) {
            if (notification.notificationType.indexOf('DAYS_OFF') !== -1) {
              notificationType = 'days-off-creation';
              ret = `<a href="#" class="notification-link" data-type="${notificationType}">Days off requests</a>`;
            } else if (notification.notificationType.indexOf('HOURS') !== -1) {
              notificationType = 'hours-creation';
              ret = `<a href="#" class="notification-link" data-type="${notificationType}">Hours Requests</a>`;
            }
          } else if (notification.notificationType.indexOf('ACCEPTED') !== -1 || notification.notificationType.indexOf('REJECTED') !== -1) {
            if (notification.notificationType.indexOf('DAYS_OFF') !== -1) {
              notificationType = 'days-off-requests';
              ret = `<a href="#" class="notification-link" data-type="${notificationType}">Days off</a>`;
            } else if (notification.notificationType.indexOf('HOURS') !== -1) {
              notificationType = 'hours-requests';
              ret = `<a href="#" class="notification-link" data-type="${notificationType}">Hours</a>`;
            }
          }          
        } else {
          if (notification.notificationType == 'MISSING_HOURS_DAYS') {
            notificationType = 'missing-hours-days';
            ret = `<a href="#" class="notification-link" data-type="${notificationType}">Hours</a>`;
          } else if (notification.notificationType == 'POTENTIAL_CLIENT_REMINDER') {
            notificationType = 'potential-client-reminder';
            ret = `<a href="#" class="notification-link" data-type="${notificationType}">Clients</a>`;
          } else if (notification.notificationType == 'POTENTIAL_PROJECT_REMINDER') {
            notificationType = 'potential-project-reminder';
            ret = `<a href="#" class="notification-link" data-type="${notificationType}">${notification.description}</a>`;
          } else if (notification.notificationType == 'WRONG_HOSTING_EXPIRATION_DATA') {
            notificationType = 'wrong-hosting-expiration-data';
            ret = `<a href="#" class="notification-link" data-type="${notificationType}">${notification.description}</a>`;
          } else if (notification.notificationType == 'HOSTING_WILL_EXPIRE') {
            notificationType = 'hosting-will-expire';
            ret = `<a href="#" class="notification-link" data-type="${notificationType}">${notification.description}</a>`;
          } else if (notification.notificationType == 'HOSTING_EXPIRED') {
            notificationType = 'hosting-will-expire';
            ret = `<a href="#" class="notification-link" data-type="${notificationType}">${notification.description}</a>`;
          }
        }
      }

      if (notification.username) {
        ret += ` from: <span class="username">${notification.username}</span>`;
      }

      return ret;
    };


    const toggleNotificationMenu = async () => {
      isNotificationsOpen.value = !isNotificationsOpen.value; // Toggle notifications state
      isMenuOpen.value = false;

      if (isNotificationsOpen.value) {
        try {
          // Call getUnreadNotifications
          const response = await getAllUnreadNotifications();

          // Check if response and response.data exist
          if (response && response.data) {
            const notifications = response.data.data; // Access notifications directly
            // Check if notifications is an array
            if (Array.isArray(notifications)) {
              // Process notifications and update the UI
              const notificationsList = document.getElementById('notifications-list');
              notificationsList.innerHTML = ''; // Clear existing notifications
              $("#notificationsCount").text(notifications.length);

              notifications.forEach(notification => {
                const listItem = document.createElement('li');
                listItem.className = 'py-2 mb-1 border-bottom';

                const notificationMessage = document.createElement('p');
                notificationMessage.className = 'notification-message';
                notificationMessage.innerHTML = getMentionType(notification.notificationType) + ': ' + getMentionTypeLink(notification); // Use innerHTML to render HTML content
                listItem.appendChild(notificationMessage);

                const timestamp = document.createElement('span');
                timestamp.textContent = notification.created_at;
                listItem.appendChild(timestamp);

                notificationsList.appendChild(listItem);
              });
            } else {
              console.error("Notifications data is not in the expected format:", notifications);
            }
          } else {
            console.error("Response data is missing:", response);
          }
        } catch (error) {
          console.error("Failed to fetch notifications:", error);
        }
      }
    };

    const menuStyle = computed(() => ({
      top: isMenuOpen.value ? '100%' : 'initial',
      right: isMenuOpen.value ? '0' : 'initial'
    }));

    const notificationStyle = computed(() => ({
      top: isNotificationsOpen.value ? '100%' : 'initial',
      right: isNotificationsOpen.value ? '0' : 'initial'
    }));

    const fetchUserData = async () => {
      try {
        const response = await fetchUserInfo();
        userInfo.value = response.data?.info || null;
        if (response.data?.info.avatar_file_name) {
          avatarUrl.value = '/storage/avatars/' + response.data?.info.avatar_file_name;
        }
        if (userInfo.value.customer_id === null && userInfo.value.superUser === 1) {
          showUsersContainer.value = true; // Set showUsersContainer to true if conditions are met
          fetchUsersData(); // Fetch users data if conditions are met
        } else {
          showUsersContainer.value = false; // Set showUsersContainer to false if conditions are not met
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const response = await fetchUsers();
        showUsersContainer.value = response.data.success; // Set showUsersContainer based on response success
        if (response.data.success) {
          users.value = response.data.data; // Update users ref with response data
        } else {
          console.error("Failed to fetch users:", response.data.message);
        }
      } catch (error) {
        showUsersContainer.value = false; // Set showUsersContainer to false in case of error
        console.error("Failed to fetch users:", error);
      }
    };

    // Handle user change
    const changeUserHandler = async (userId) => {
      const userInfo = await fetchUserInfo();
      if (userInfo.data.info?.id) {
        if (userId && parseInt(userInfo.data.info?.id) != parseInt(userId)) {
          const toast = useToast();
          try {
            const response = await changeUser(userId); // Call the changeUser function with the selected userId
            if (response.data.success) {
              // Update the Authorization header with the new token
              const newToken = response.data.data.token.plainTextToken;

              localStorage.removeItem('api_token');
              localStorage.setItem('api_token', newToken);

              await fetchUserData();

              toast.success(response.data.message); // Show success message

              // Reload the page
              window.location.reload();
            } else {
              toast.error(response.data.message); // Show error message
            }
          } catch (error) {
            console.error('Error changing user:', error);
            toast.error('An error occurred while changing the user.'); // Show generic error message
          }
        }
      }
    };

    const closeMenu = (event) => {
      if (!document.querySelector('.user-menu').contains(event.target) && !document.querySelector('.user-menu-toggler').contains(event.target)) {
        isMenuOpen.value = false;
      }
      if (!document.querySelector('.notifications-menu').contains(event.target) && !document.querySelector('.notifications-menu-toggler').contains(event.target)) {
        isNotificationsOpen.value = false;
      }
    };

    const logout = (event) => {
      event.preventDefault();
      localStorage.removeItem('api_token');
      localStorage.removeItem('main_auth_token');
      router.push('/');
    };

    const theme = ref(localStorage.getItem('theme') || 'dark'); // Default theme is 'dark'

    const switchTheme = (e) => {
      if (e.target.checked) {
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem('theme', 'light');
      }
    };

    // Fetch notifications every 2 seconds
    let notificationInterval;


    onMounted(() => {
     
      
      if (!userInfo.value) {
        fetchUserData(); // Fetch user info if not already cached
      }


      $(document.body).on('click', '.notification-link', handleNotificationLinkClick);

      document.addEventListener('click', closeMenu);

      const currentTheme = localStorage.getItem('theme');

      if (currentTheme) {
        document.documentElement.setAttribute('data-theme', currentTheme);
      }
      router.beforeEach((to, from, next) => {
        startLoading();
        next();
      });

      router.afterEach(() => {
        stopLoading();
      });

      fetchNotificationCount(); // Fetch initial count

    });

    onUnmounted(() => {
      $(document.body).off('click', '.notification-link', handleNotificationLinkClick);
      document.removeEventListener('click', closeMenu);
      clearInterval(notificationInterval); // Clear interval when component is destroyed

    });

    // Define a method to toggle between light and dark modes
    const toggleTheme = () => {
      isLightMode.value = !isLightMode.value; // Toggle between dark and light mode
      const newTheme = isLightMode.value ? 'dark' : 'light'; // Determine the new theme
      document.documentElement.setAttribute('data-theme', newTheme); // Update the theme attribute of the root element
      localStorage.setItem('theme', newTheme); // Save the theme preference to local storage
    };

    return {
      showSearch,
      userInfo,
      users,
      selectedUser,
      menuStyle,
      toggleMenu,
      isMenuOpen,
      isNotificationsOpen,
      toggleNotificationMenu,
      notificationStyle,
      menuItemsCollapsed,
      notificationCount,
      openMainMenuItem,
      showUsersContainer,
      changeUserHandler,
      isLightMode,
      toggleTheme,
      theme,
      switchTheme,
      isLoading,
      toggleSidebar,
      isSidebarOpen,
      isSidebarMini,
      isRtl,
      toggleSidebarMini,
      logout,
      avatarUrl,
      logout,
      pageHeader
    };
  }
};
</script>


<style scoped>
.submenu.collapsed {
  display: none;
}

.submenu.collapse {
  display: block;
}

.arrow.icofont-dotted-up {
  transform: rotate(180deg);
}
.page-header {
  font-size: 2rem;
  font-weight: 700;
}
.btn {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); /* Adjust values as needed */
}
.centered-img {
    display: block;
    margin-left: 1.5em; /* Center-align the image */
}
.notifications {
  position: relative;
}

#notificationBadge {
  position: absolute;
  top: 6px;
  right: -3px;
  transform: translate(50%, -70%); /* To adjust the badge's position */
}


</style>