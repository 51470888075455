<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="body d-flex py-3">
                <div class="container-xxl">
                    
                </div>
                <br>
                <div class="deadline-form" id="update-user-form">
                    <form>
                        <!-- Password Fields -->
                        <div class="row g-3 mb-3 justify-content-center">
                            <div class="col-sm-6 p-5" style="box-shadow: 1px 1px 10px 1px #cbcbcb;border-radius: 5px;">
                                <div class="col-sm-12 text-center">
                                    <div class="profile-image-container mb-3">
                                        <img :src="avatarUrl" alt="Profile Image"
                                            class="img-thumbnail"
                                            style="width: 150px; height: 150px; object-fit: cover; border-radius: 50%;">
                                    </div>
                                    <input type="file" @change="onFileChange" accept="image/*"
                                        class="form-control mb-4">
                                </div>
                                <label for="userOldPassword" class="form-label">Παλιός Κωδικός</label>
                                <input type="password" class="form-control mb-4" id="userOldPassword"
                                    v-model="oldPassword" placeholder="Κωδικός">
                                <label for="userEditPassword" class="form-label">Νέος Κωδικός</label>
                                <input type="password" class="form-control mb-4" id="userEditPassword"
                                    v-model="newPassword" placeholder="Κωδικός">
                                <label for="userEditPasswordConfirm" class="form-label">Επιβεβαίωση νέου κωδικού</label>
                                <input type="password" class="form-control" id="userEditPasswordConfirm"
                                    v-model="newPasswordConfirm" placeholder="Επιβεβαίωση νέου κωδικού">
                                <div class="d-flex justify-content-center pt-4">
                                    <button type="button" class="btn btn-secondary updateUser"
                                        @click="submitForm">Ενημέρωση προφίλ</button>
                                </div>
                            </div>
                        </div>
                        <div id="update-user-errors"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { changeProfile } from '@/services/change-profile';
import { fetchUserInfo } from '@/services/user';

export default {
    name: 'ChangeProfile',
    setup() {
        const toast = useToast();
        const oldPassword = ref('');
        const newPassword = ref('');
        const newPasswordConfirm = ref('');
        const avatar = ref(null); // Stores the file for submission
        const avatarUrl = ref('/img/profile_av.7b0dcddf.png'); // URL for preview

        const onFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                avatar.value = file; // Directly assign the file object
                const reader = new FileReader();
                reader.onload = (e) => {
                    avatarUrl.value = e.target.result; // Set preview URL
                };
                reader.readAsDataURL(file);
            }
        };

        const submitForm = async () => {
            try {
                // Create FormData and append fields
                const formData = new FormData();
                formData.append('old_password', oldPassword.value);
                formData.append('password', newPassword.value);
                formData.append('password_confirmation', newPasswordConfirm.value);
                
                if (avatar.value) {
                    formData.append('avatar_file', avatar.value); // Add file if it exists
                }

                console.log(avatar.value)


                const response = await changeProfile(formData); // Send formData directly

                if (response.data.success) {
                    toast.success(response.data.message);
                    const r = await fetchUserInfo();
                    if (r.data.info && r.data.info.avatar_file_name) {
                        avatarUrl.value = '/storage/avatars/' + r.data.info.avatar_file_name;
                    }
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
            }
        };

        onMounted(async () => {
            const r = await fetchUserInfo();
            if (r.data.info && r.data.info.avatar_file_name) {
                avatarUrl.value = '/storage/avatars/' + r.data.info.avatar_file_name;
            }
        });

        return { oldPassword, newPassword, newPasswordConfirm, submitForm, avatar, avatarUrl, onFileChange };
    },
    metaInfo: {
        title: 'Χρήστες',
        meta: [
            { name: 'description', content: 'MIS - Groups' },
            { property: 'og:title', content: 'MIS - Groups' },
            { property: 'og:description', content: 'This is the groups page.' }
        ]
    }
};
</script>

<style scoped>
.profile-image-container {
    display: flex;
    justify-content: center;
}
</style>
