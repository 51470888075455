import apiClient from './api';

async function starNotificationById(notificationId) {
  try {
    const response = await apiClient.post('/star-notification', { id: notificationId });
    return response;
  } catch (error) {
    console.error('Error starring notification:', error);
    throw error;
  }
}

async function deleteNotificationById(notificationId) {
  try {
    const response = await apiClient.post('/delete-notification', { id: notificationId });
    return response;
  } catch (error) {
    console.error('Error starring notification:', error);
    throw error;
  }
}


async function markNotificationReadById(notificationId) {
  try {
    const response = await apiClient.post('/read-notification', { id: notificationId });
    return response;
  } catch (error) {
    console.error('Error starring notification:', error);
    throw error;
  }
}

async function markNotificationUnreadById(notificationId) {
  try {
    const response = await apiClient.post('/unread-notification', { id: notificationId });
    return response;
  } catch (error) {
    console.error('Error starring notification:', error);
    throw error;
  }
}

async function unstarNotificationById(notificationId) {
  try {
    const response = await apiClient.post('/unstar-notification', { id: notificationId });
    return response;
  } catch (error) {
    console.error('Error starring notification:', error);
    throw error;
  }
}

async function fetchAll(data) {
  try {
    const response = await apiClient.get('get-all-notifications', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function get(data) {
  try {
    const response = await apiClient.get('/get-project', { params: data || {} }); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function marked(ids) {
    try {
        const response = await apiClient.post('/mark-all-notifications-read', {
            ids: ids        
        });
        return response;
    } catch (error) {
        console.error('Error marking as read:', error);
        throw error;
    }
}


async function fetchManagers() {
  try {
    const response = await apiClient.get('/all-users'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function changeManager(managerId) {
  try {
    const response = await apiClient.get('/dashboard/get-follow-up', {
      managerId: managerId
    });
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export { fetchAll, get, starNotificationById, unstarNotificationById, fetchManagers, changeManager, marked, markNotificationReadById, markNotificationUnreadById, deleteNotificationById };
