import apiClient from "./api";

export async function login(username, password, fingerprint) {
  try {
    const response = await apiClient.post("/login", {
      username: username,
      password: password,
      b_id: fingerprint,
      remember_me: 0
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export async function register(userData) {
  try {
    const response = await apiClient.post("/register", userData);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}
  export async function sendVerificationToken(email) {
    try {
      const response = await apiClient.post("/send-verification-token", {email:email});
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }

    
}


export async function createNewPassword(password, password_confirmation) {
  try {
    const response = await apiClient.post("/change-password", {
      password: password,
      password_confirmation: password_confirmation,
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}