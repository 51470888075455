<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        <div
                            class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                            <div class="header-section">
                                <div class="search-area mt-2">
                                    <input id="main-search-input" type="search" class="form-control"
                                        placeholder="Αναζήτηση..." aria-label="search"
                                        aria-describedby="addon-wrapping" />
                                </div>
                            </div>
                            <div class="d-flex mt-sm-0 mt-3 justify-content-between">
                                <button type="button" class="ms-0 ms-sm-3 btn btn-dark" style="color:var(--secondary-color); font-weight:bold"
                                    @click.prevent="createClient"  v-if="canCreateClient || superUser"><i class="icofont-plus me-2 fs-6" ></i>New
                                    client</button>
                                    <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0" v-if="canArchive || superUser">
                                    <button class="ms-0 ms-sm-3 btn btn-primary dropdown-menu-toggler" type="button" style="color:var(--secondary-color); font-weight:bold">
                                        Ενέργειες
                                    </button>
                                    <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': isDropdownOpen}" :style="dropdownStyle">
                                        <li><a class="dropdown-item py-2 rounded archiveSelected" href="#"
                                                @click.prevent="archiveClients">Αρχειοθέτηση
                                                επιλεγμένων</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix g-3">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div class="card-body table-responsive">
                            <table id="clients" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style="width:20%;"><div>Client<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('clients.title')"></i></div></th>
                                        <th style="width:15%;"><div>Total Projects</div></th>
                                        <th style="width:15%;"><div>On Progress</div></th>
                                        <th style="width:15%;"><div>On Hold</div></th>
                                        <th style="width:15%;"><div>Done</div></th>
                                        <th style="width:15%;"><div>Archived</div></th>
                                        <th class="no-after" style="width:5%; background-color:white; border:none"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(clientData, index) in data" :key="clientData.id" :class="{ 'selected': selectedClientIds.includes(clientData.id) }" @click="toggleClientSelection(clientData.id, $event)">
                                        <td v-if="clientData?.avatar_file_name">
                                            <img :src="`/storage/clients_avatars/${clientData.avatar_file_name}`"
                                                style="width:10em">
                                        </td>
                                        <td v-else>
                                            
                                        </td>
                                        <td>
                                            {{ clientData.code }}
                                        </td>
                                        <td>
                                            {{ clientData.projectCount.total }}
                                        </td>
                                        <td>
                                            {{ clientData.projectCount.open }}
                                        </td>
                                        <td>
                                            {{ clientData.projectCount.onHold }}
                                        </td>
                                        <td>
                                            {{ clientData.projectCount.done }}
                                        </td>
                                        <td>
                                            {{ clientData.projectCount.archived }}
                                        </td>
                                        <td style="background-color:white">
                                            <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" class="btn btn-dark edit-client"
                                                    @click.prevent="editClient(clientData.id)" v-if="canEditClient(clientData) || canEditAllClients || superUser">
                                                    <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                    class="menu-img" style="width:1.5em"></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="col" v-if="!data || !data.length">
                                        <td>   
                                        </td>
                                        <td>
                                            No data
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks"
                                        :key="index" :class="{ active: paginationLink.active }">
                                        <router-link
                                            :to="{ path: 'clients', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                            v-if="paginationLink.url">
                                            <span class="page-link" v-html="paginationLink.label"></span>
                                        </router-link>
                                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>

        <!-- Modal for creating a new user -->
        <div class="modal fade" id="create-client-form" tabindex="-1" aria-labelledby="createprojectlLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold" id="createprojectlLabel">Στοιχεία πελάτη</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input name="id" id="id" type="hidden" v-model="clientData.id" />
                        <div class="mb-3">
                            <label for="title" class="form-label">Title</label>
                            <input type="title" class="form-control" id="title" v-model="clientData.title"
                                placeholder="Title">
                        </div>
                        <div class="mb-3">
                            <label for="nickname" class="form-label">Nickname</label>
                            <input type="nickname" class="form-control" id="nickname" v-model="clientData.nickname"
                                placeholder="Nickname">
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" v-model="clientData.email"
                                placeholder="Email">
                        </div>
                        <div class="mb-3">
                            <label for="address" class="form-label">Address</label>
                            <input type="address" class="form-control" id="address" v-model="clientData.address"
                                placeholder="Address">
                        </div>
                        <div class="profile-image-container mb-3">
                            <img :src="avatarUrl" alt="Profile Image"
                                class="img-thumbnail"
                                style="width: 150px; height: 150px; object-fit: cover; border-radius: 5px;">
                            <input type="file" @change="onFileChange" accept="image/*" class="form-control mb-4">
                        </div>
                        <div class="mb-3">
                            <label for="postal" class="form-label">Postal code</label>
                            <input type="postal" class="form-control" id="postal" v-model="clientData.postal"
                                placeholder="Postal">
                        </div>
                        <div class="mb-3">
                            <label for="occupation" class="form-label">Occupation</label>
                            <input type="occupation" class="form-control" id="occupation" v-model="clientData.occupation"
                                placeholder="Occupation">
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label">Phone</label>
                            <input type="phone" class="form-control" id="phone" v-model="clientData.phone"
                                placeholder="Phone">
                        </div>
                        <div class="mb-3">
                            <label for="taxInfo" class="form-label">Tax info</label>
                            <input type="taxInfo" class="form-control" id="taxInfo" v-model="clientData.taxInfo"
                                placeholder="Tax info">
                        </div>
                        <div class="mb-3">
                            <label for="foundUs" class="form-label">Found us</label>
                            <input type="foundUs" class="form-control" id="foundUs" v-model="clientData.foundUs"
                                placeholder="Found us">
                        </div>
                        <div class="mb-3">
                            <label for="potential" class="form-label">Potential</label>
                            <select id="potential" class="form-select" v-model="clientData.potential"
                                aria-label="Potential">
                                <option value="">Παρακαλώ επιλέξτε</option>
                                <option value="1">Ναι</option>
                                <option value="0">Όχι</option>
                            </select>
                        </div>

                        <!-- Conditionally show the Paid field based on the potential value -->
                        <div class="mb-3" v-if="clientData.potential == '' || clientData.potential == 0">
                            <label for="paid" class="form-label">Paid</label>
                            <select id="paid" class="form-select" v-model="clientData.paid" aria-label="Paid">
                                <option value="">Παρακαλώ επιλέξτε</option>
                                <option value="1">Ναι</option>
                                <option value="0">Όχι</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
<!--                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
 -->                        <button type="button" class="btn btn-secondary" @click="submitForm">Αποθήκευση</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveClients } from '@/services/clients';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { mapGetters } from 'vuex';

const defaultClientData = {
    id: '',
    title: '',
    nickname: '',
    email: '',
    phone: '',
    address: '',
    postal: '',
    occupation: '',
    taxInfo: '',
    potential: '',
    foundUs: '',
    notes: '',
    paid: ''
};

export default {
    name: 'Clients',
    methods: {
        
        canEditClient(client) {
            if (!client) { return false; }
            return localStorage.getItem('current_user_id') == client?.createdBy && this.userPermissions?.edit === 1;
        },
       
    },
    computed: {
    ...mapGetters({
        getUserPermission: 'getUserPermission',
        getSuperUserPermission: 'getSuperUserPermission', // Add this line

    }),
    
    
    superUser() {
        return this.getSuperUserPermission === 1;
    },
     userPermissions() {
        return this.getUserPermission('Clients');
    },
    
    canEditAllClients() {
        return this.userPermissions?.editAll === 1;
    },
    canCreateClient() {
        return this.userPermissions?.create === 1;
    },
    canArchive() {
        return this.userPermissions?.archive === 1;
    },
  },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();


        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

        const clientData = ref(defaultClientData);
        const selectedClientIds = ref([]);

        const isDropdownOpen = ref(false);

        const avatar = ref(null); // Stores the file for submission

        const avatarUrl = ref(''); // URL for preview

        const onFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                avatar.value = file; // Directly assign the file object
                const reader = new FileReader();
                reader.onload = (e) => {
                    avatarUrl.value = e.target.result; // Set preview URL
                };
                reader.readAsDataURL(file);
            }
        };

        const submitForm = async () => {
            try {
                // Create FormData and append all client data fields
                const formData = new FormData();
                Object.entries(clientData.value).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                // Append the avatar file if it exists
                if (avatar.value) {
                    formData.append('avatar_file', avatar.value);
                }

                console.log(formData.avatar_file)


                // Submit FormData
                const response = await createOrUpdate(formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data.success) {
                    $("#create-client-form").modal('toggle');
                    fetchData();
                    toast.success('The client has been updated');

                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Failed to submit form');
            }
        };


    
        const toggleClientSelection = (clientId) => {
    if (selectedClientIds.value.includes(clientId)) {
        selectedClientIds.value = selectedClientIds.value.filter(id => id !== clientId);
    } else {
        selectedClientIds.value.push(clientId);
    }
};


        const archiveClients = async () => {
            if (!selectedClientIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει πελάτες');
                return;
            }
            const response = await massArchiveClients({ ids: selectedClientIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const editClient = async (id) => {
            const clientInfo = await get({ "id": id });
            if (clientInfo.data.success) {
                clientData.value.id = clientInfo.data.data.id;
                clientData.value.title = clientInfo.data.data.title;
                clientData.value.nickname = clientInfo.data.data.nickname;
                clientData.value.email = clientInfo.data.data.email;
                clientData.value.phone = clientInfo.data.data.phone;
                clientData.value.address = clientInfo.data.data.address;
                clientData.value.postal = clientInfo.data.data.postal;
                clientData.value.occupation = clientInfo.data.data.occupation;
                clientData.value.taxInfo = clientInfo.data.data.taxInfo;
                clientData.value.potential = clientInfo.data.data.potential;
                clientData.value.foundUs = clientInfo.data.data.foundUs;
                clientData.value.notes = clientInfo.data.data.notes;
                clientData.value.paid = clientInfo.data.data.paid;
            }
            $("#create-client-form").modal('toggle');
        };

        const createClient = async () => {
            clientData.value = {
                id: '',
                title: '',
                nickname: '',
                email: '',
                phone: '',
                address: '',
                postal: '',
                occupation: '',
                taxInfo: '',
                potential: '',
                foundUs: '',
                notes: '',
                paid: ''
            };
            $("#create-client-form").modal('toggle');
        };

        

        const getPageFromUrl = (url) => {
    if (!url) return null;
    const params = new URLSearchParams(url.split('?')[1]);
    return params.get('page') || 1;
};


        const closeMenu = (event) => {
            if (document.querySelector('.dropdown-menu').length && !document.querySelector('.dropdown-menu').contains(event.target) && !document.querySelector('.dropdown-menu-toggler').contains(event.target)) {
                isDropdownOpen.value = false;
            }
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/clients', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        onMounted(() => {
            /* if (r.data.info && r.data.info.avatar_file_name) {
                avatarUrl.value = '/storage/clients_avatars/' + r.data.info.avatar_file_name;
            } */
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            document.addEventListener('click', closeMenu);
        });

        onUnmounted(() => {
            document.removeEventListener('click', closeDropdown);

        });

        // Watch the potential field and update paid accordingly
        watch(clientData, (newValue) => {
            if (newValue.potential === '1') {
                clientData.value.paid = '0'; // Set paid to "No" when potential is "Yes"
            } else if (newValue.potential === '0') {
                clientData.value.paid = '1'; // Set paid to "Yes" when potential is "No"
            }
        }, { deep: true });

        return {
            data,
            paginationLinks,
            filters,
            updateFilters,
            fetchData,
            getPageFromUrl,
            clientData,
            submitForm,
            editClient,
            createClient,
            selectedClientIds,
            toggleClientSelection,
            archiveClients,
            dropdownStyle,
            closeDropdown,
            isDropdownOpen,
            toggleDropdown,
            sortBy,
            avatar, avatarUrl, onFileChange
        };
    },
    metaInfo: {
        title: 'Πελάτες',
        meta: [
            { name: 'description', content: 'MIS - Clients' },
            { property: 'og:title', content: 'MIS - Clients' },
            { property: 'og:description', content: 'This is the clients page.' }
        ]
    }
};
</script>


<style scoped>
.dropdown-menu.show {
    display: block;
}
tr.selected, div.selected {
    background: #b1afaf;
}
</style>
