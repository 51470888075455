import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/get-public-holidays', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    console.error('Error fetching all holidays:', error);
    throw error;
  }
}

async function createOrUpdate(data) {
  try {
    const response = await apiClient.post('/create-update-public-holiday', data);
    return response;
  } catch (error) {
    console.error('Error creating holiday:', error);
    throw error;
  }
}

async function deletePublicHoliday(data) {
    try {
      const response = await apiClient.post('/delete-public-holiday', data);
      return response;
    } catch (error) {
      console.error('Error creating holiday:', error);
      throw error;
    }
  }



async function get(params) {
  try {
    const response = await apiClient.get('/get-servicegroup', { params });
    return response;
  } catch (error) {
    console.error('Error fetching holiday:', error);
    throw error;
  }
}

export { fetchAll, createOrUpdate, get, deletePublicHoliday };
