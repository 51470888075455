<template>
  <div class="body d-flex py-lg-3 py-md-2">
    <div class="container-xxl">
      <!-- Add Holiday Button -->
      <div class="row align-items-center">
        <div class="col">
          <div class="card-header py-1 no-bg bg-transparent d-flex align-items-center px-0 justify-content-end">
            <div class="col-auto d-flex">
              <button type="button" class="ms-0 ms-sm-3 btn btn-dark" style="color:var(--secondary-color); font-weight:bold"@click.prevent="createHoliday" v-if="canCreateHolidays || superUser">
                <i class="icofont-plus me-2 fs-6"></i>Add holiday
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Main Content Row -->
      <div class="row">
        <!-- Holiday Table Section -->
        <div class="col-lg-6 mb-3">
          <div class="card h-100">
            <div class="card-body table-responsive">
              <table class="table table-hover align-middle mb-0 mis_table">
                <thead>
                  <tr>
<!--                     <th style="width:6%;"><div>#</div></th>
 -->                    <th style="width:15%;"><div>Name</div></th>
                    <th style="width:4%;"><div>DATE</div></th>
                    <th style="width:6%; background-color:white; border:none" class="no-after"><div>Actions</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(holiday, index) in data" :key="holiday.id"
                      @mouseover="highlightEvent(holiday.id, true)"
                      @mouseleave="highlightEvent(holiday.id, false)"
                      :class="{ 'table-primary': holiday.id === selectedHolidayId }">
<!--                     <td>{{ holiday.id }}</td>
 -->                    <td>{{ holiday.title }}</td>
                    <td>{{ holiday.from_formatted }}</td>
                    <td>
                      <button type="button" class="btn btn-secondary deleterow" @click="deleteHoliday(holiday.id)" v-if="canEditHolidays || superUser">
                        <i class="icofont-ui-delete text-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation" v-if="data && data.length">
                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                  <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                    <router-link :to="{ path: 'holidays', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                      <span class="page-link" v-html="paginationLink.label"></span>
                    </router-link>
                    <span class="page-link" v-else v-html="paginationLink.label"></span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!-- Calendar Section -->
        <div class="col-lg-6 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <FullCalendar ref="fullCalendar" :options="calendarOptions" />
            </div>
          </div>
        </div>
      </div>
      <!-- Add Holiday Modal -->
      <div class="modal fade" id="create-holiday-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="createprojectLabel">Add Holiday</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input name="id" id="id" type="hidden" v-model="holidayData.id" />
              <div class="mb-3">
                <label for="title" class="form-label">Title</label>
                <input type="text" class="form-control" id="title" v-model="holidayData.title" placeholder="Title">
              </div>
              <div class="mb-3">
                <label for="from" class="form-label"> Date</label>
                <input type="date" class="form-control" id="from" v-model="holidayData.from" placeholder="Start Date">
              </div>
              <!-- <div class="mb-3">
                <label for="to" class="form-label">End Date</label>
                <input type="date" class="form-control" id="to" v-model="holidayData.to" placeholder="End Date">
              </div> -->
            </div>
            <div class="modal-footer">
<!--               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
 -->              <button type="button" class="btn btn-secondary" @click="submitForm">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, deletePublicHoliday } from '@/services/holidays'; // Update import path as needed
import { useToast } from 'vue-toastification';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import { mapGetters } from 'vuex';

const defaultHolidayData = {
  id: '',
  title: '',
  from: '',
  to: ''
};

const prepareHolidaysData = function(data) {
  const today = new Date().toISOString().slice(0, 10);
  return data.map(holiday => {
    const startDate = new Date(holiday.from);
    const endDate = new Date(holiday.to);
    endDate.setDate(endDate.getDate() + 1); // FullCalendar's end date is exclusive, so we add one day
    return {
      id: holiday.id,
      title: holiday.title,
      start: startDate.toISOString().slice(0, 10),
      end: endDate.toISOString().slice(0, 10),
      isToday: holiday.from === today
    };
  });
};

export default {
  name: 'Holidays',
  components: {
    FullCalendar
  },
  computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Holidays');
        },
        canEditHolidays() {
            return this.userPermissions?.editCreator === 1;
        },
        canEditAllHolidays() {
            return this.userPermissions?.editAll === 1;
        },
        canViewAllHolidays() {
            return this.userPermissions?.viewAdmin === 1;
        },
        canCreateHolidays() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
    },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { data, paginationLinks, filters, updateFilters, fetchData } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

    const holidayData = ref(defaultHolidayData);
    const calendarEvents = ref([]);
    const selectedHolidayId = ref(null);

    const createHoliday = () => {
      holidayData.value = { ...defaultHolidayData };
      $('#create-holiday-form').modal('toggle');
    };

    const deleteHoliday = async (id) => {
      const response = await deletePublicHoliday({ id });
      if (response.data.success) {
        toast.success(response.data.message);
        fetchData();
        fetchHolidays();
      } else {
        toast.error(response.data.message);
      }
    };

    const submitForm = async () => {
      try {
        const response = await createOrUpdate(holidayData.value);
        if (response.data.success) {
          toast.success(response.data.message);
          $('#create-holiday-form').modal('toggle');
          fetchData();
          fetchHolidays();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('Error submitting form');
      }
    };

    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    const fetchHolidays = async () => {
      try {
        const response = await fetchAll();
        if (response.data.success) {
          calendarEvents.value = prepareHolidaysData(response.data.data.data);
        }
      } catch (error) {
        console.error('Error fetching holidays:', error);
      }
    };

    const calendarOptions = computed(() => ({
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',
      events: calendarEvents.value,
      eventContent: function(arg) {
        let eventHtml = `<div class="fc-event-title" title="${arg.event.title}">${arg.event.title}</div>`;
        if (arg.event.id === selectedHolidayId.value) {
          eventHtml = `<div class="fc-event-title fc-event-highlight" title="${arg.event.title}">${arg.event.title}</div>`;
        }
        return {
          html: eventHtml
        };
      }
    }));

    const highlightEvent = (id, isHovered) => {
      if (isHovered) {
        selectedHolidayId.value = id;
      } else {
        selectedHolidayId.value = null;
      }
    };

    const searchAction = () => {
      setTimeout(() => {
        router.push({ path: '/holidays', query: { ...route.query, search: $("#main-search-input").val() } });
      }, 300);
    };

    // Fetch data on component mount
    onMounted(async () => {
      $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
      await fetchData(); // Fetch data for the table
      await fetchHolidays(); // Fetch data for the calendar
    });

    onUnmounted(() => {
      $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
    });

    return {
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      getPageFromUrl,
      holidayData,
      submitForm,
      createHoliday,
      deleteHoliday,
      calendarOptions,
      highlightEvent,
      selectedHolidayId
    };
  },
  metaInfo: {
    title: 'Holidays',
    meta: [
      { name: 'description', content: 'MIS - Holidays' },
      { property: 'og:title', content: 'MIS - Holidays' },
      { property: 'og:description', content: 'This is the holidays page.' }
    ]
  }
};
</script>

<style scoped>
.dropdown-menu.show {
  display: block;
}

.table-primary {
  background-color: #f0f0f0; /* Adjust to your preference */
}

.fc-event-highlight {
  background-color: #f0f0f0; /* Adjust to your preference */
  border: 1px solid #ccc; /* Adjust to your preference */
  padding: 2px 4px; /* Adjust to your preference */
}
</style>
