<template>
    <div class="h-100 reset-password-wrapper d-flex">
      <!-- Left Section: Logo and App Title -->
      <div class="p-3 mb-xl-0 left-section d-flex flex-column justify-content-center align-items-center text-center">
        <img src="../assets/images/logo.png" class="logo-img" style="width:30em" alt="Citrine Logo" >

      </div>
  
      <!-- Right Section: Forms -->
      <div class="right-section d-flex flex-column justify-content-center align-items-center">
        <div class="form-container">
          <!-- Step 1 -->
          <form v-if="!showStep2" @submit.prevent="sendVerificationToken" class="row g-1 p-3 p-md-2">
            <div class="col-12 text-center mb-1 mb-lg-5">
              <h3 class="fs-1 text-center fw-bold forgotPass">ΞΕΧΑΣΑΤΕ ΤΟΝ ΚΩΔΙΚΟ ΣΑΣ;</h3>

              <span>Εισαγάγετε τη διεύθυνση email που χρησιμοποιήσατε κατά την εγγραφή σας και θα σας στείλουμε οδηγίες για να επαναφέρετε τον κωδικό πρόσβασής σας.</span>
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Email address</label>
              <input type="email" class="form-control form-control-md" v-model="email" placeholder="name@example.com">
            </div>
            <div class="col-12 text-center mt-4">
              <button type="submit" class="btn btn-lg btn-block  lift text-uppercase btn-submit" style="background-color: #828282; color:white; font-weight: bolder;" :disabled="loading">
                Αποστολη
              </button><br>
              <div class="col-12 text-center mt-4">
            <div class="button-group d-flex align-items-center justify-content-center">
              <a href="/" class="btn mt-3 fs-5" style="color: grey;">Σύνδεση</a>
              <!-- <button @click="goToLogin" class="btn mt-3 fs-5" style="color: grey;">Σύνδεση</button> -->
            
            </div>
          </div>

            </div>
            <div v-if="errorMessage" class="error-div text-center mt-3">
              <p>{{ errorMessage }}</p>
            </div>
            <div v-if="successMessage" class="success-message text-center mt-3">
              <p>{{ successMessage }}</p>
            </div>
          </form>
  
          <!-- Step 2 -->
          <form v-if="showStep2" @submit.prevent="completePasswordChange" class="row g-1 p-3 p-md-2">
            <div class="col-12 text-center mb-1 mb-lg-5">
              <h1>Αλλαγή Κωδικού πρόσβασης</h1>
              <span>Εισάγετε τον νέο σας κωδικό.</span>
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Νέος κωδικός</label>
              <input type="password" class="form-control form-control-md" v-model="password" placeholder="********">
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Επιβεβαίωση νέου κωδικού</label>
              <input type="password" class="form-control form-control-md" v-model="passwordConfirmation" placeholder="********">
            </div>
            <div class="col-12 text-center mt-4">
              <button type="submit" class="btnpx-4 py-2 btn-submit" style="background-color: #828282; color:white; font-weight: bolder;" :disabled="loading">
                Αλλαγή
              </button><br>
              <div class="col-12 text-center mt-4">
  <div class="button-group d-flex align-items-center justify-content-center">
    <button @click="goToLogin" class="btn mt-3 fs-5" style="color: grey;">Σύνδεση</button>
  </div>
</div>
            </div>
            <div v-if="errorMessage" class="error-div text-center mt-3">
              <p>{{ errorMessage }}</p>
            </div>
            <div v-if="successMessage" class="success-message text-center mt-3">
              <p>{{ successMessage }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>

<script>
import { ref, onMounted } from 'vue';
import apiClient from '../services/api';

export default {
    name: 'ResetPassword',
    setup() {
        const email = ref('');
        const password = ref('');
        const passwordConfirmation = ref('');
        const token = ref('');
        const loading = ref(false);
        const errorMessage = ref('');
        const successMessage = ref('');
        const showStep2 = ref(false);

        onMounted(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const emailParam = urlParams.get('email');
            const tokenParam = urlParams.get('token');
            
            if (emailParam && tokenParam) {
                email.value = emailParam;
                token.value = tokenParam;
                showStep2.value = true;
            }
        });

        const sendVerificationToken = async () => {
            loading.value = true;
            errorMessage.value = '';
            successMessage.value = '';
            try {
                const response = await apiClient.post('send-verification-token', {
                    email: email.value,
                });
                if (response.data.success) {
                    successMessage.value = response.data.message;
                } else {
                    errorMessage.value = response.data.message;
                }
            } catch (error) {
                errorMessage.value = 'An error occurred while sending the verification token.';
            } finally {
                loading.value = false;
            }
        };

        const completePasswordChange = async () => {
            loading.value = true;
            errorMessage.value = '';
            successMessage.value = '';
            try {
                const response = await apiClient.post('/complete-password-change', {
                    email: email.value,
                    token: token.value,
                    password: password.value,
                    password_confirmation: passwordConfirmation.value,
                });
                if (response.data.success) {
                    successMessage.value = response.data.message;
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);
                } else {
                    errorMessage.value = response.data.message;
                }
            } catch (error) {
                errorMessage.value = 'An error occurred while changing the password.';
            } finally {
                loading.value = false;
            }
        };

        return {
            email,
            password,
            passwordConfirmation,
            loading,
            errorMessage,
            successMessage,
            showStep2,
            sendVerificationToken,
            completePasswordChange,
        };
    },
};
</script>
<style scoped>
.button-group {
display: flex;
align-items: center; /* Vertically centers items within the flex container */
justify-content: center; /* Centers items horizontally within the flex container */
}

.button-group span {
margin: 0 10px; /* Adjust spacing around the separator */
font-size: 1.2rem; /* Adjust font size to match buttons if needed */
}

/* Main Wrapper to hold the left and right sections */
.reset-password-wrapper {
  width: 100vw; /* Full viewport width */
  
  height: 100vh !important;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.Loginlogo{
  height: 200px;
}
/* Left Section: App info, occupies half the screen */
.left-section {
  background-color: rgb(240, 240, 240);
  width: 50%; /* Takes up half the screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Right Section: Forms, occupies the other half */
.right-section {
  background-color: white;
  width: 50%; /* Takes up half the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* The form container */
.form-container {
  width: 100%;
  max-width: 450px;
}

/* Input fields styling */
.form-control-md {
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Error and success message styling */
.error-div {
  color: red;
  text-align: center;
}
.loginTitle{
  text-shadow: 2px 0px;
}

.forgotPass{
  color:#5C5C5C;
  font-size:20em;
  font-weight: bolder;
}
.success-message {
  color: rgb(64, 168, 64);
  text-align: center;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .reset-password-wrapper {
    flex-direction: column;
    height: unset !important;
    justify-content: start;
  }
  .Loginlogo{
    height: 160px;
  }
  .left-section,
  .right-section {
    width: 100%; /* Stack the sections vertically on smaller screens */
  }
}
</style>
