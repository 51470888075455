<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                            <div class="search-area mt-2">
                                        <input id="main-search-input" type="search" class="form-control"
                                            placeholder="Search User" aria-label="search"
                                            aria-describedby="addon-wrapping" />
                                    </div>
                            <ul class="nav nav-tabs tab-body-header rounded mt-sm-0 mt-3 ms-0 ms-sm-3 prtab-set w-sm-100" role="tablist">
                                <li class="nav-item col">
                                    <a :class="{ 'nav-link text-center active': filters.active == 1, 'nav-link text-center': filters.active == 0 }"
                                        @click.prevent="() => updateFilters({ active: 1, page: 1 })" href="#" role="tab">Ενεργός/-ή</a>
                                </li>
                                <li class="nav-item col">
                                    <a :class="{ 'nav-link text-center active': filters.active == 0, 'nav-link text-center': filters.active == 1 }"
                                        @click.prevent="() => updateFilters({ active: 0, page: 1 })" href="#" role="tab">Μη Ενεργός/-ή</a>
                                </li>
                            </ul>

                            <div class="d-flex mt-sm-0 mt-3 justify-content-between">
                                <button type="button" class="ms-0 ms-sm-3 btn btn-dark" style="color:var(--secondary-color); font-weight:bold" @click.prevent="createUser" v-if="canCreateUser">
                                    <i class="icofont-plus me-2 fs-6"></i>New user
                                </button>
                                <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0" v-if="canArchive">
                                    <button class="ms-0 ms-sm-3 btn btn-primary dropdown-menu-toggler"  style="color:var(--secondary-color); font-weight:bold" type="button">
                                        Ενέργειες
                                    </button>
                                    <ul class="dropdown-menu border-0 shadow p-3" :class="{'show': isDropdownOpen}" :style="dropdownStyle">
                                        <li>
                                            <a class="dropdown-item py-2 rounded archiveSelected" href="#" @click.prevent="archiveUsers">
                                                Αρχειοθέτηση επιλεγμένων
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ms-3 d-flex align-items-center">
                                        <img v-if="isGridView" src="../assets/images/list.png" alt="List" title="List"
                                            class="menu-img" style="width:2.5em; cursor: pointer;"
                                            @click="isGridView = false">
                                        <div v-if="!isGridView" style="background-color: #AFAFAF">
                                            <img src="../assets/images/list2.png" alt="List" title="List"
                                                class="menu-img" style="width:2.5em; cursor: pointer;"
                                                @click="isGridView = false">
                                        </div>
                                        <img v-if="!isGridView" src="../assets/images/grid.png" alt="Grid" title="Grid"
                                            class="menu-img" style="width:2.5em; cursor: pointer;"
                                            @click="isGridView = true">
                                        <div v-if="isGridView" style="background-color: #AFAFAF">
                                            <img src="../assets/images/grid2.png" alt="Grid" title="Grid"
                                                class="menu-img" style="width:2.5em; cursor: pointer;"
                                                @click="isGridView = true">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isGridView" class="card-body table-responsive">
                                <table id="projectTable" class="table table-hover align-middle mb-0 mis_table"
                                    style="width:100%">
                                    <thead>
                                        <tr>
                                            <th style="width:10%;">
                                                <div>ID<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('id')"></i></div>
                                            </th>
                                            <th style="width:20%;">
                                                <div>User<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('username')"></i></div>
                                            </th>
                                            <th style="width:20%;">
                                                <div>Email<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('email')"></i></div>
                                            </th>
                                            <th style="width:20%;">
                                                <div>Phone<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('tel')"></i></div>
                                            </th>
                                            <th style="width:15%;">
                                                <div>Projects assigned</div>
                                            </th>
                                            <th class="no-after"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, index) in data" :key="user.info.id" @click="toggleUsersSelection(user.info.id, $event)" :class="{ 'selected': selectedUserIds.includes(user.info.id) }">
                                            <td >
                                                {{ user.info.id }}
                                            </td>
                                            <td >
                                                {{ user.info.username }}
                                            </td>

                                            

                                            <td >
                                                {{ user.info.email }}
                                            </td>  
                                            <td >
                                                {{ user.info.tel }}
                                            </td>  
                                            <td >
                                                {{user.projectCount?.total || 0 }}
                                            </td>                                          
                                            <td>
                                                 <div class="button-group">
                                                    
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark"
                                                        @click="editUser(user.info.id)" v-if="superUser || canEditAllUsers || (canEditUser && user.info.id === getUser)">
                                                            <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                                class="menu-img" style="width:1.5em">
                                                        </button>
                                                    </div>
                                                    
                                                </div>  
                                            </td>

                                        </tr>
                                        <tr class="col" v-if="!data || !data.length">
                                            <td>No data</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
            <div v-if="isGridView"  class="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-4 row-deck py-1 pb-4" style="position: relative; z-index: 0">
                <div class="col-6" v-for="(user, index) in data" :key="user.info.id">
                    <div class="card teacher-card" :class="{ 'shadow': selectedUserIds.includes(user.info.id) }">
                        <div class="card-body position-relative" style="border-radius: 15px; background: #eae8e8 !important; color: black;">
                            <div class="d-flex align-items-center">
                                <div class="checkbox-wrapper">
                                    <input type="checkbox" @change="toggleUserSelection(user.info.id, $event)">
                                </div>
                                <div class="profile-av text-center ms-3">
                                    <img src="../assets/images/lg/avatar3.jpg" alt="" class="avatar xl rounded-circle img-thumbnail shadow-sm">
                                </div>
                                <div class="ms-3 user-info">
                                    <span class="rounded-1 d-inline-block small-14 mb-0 mt-1">Contact ID: {{ user.info.id }}</span>
                                    <h5 class="mb-0 mt-2 fw-bold d-block fs-6">{{ user.info.username }}</h5>
                                </div>
                                <button type="button" style="padding: 4px 6px; border-color: black" class="btn btn-light ms-auto mt-1 w-sm-100 show-edit-user-modal" @click="editUser(user.info.id)" v-if="superUser || canEditAllUsers || (canEditUser && user.info.id === getUser)">
                                    <i class="icofont-pencil fs-5"></i>
                                </button>
                            </div>
                            <div class="userInfoText mt-3">
                                <div class="d-flex align-items-center mb-2">
                                    <i class="icofont-email fs-4 text-secondary"></i>
                                    <span class="ms-2">{{ user.info.email || 0 }}</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center" style="width: 24px; height: 24px;">
                                        {{ user.projectCount?.total || 0 }}
                                    </div>
                                    <span class="ms-2">Projects Assigned</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" v-if="!data || !data.length">
                <p>Δεν βρεθηκαν αποτελέσματα...</p>
            </div>
        </div>
        <nav aria-label="Page navigation">
            <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                    <router-link :to="{ path: 'users', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                        <span class="page-link" v-html="paginationLink.label"></span>
                    </router-link>
                    <span class="page-link" v-else v-html="paginationLink.label"></span>
                </li>
            </ul>
        </nav>

        <!-- Modal for creating a new user -->
        <div class="modal fade" id="create-user-form" tabindex="-1" aria-labelledby="createprojectlLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold" id="createprojectlLabel">Στοιχεία χρήστη</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input name="id" id="id" type="hidden" v-model="userData.id" />
                        <div class="mb-3">
                            <label for="userEmail" class="form-label">Email Χρήστη</label>
                            <input type="email" class="form-control" id="userEmail" v-model="userData.email"
                                placeholder="Email">
                        </div>
                        <div class="mb-3">
                            <label for="userUsername" class="form-label">Όνομα χρήστη</label>
                            <input type="text" class="form-control" id="userUsername" v-model="userData.username"
                                placeholder="Username">
                        </div>
                        <div class="deadline-form">
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6">
                                    <label for="userPassword" class="form-label">Κωδικός</label>
                                    <input type="password" class="form-control" id="userPassword"
                                        v-model="userData.password" placeholder="Κωδικός">
                                </div>
                                <div class="col-sm-6">
                                    <label for="userPasswordConfirm" class="form-label">Επιβεβαίωση κωδικού</label>
                                    <input type="password" class="form-control" id="userPasswordConfirm"
                                        v-model="userData.passwordConfirm" placeholder="Επιβεβαίωση κωδικός">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="userHireDate" class="form-label">Ημερομηνία πρόσληψης</label>
                                    <input type="date" class="form-control" id="userHireDate"
                                        v-model="userData.hireDate" placeholder="Ημερομηνία πρόσληψης">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label class="form-label">Ενεργοποίηση χρήστη</label>
                                    <select id="userActive" class="form-select" v-model="userData.active"
                                        aria-label="Ενεργοποίηση χρήστη">
                                        <option value="0">Όχι</option>
                                        <option value="1">Ναι</option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="userName" class="form-label">Όνομα</label>
                                    <input type="text" class="form-control" id="userName" v-model="userData.name"
                                        placeholder="Όνομα">
                                </div>
                                <div class="col-sm-6">
                                    <label for="userSurname" class="form-label">Επώνυμο</label>
                                    <input type="text" class="form-control" id="userSurname" v-model="userData.surname"
                                        placeholder="Επώνυμο">
                                </div>
                                <div class="col">
                                    <label for="userFatherFullName" class="form-label">Ονοματεπώνυμο Πατέρα</label>
                                    <input type="text" class="form-control" id="userFatherFullName"
                                        v-model="userData.fatherFullName" placeholder="Ονοματεπώνυμο Πατέρα">
                                </div>
                                <div class="col">
                                    <label for="userMotherFullName" class="form-label">Ονοματεπώνυμο Μητέρας</label>
                                    <input type="text" class="form-control" id="userMotherFullName"
                                        v-model="userData.motherFullName" placeholder="Ονοματεπώνυμο Μητέρας">
                                </div>
                                <div class="col">
                                    <label for="userSpouseFullName" class="form-label">Ονοματεπώνυμο συζύγου</label>
                                    <input type="text" class="form-control" id="userSpouseFullName"
                                        v-model="userData.spouseFullName" placeholder="Ονοματεπώνυμο συζύγου">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label class="form-label">ΕΙΔΟΣ ΤΑΥΤΟΤΗΤΑΣ</label>
                                    <select class="form-select" id="userIdentityType" v-model="userData.identityType"
                                        aria-label="ΕΙΔΟΣ ΤΑΥΤΟΤΗΤΑΣ">
                                        <option selected="" value="Αστυνομική Ταυτότητα">Αστυνομική Ταυτότητα</option>
                                        <option value="Διαβατήριο">Διαβατήριο</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label for="userIdentityNum" class="form-label">Αριθμός Ταυτότητας</label>
                                    <input type="text" class="form-control" id="userIdentityNum"
                                        v-model="userData.identityNum" placeholder="Αριθμός Ταυτότητας">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6">
                                    <label for="userIdentityCreationDate" class="form-label">Ημερ.Έκδοσης
                                        Ταυτότητας</label>
                                    <input type="date" class="form-control" id="userIdentityCreationDate"
                                        v-model="userData.identityCreationDate">
                                </div>
                                <div class="col">
                                    <label for="userIdentityCreationDepartment" class="form-label">Έκδουσα Αρχή</label>
                                    <input type="text" class="form-control" id="userIdentityCreationDepartment"
                                        v-model="userData.identityCreationDepartment" placeholder="Έκδουσα Αρχή">
                                </div>
                                <div class="col-sm-6">
                                    <label for="userAfm" class="form-label">ΑΦΜ</label>
                                    <input type="number" class="form-control" id="userAfm" v-model="userData.afm"
                                        placeholder="ΑΦΜ">
                                </div>
                                <div class="col-sm-6">
                                    <label for="userDoy" class="form-label">ΔΟΥ</label>
                                    <input type="text" class="form-control" id="userDoy" v-model="userData.doy"
                                        placeholder="ΔΟΥ">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="userAmIka" class="form-label">ΑΜ ΙΚΑ</label>
                                    <input type="text" class="form-control" id="userAmIka" v-model="userData.amIka"
                                        placeholder="Α.Μ. ΙΚΑ">
                                </div>
                                <div class="col">
                                    <label for="userAmka" class="form-label">ΑΜΚΑ</label>
                                    <input type="number" class="form-control" id="userAmka" v-model="userData.amka"
                                        placeholder="ΑΜΚΑ">
                                </div>
                                <div class="col">
                                    <label for="userAmMiscDepartment" class="form-label">ΑΜ ΑΛΛΟΥ ΑΣΦΑΛΙΣΤΙΚΟΥ
                                        ΤΑΜΕΙΟΥ</label>
                                    <input type="text" class="form-control" id="userAmMiscDepartment"
                                        v-model="userData.amMiscDepartment" placeholder="ΑΜ ΑΛΛΟΥ ΑΣΦΑΛΙΣΤΙΚΟΥ ΤΑΜΕΙΟΥ">
                                </div>
                                <div class="col">
                                    <label for="userTaxEmail" class="form-label">User Tax Email</label>
                                    <input type="text" class="form-control" id="userTaxEmail"
                                        v-model="userData.taxEmail" placeholder="User Tax Email">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6">
                                    <label for="userBirthday" class="form-label">Ημερ.Γεννήσεως</label>
                                    <input type="date" class="form-control" id="userBirthday"
                                        v-model="userData.birthday">
                                </div>
                                <div class="col">
                                    <label for="userBirthplace" class="form-label">Τόπος Γεννήσεως</label>
                                    <input type="text" class="form-control" id="userBirthplace"
                                        v-model="userData.birthplace" placeholder="Τόπος Γεννήσεως">
                                </div>
                                <div class="col">
                                    <label for="userNationality" class="form-label">Ιθαγένεια</label>
                                    <input type="text" class="form-control" id="userNationality"
                                        v-model="userData.nationality" placeholder="Ιθαγένεια">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6">
                                    <label for="userAddress" class="form-label">Διεύθ. κατοικίας</label>
                                    <input type="text" class="form-control" id="userAddress" v-model="userData.address"
                                        placeholder="Διεύθ. κατοικίας">
                                </div>
                                <div class="col">
                                    <label for="userArea" class="form-label">Περιοχή</label>
                                    <input type="text" class="form-control" id="userArea" v-model="userData.area"
                                        placeholder="Περιοχή">
                                </div>
                                <div class="col">
                                    <label for="userPostal" class="form-label">ΤΚ</label>
                                    <input type="text" class="form-control" id="userPostal" v-model="userData.postal"
                                        placeholder="ΤΚ">
                                </div>
                            </div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6">
                                    <label for="userMunicipality" class="form-label">Δημοτικό Διαμέρισμα</label>
                                    <input type="text" class="form-control" id="userMunicipality"
                                        v-model="userData.municipality" placeholder="Δημοτικό Διαμέρισμα">
                                </div>
                                <div class="col">
                                    <label for="userTel" class="form-label">Τηλέφωνο</label>
                                    <input type="tel" class="form-control" v-model="userData.tel"
                                        placeholder="Τηλέφωνο">
                                </div>
                                <div class="col">
                                    <label for="userMaritalStatus" class="form-label">Οικογενειακή κατάσταση</label>
                                    <input type="text" class="form-control" id="userMaritalStatus"
                                        v-model="userData.maritalStatus" placeholder="Οικογενειακή κατάσταση">
                                </div>
                            </div>
                            <div class="table-responsive permission-applied" v-if="superUser || canEditAllUsers">
                                <input type="checkbox" class="form-check-input check-uncheck-all"
                                    @change="massCheckUncheckAll($event, 'all')">&nbsp; Check/uncheck all permissions
                                <table class="table table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th>Entity</th>
                                            <th class="text-center">View&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-view-permissions"
                                                    @change="massCheckUncheckAll($event, 'view')"></th>
                                            <th class="text-center">View All&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-view-all-permissions"
                                                    @change="massCheckUncheckAll($event, 'viewAdmin')"></th>
                                            <th class="text-center">Create&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-create-permissions"
                                                    @change="massCheckUncheckAll($event, 'create')"></th>
                                            <th class="text-center">Edit&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-edit-permissions"
                                                    @change="massCheckUncheckAll($event, 'edit')"></th>
                                            <th class="text-center">Edit All&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-edit-all-permissions"
                                                    @change="massCheckUncheckAll($event, 'editAll')"></th>
                                            <th class="text-center">Archive&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-archive-permissions"
                                                    @change="massCheckUncheckAll($event, 'archive')"></th>
                                            <th class="text-center">Accept&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-accept-permissions"
                                                    @change="massCheckUncheckAll($event, 'accept')"></th>
                                            <th class="text-center">Edit creator&nbsp;<input type="checkbox"
                                                    class="form-check-input check-uncheck-all-edit-creator-permissions"
                                                    @change="massCheckUncheckAll($event, 'editCreator')"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(permission, index) in userData.permissions" :key="index">
                                            <td class="firsttd fw-bold">
                                                <input type="checkbox"
                                                    class="form-check-input check-uncheck-all-row-inputs"
                                                    @change="massCheckUncheckRow($event, index)">&nbsp;
                                                {{ permission.entityTitle }}
                                            </td>
                                            <input type="hidden"
                                                :name="'permissions[' + permission.entityId + '][userId]'"
                                                :value="permission.userId" />
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox" data-bs-toggle="tooltip"
                                                    data-permission-name="view"
                                                    :name="'permissions[' + permission.entityId + '][view]'"
                                                    :checked="permission.view === 1"
                                                    @change="updatePermission(index, 'view', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][viewAdmin]'"
                                                    :checked="permission.viewAdmin === 1"
                                                    data-permission-name="viewAdmin"
                                                    @change="updatePermission(index, 'viewAdmin', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][create]'"
                                                    :checked="permission.create === 1" data-permission-name="create"
                                                    @change="updatePermission(index, 'create', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][edit]'"
                                                    :checked="permission.edit === 1" data-permission-name="edit"
                                                    @change="updatePermission(index, 'edit', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][editAll]'"
                                                    :checked="permission.editAll === 1" data-permission-name="editAll"
                                                    @change="updatePermission(index, 'editAll', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][archive]'"
                                                    :checked="permission.archive === 1" data-permission-name="archive"
                                                    @change="updatePermission(index, 'archive', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][accept]'"
                                                    :checked="permission.accept === 1" data-permission-name="accept"
                                                    @change="updatePermission(index, 'accept', $event)">
                                            </td>
                                            <td class="text-center">
                                                <input class="form-check-input" type="checkbox"
                                                    :name="'permissions[' + permission.entityId + '][editCreator]'"
                                                    :checked="permission.editCreator === 1" data-permission-name="editCreator"
                                                    @change="updatePermission(index, 'editCreator', $event)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="update-user-errors"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
<!--                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
 -->                        <button type="button" class="btn btn-secondary" @click="submitForm">Αποθήκευση</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>



<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveUsers } from '@/services/users';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { mapGetters } from 'vuex';


const defaultUserData = {
    id: '',
    email: '',
    username: '',
    password: '',
    passwordConfirm: '',
    hireDate: '',
    active: '1',
    name: '',
    surname: '',
    fatherFullName: '',
    motherFullName: '',
    spouseFullName: '',
    identityType: 'Αστυνομική Ταυτότητα',
    identityNum: '',
    identityCreationDate: '',
    identityCreationDepartment: '',
    afm: '',
    doy: '',
    amIka: '',
    amka: '',
    amMiscDepartment: '',
    taxEmail: '',
    birthday: '',
    birthplace: '',
    nationality: '',
    address: '',
    area: '',
    profilePath: null,
    postal: '',
    municipality: '',
    tel: '',
    maritalStatus: '',
    permissions: [
         {
           "userId": null,
           "entityId": 1,
            "view": 0,
           "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Dashboard",
            "entity": {
                 "id": 1,
                "title": "Dashboard",
                "viewDescription": null,
                 "viewAdminDescription": null,
                "editDescription": null,
                "editAllDescription": null,
                "createDescription": null,
                 "archiveDescription": null,
                "acceptDescription": null,
                 "created_at": "2024-05-30T10:22:09.000000Z",
                "updated_at": "2024-05-30T10:22:09.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 2,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Projects",
            "entity": {
                "id": 2,
                "title": "Projects",
                "viewDescription": "Το View permission στα Projects αφορά το αν ο χρήστης θα βλέπει στο μενού τα Projects και αν μπορεί να επισκέπτεται τη σελίδα ανεξάρτητα από το αν έχει δημιουργήσει ή έχει γίνει assign σε κάποιο Project.",
                "viewAdminDescription": "Το View all permission στα Projects αφορά το αν ο χρήστης θα βλέπει όλα τα Projects ανεξάρτητα από το αν έχει δημιουργήσει ή έχει γίνει assign σε κάποιο project. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τα Projects θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Projects δίνει στον χρήστη permissions creator για όλα τα Projects που βλέπει, πρέπει όμως να είναι απαραίτητα assigned/creator/manager. Γενικά το edit permission δεν χρειάζεται στα Projects γιατί λειτουργούν με βάση το ρόλο του χρήστη. Αν θέλουμε όμως κάποιοι χρήστες να έχουν τη δυνατότητα να επεξεργάζονται όλα τα πεδία του Project που βλέπουν, μπορούμε να το χρησιμοποιήσουμε.",
                "editAllDescription": "Το Edit All permission στα Projects δίνει στον χρήστη δίνει στον χρήστη permissions creator σε όλα τα projects ανεξάρτητα αν είναι creator/manager/assigned, προφανώς για να δει όλα τα project θα πρέπει να έχει και View All permission.",
                "createDescription": "Το Create permission στα Projects δίνει τη δυνατότητα στο χρήστη να δημιουργεί Projects. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Projects.",
                "archiveDescription": "Το Archive permission στα Projects δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στα Projects.",
                "created_at": "2024-05-30T10:22:15.000000Z",
                "updated_at": "2024-05-30T10:22:15.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 3,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Users",
            "entity": {
                "id": 3,
                "title": "Users",
                "viewDescription": "Το View permission στους Χρήστες αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τους Χρήστες και αν μπορεί να επισκέπτεται τη σελίδα.",
                "viewAdminDescription": "Το View All permission στους Χρήστες αφορά το αν ο χρήστης μπορεί να βλέπει όλους τους χρήστες. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τους Χρήστες θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στους Χρήστες δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τον λογαριασμό του μέσα από την οθόνη των Χρηστών. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Χρήστες.",
                "editAllDescription": "Το Edit All permission στους Χρήστες δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τους λογαριασμούς όλων των χρηστών. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Χρήστες όπως και View all permission για να δει τους υπόλοιπους χρήστες.",
                "createDescription": "Το Create permission στους Χρήστες δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούριους χρήστες. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Χρήστες.",
                "archiveDescription": "Το Archive permission στους Χρήστες δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί άλλους χρήστες (προσωρινή διαγραφή). Προφανώς θα πρέπει να έχει και View all permission για να μπορεί να δει τους υπόλοιπους χρήστες, όπως και View permission για να μπορεί να επισκεφθεί τη σελίδα.",
                "acceptDescription": "Δεν εφαρμόζεται στους Χρήστες.",
                "created_at": "2024-05-30T10:22:22.000000Z",
                "updated_at": "2024-05-30T10:22:22.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 4,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Clients",
            "entity": {
                "id": 4,
                "title": "Clients",
                "viewDescription": "Το View permission στους Πελάτες αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τους Πελάτες και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο όσους έχει δημιουργήσει.",
                "viewAdminDescription": "Το View All permission στους Πελάτες αφορά το αν ο χρήστης μπορεί να βλέπει όλους τους πελάτες. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τους Πελάτες θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στους Πελάτες δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τους πελάτες που έχει δημιουργήσει. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Πελάτες.",
                "editAllDescription": "Το Edit All permission στους Πελάτες δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται όλους τους πελάτες. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Πελάτες όπως και View all permission για να δει τους όλους τους πελάτες και όχι μόνο όσους έχει δημιουργήσει.",
                "createDescription": "Το Create permission στους Πελάτες δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούριους πελάτες. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Πελάτες.",
                "archiveDescription": "Το Archive permission στους Πελάτες δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί πελάτες (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στους Πελάτες.",
                "created_at": "2024-05-30T10:22:27.000000Z",
                "updated_at": "2024-05-30T10:22:27.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 5,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Services",
            "entity": {
                "id": 5,
                "title": "Services",
                "viewDescription": "Το View permission στα Services αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τα Services και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο όσα έχει δημιουργήσει.",
                "viewAdminDescription": "Το View All permission στα Services αφορά το αν ο χρήστης μπορεί να βλέπει όλα τα services. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τα Services θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Services δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τα Services που έχει δημιουργήσει. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Services.",
                "editAllDescription": "Το Edit All permission στα Services δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται όλα τα Services. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Services όπως και View all permission για να δει όλα τα Services και όχι μόνο όσα έχει δημιουργήσει.",
                "createDescription": "Το Create permission στα Services δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούρια Services. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Πελάτες.",
                "archiveDescription": "Το Archive permission στα Services δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί services (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στα Services.",
                "created_at": "2024-05-30T10:22:32.000000Z",
                "updated_at": "2024-05-30T10:22:32.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 6,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Suppliers",
            "entity": {
                "id": 6,
                "title": "Suppliers",
                "viewDescription": "Το View permission στους Suppliers αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τους Suppliers και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο όσους έχει δημιουργήσει.",
                "viewAdminDescription": "Το View All permission στους Suppliers αφορά το αν ο χρήστης μπορεί να βλέπει όλους τους Suppliers. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τους Suppliers θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στους Suppliers δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τους Suppliers που έχει δημιουργήσει. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Suppliers.",
                "editAllDescription": "Το Edit All permission στους Suppliers δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται όλα τα Services. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Services όπως και View all permission για να δει όλους τους Suppliers και όχι μόνο όσους έχει δημιουργήσει.",
                "createDescription": "Το Create permission στους Suppliers δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούριους Suppliers. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στους Suppliers.",
                "archiveDescription": "Το Archive permission στους Suppliers δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί suppliers (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στους Suppliers.",
                "created_at": "2024-05-30T10:22:38.000000Z",
                "updated_at": "2024-05-30T10:22:38.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 7,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Contacts",
            "entity": {
                "id": 7,
                "title": "Contacts",
                "viewDescription": "Το View permission στα Contacts αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τα Contacts και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο όσα έχει δημιουργήσει.",
                "viewAdminDescription": "Το View All permission στα Contacts αφορά το αν ο χρήστης μπορεί να βλέπει όλα τα Contacts. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τα Contacts θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Contacts δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τα Contacts που έχει δημιουργήσει. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Contacts.",
                "editAllDescription": "Το Edit All permission στα Contacts δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται όλα τα Contacts. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Contacts όπως και View all permission για να δει όλα τα Contacts και όχι μόνο όσα έχει δημιουργήσει.",
                "createDescription": "Το Create permission στα Contacts δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούρια Contacts. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Contacts.",
                "archiveDescription": "Το Archive permission στα Contacts δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί contacts (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στα Contacts.",
                "created_at": "2024-05-30T10:22:44.000000Z",
                "updated_at": "2024-05-30T10:22:44.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 8,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Hours",
            "entity": {
                "id": 8,
                "title": "Hours",
                "viewDescription": "Το View permission στα Hours αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τα Hours και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο όσα έχει δημιουργήσει.",
                "viewAdminDescription": "Το View All permission στα Hours αφορά το αν ο χρήστης μπορεί να βλέπει όλα τα Hours. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τα Hours θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Hours δίνει τη δυνατότητα στο χρήστη να επεξεργάζεται τα Hours που έχει δημιουργήσει ή να προσθέτει ώρες για προηγούμενη ημερομηνία. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Hours.",
                "editAllDescription": "Δεν εφαρμόζεται στα Hours.",
                "createDescription": "Το Create permission στα Hours δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούρια Hours. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Hours.",
                "archiveDescription": "Δεν εφαρμόζεται στα Hours.",
                "acceptDescription": "To accept permission στα Hours εμφανίζει στο χρήστη το Hours Requests Panel για να μπορεί να αποδεχθεί αιτήματα αλλαγής στις ώρες.",
                "created_at": "2024-05-30T10:22:51.000000Z",
                "updated_at": "2024-05-30T10:22:51.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 9,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Days off",
            "entity": {
                "id": 9,
                "title": "Days off",
                "viewDescription": "Το View permission στα Days Off αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τα Days Off και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο όσα έχει δημιουργήσει.",
                "viewAdminDescription": "Το View All permission στα Days Off αφορά το αν ο χρήστης μπορεί να βλέπει όλες τις άδειες. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τα Days Off θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Days Off δίνει τη δυνατότητα στο χρήστη να διαγράφει τα Days Off που έχει δημιουργήσει. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Days Off.",
                "editAllDescription": "Το Edit All permission στα Days Off δίνει τη δυνατότητα στο χρήστη να διαγράφει όλα τα Days Off, ανεξάρτητα αν τα έχει δημιουργήσει ο ίδιος. Προφανώς θα πρέπει να έχει View permission για να μπορεί να μπει στα Days off και επίσης View All permission για να βλέπει όλα τα Days off.",
                "createDescription": "Το Create permission στα Days Off δίνει τη δυνατότητα στο χρήστη να δημιουργεί αιτήματα αδείας. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στα Days Off.\";\n                permission.permission_archive_description = \"Δεν εφαρμόζεται στα Days Off.",
                "archiveDescription": "Δεν εφαρμόζεται στα Days Off.",
                "acceptDescription": "To accept permission στα Days Off εμφανίζει στο χρήστη το Days Off Request Panel για να μπορεί να αποδεχθεί αιτήματα αδείας.",
                "created_at": "2024-05-30T10:22:59.000000Z",
                "updated_at": "2024-05-30T10:22:59.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 10,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Reporting",
            "entity": {
                "id": 10,
                "title": "Reporting",
                "viewDescription": "Το View permission στo Reporting αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού το Reporting και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει τα δεδομένα των ωρών για όσα Projects έχει πάρει μέρος.",
                "viewAdminDescription": "Το View All permission στo Reporting αφορά το αν ο χρήστης μπορεί να βλέπει το Reporting από όλα τα Project. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού το Reporting θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Δεν εφαρμόζεται στo Reporting.",
                "editAllDescription": "Δεν εφαρμόζεται στo Reporting.",
                "createDescription": "Δεν εφαρμόζεται στo Reporting.",
                "archiveDescription": "Δεν εφαρμόζεται στo Reporting.",
                "acceptDescription": "Δεν εφαρμόζεται στo Reporting.",
                "created_at": "2024-05-30T10:23:04.000000Z",
                "updated_at": "2024-05-30T10:23:04.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 11,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Holidays",
            "entity": {
                "id": 11,
                "title": "Holidays",
                "viewDescription": "Το View permission στις Holidays (Αργίες) αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τις Holidays (Αργίες) και αν μπορεί να επισκέπτεται τη σελίδα.",
                "viewAdminDescription": "Δεν εφαρμόζεται στις Holidays (Αργίες).",
                "editDescription": "Δεν εφαρμόζεται στις Holidays (Αργίες).",
                "editAllDescription": "Δεν εφαρμόζεται στις Holidays (Αργίες).",
                "createDescription": "Το Create permission στις Holidays (Αργίες) δίνει τη δυνατότητα στο χρήστη να δημιουργεί καινούριες Holidays (Αργίες). Προφανώς θα πρέπει να έχει και View permission για να μπορεί να μπει στις Holidays (Αργίες).",
                "archiveDescription": "Το Archive permission στις Holidays (Αργίες) δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί Holidays (Αργίες).",
                "acceptDescription": "Δεν εφαρμόζεται στις Holidays (Αργίες).",
                "created_at": "2024-05-30T10:23:09.000000Z",
                "updated_at": "2024-05-30T10:23:09.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 12,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Days off reports",
            "entity": {
                "id": 12,
                "title": "Days off reports",
                "viewDescription": "Το View permission στα Days Off Reports αφορά το αν ο χρήστης μπορεί να βλέπει στο μενού τα Days Off Reports και αν μπορεί να επισκέπτεται τη σελίδα. Αν δεν έχει View All permission βλέπει μόνο τις άδειές του.",
                "viewAdminDescription": "Το View All permission στα Days Off Reports αφορά το αν ο χρήστης μπορεί να βλέπει όλες τις άδειες. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης στο μενού τα Days Off θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Δεν εφαρμόζεται στα Days Off Reports.",
                "editAllDescription": "Δεν εφαρμόζεται στα Days Off Reports.",
                "createDescription": "Δεν εφαρμόζεται στα Days Off Reports.",
                "archiveDescription": "Δεν εφαρμόζεται στα Days Off Reports.",
                "acceptDescription": "Δεν εφαρμόζεται στα Days Off Reports.",
                "created_at": "2024-05-30T10:23:17.000000Z",
                "updated_at": "2024-05-30T10:23:17.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 13,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Tasks",
            "entity": {
                "id": 13,
                "title": "Tasks",
                "viewDescription": "Το View permission στα Tasks αφορά το αν ο χρήστης θα βλέπει τα Tasks ενός Project εφόσον είναι creator/manager/assigned, για να εμφανιστούν τα Tasks των Project θα πρέπει να είναι επιλεγμένο, αλλιώς κρύβονται.",
                "viewAdminDescription": "Το View All permission στα Tasks αφορά το αν ο χρήστης θα βλέπει όλα τα tasks ενός Project ανεξάρτητα από το αν είναι creator/manager/assigned σε κάποιο task. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης τα Tasks θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Tasks δίνει στον χρήστη permissions creator για όλα τα Tasks που βλέπει, πρέπει όμως να είναι απαραίτητα creator/manager/assigned. Γενικά το edit permission δεν χρειάζεται στα Tasks γιατί λειτουργούν με βάση το ρόλο του χρήστη. Αν θέλουμε όμως κάποιοι χρήστες να έχουν τη δυνατότητα να επεξεργάζονται όλα τα πεδία του Task που βλέπουν, μπορούμε να το χρησιμοποιήσουμε.",
                "editAllDescription": "Το Edit All permission στα Tasks δίνει στον χρήστη δίνει στον χρήστη permissions creator σε όλα τα Tasks ανεξάρτητα αν είναι creator/manager/assigned, προφανώς για να δει όλα τα Tasks θα πρέπει να έχει και View All permission όπως και το View permission για να εμφανίζεται η ενότητα των Tasks.",
                "createDescription": "Το Create permission στα Tasks δίνει τη δυνατότητα στο χρήστη να δημιουργεί Tasks. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να δει τα Tasks.",
                "archiveDescription": "Το Archive permission στα Tasks δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στα Tasks.",
                "created_at": "2024-05-30T10:23:23.000000Z",
                "updated_at": "2024-05-30T10:23:23.000000Z"
            }
        },
        {
            "userId": null,
            "entityId": 14,
            "view": 0,
            "viewAdmin": 0,
            "create": 0,
            "edit": 0,
            "editAll": 0,
            "archive": 0,
            "accept": 0,
            "entityTitle": "Subtasks",
            "entity": {
                "id": 14,
                "title": "Subtasks",
                "viewDescription": "Το View permission στα Subtasks αφορά το αν ο χρήστης θα βλέπει τα Subtasks ενός Task εφόσον είναι creator/manager/assigned, για να εμφανιστούν τα Subtasks των Project θα πρέπει να είναι επιλεγμένο, αλλιώς κρύβονται.",
                "viewAdminDescription": "Το View All permission στα Subtasks αφορά το αν ο χρήστης θα βλέπει όλα τα Subtasks ενός Task ανεξάρτητα από το αν είναι creator/manager/assigned σε κάποιο Subtask. ΠΡΟΣΟΧΗ: Για να δει ο χρήστης τα Tasks θα πρέπει να έχει απαραίτητα και το View permission.",
                "editDescription": "Το Edit permission στα Subtasks δίνει στον χρήστη permissions creator για όλα τα Subtask που βλέπει, πρέπει όμως να είναι απαραίτητα creator/manager/assigned. Γενικά το edit permission δεν χρειάζεται στα Subtasks γιατί λειτουργούν με βάση το ρόλο του χρήστη. Αν θέλουμε όμως κάποιοι χρήστες να έχουν τη δυνατότητα να επεξεργάζονται όλα τα πεδία του Subtask που βλέπουν, μπορούμε να το χρησιμοποιήσουμε.",
                "editAllDescription": "Το Edit All permission στα Subtasks δίνει στον χρήστη δίνει στον χρήστη permissions creator σε όλα τα Subtasks ανεξάρτητα αν είναι creator/manager/assigned, προφανώς για να δει όλα τα Subtasks θα πρέπει να έχει και View All permission όπως και το View permission για να εμφανίζεται η ενότητα των Subtasks.",
                "createDescription": "Το Create permission στα Subtasks δίνει τη δυνατότητα στο χρήστη να δημιουργεί Subtasks. Προφανώς θα πρέπει να έχει και View permission για να μπορεί να δει τα Subtasks.",
                "archiveDescription": "Το Archive permission στα Subtasks δίνει τη δυνατότητα στο χρήστη να αρχειοθετεί (προσωρινή διαγραφή).",
                "acceptDescription": "Δεν εφαρμόζεται στα Subtasks.",
                "created_at": "2024-05-30T10:23:28.000000Z",
                "updated_at": "2024-05-30T10:23:28.000000Z"
            }
        }
    ]
};

export default {
    name: 'Users',
    data() {
        return {
            isGridView: false, // true for grid view, false for list view
        };
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission', // Add this line
        }),
        userPermissions() {
            return this.getUserPermission('Users');
        },
        canCreateUser() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
      return this.userPermissions?.archive === 1;
    },
    canUpdateUser() {
            return this.userPermissions?.create === 1;
        },
        canEditUser() {
            return this.userPermissions?.edit === 1;
        },
        canEditAllUsers() {
            return this.userPermissions?.editAll === 1;
        },
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        getUser() {
            return this.$store.state.userInfo.id;
        },
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1, active: route.query.active || 1}, { page: 1, active: 1 });

        const userData = ref(defaultUserData);
        const selectedUserIds = ref([]);
        const isDropdownOpen = ref(false);

        const toggleUserSelection = (userId, event) => {
            if (event.target.checked) {
                selectedUserIds.value.push(userId);
            } else {
                selectedUserIds.value = selectedUserIds.value.filter(id => id !== userId);
            }
            console.log(selectedUserIds);
        };

        const toggleUsersSelection = (userId, event) => {
            if (!selectedUserIds.value.includes(userId)) {
                selectedUserIds.value.push(userId);
            } else {
                selectedUserIds.value = selectedUserIds.value.filter(id => id !== userId);
            }
        };

        const archiveUsers = async () => {
            if (!selectedUserIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει χρήστες');
                return;
            }
            const response = await massArchiveUsers({ ids: selectedUserIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const editUser = async (id) => {
            const userInfo = await get({ "id": id });
            if (userInfo.data.success) {
                userData.value = userInfo.data.data.info;
                userData.value.permissions = userInfo.data.data.permissions;
            }
            $("#create-user-form").modal('toggle');
        };

        const createUser = async () => {
            userData.value = defaultUserData;
            $("#create-user-form").modal('toggle');
        };

        const submitForm = async () => {
            const response = await createOrUpdate(userData.value);
            if (response.data.success) {
                useToast().success(response.data.message);
                $("#create-user-form").modal('toggle');
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const updatePermission = (index, permissionType, event) => {
            userData.value.permissions[index][permissionType] = event.target.checked ? 1 : 0;
        };

        const massCheckUncheckAll = (event, permissionType) => {
            const isChecked = event.target.checked;
            userData.value.permissions.forEach((permission, index) => {
                if (!event.target.disabled) {
                    if (permissionType === 'all') {
                        Object.keys(permission).forEach((key) => {
                            if (permission.view != 'disabled') {
                                permission.view = isChecked ? 1 : 0;
                            }
                            if (permission.viewAdmin != 'disabled') {
                                permission.viewAdmin = isChecked ? 1 : 0;
                            }
                            if (permission.create != 'disabled') {
                                permission.create = isChecked ? 1 : 0;
                            }
                            if (permission.edit != 'disabled') {
                                permission.edit = isChecked ? 1 : 0;
                            }
                            if (permission.editAll != 'disabled') {
                                permission.editAll = isChecked ? 1 : 0;
                            }
                            if (permission.archive != 'disabled') {
                                permission.archive = isChecked ? 1 : 0;
                            }
                            if (permission.accept != 'disabled') {
                                permission.accept = isChecked ? 1 : 0;
                            }
                        });
                    } else {
                        if (permissionType == 'view' || permissionType == 'viewAdmin' || permissionType == 'edit' || permissionType == 'editAll' || permissionType == 'create' || permissionType == 'accept' || permissionType == 'archive' || permissionType == 'editCreator') {
                            permission[permissionType] = isChecked ? 1 : 0;
                        }
                    }
                }
            });
        };

        const massCheckUncheckRow = (event, rowIndex) => {
            const isChecked = event.target.checked;
            const permissionRow = userData.value.permissions[rowIndex];
            Object.keys(permissionRow).forEach((key) => {
                if (!event.target.disabled && (key == 'view' || key == 'viewAdmin' || key == 'edit' || key == 'editAll' || key == 'create' || key == 'accept' || key == 'archive')) {
                    permission[key] = isChecked ? 1 : 0;
                }
            });
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({path: '/users', query: {...route.query, search: $("#main-search-input").val()}});
            }, 300);
        };

        onMounted(() => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
        });


        return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, userData, submitForm, editUser, createUser, selectedUserIds, toggleUserSelection, toggleUsersSelection, updatePermission, massCheckUncheckAll, massCheckUncheckRow, archiveUsers, toggleDropdown, closeDropdown, isDropdownOpen, dropdownStyle, sortBy };
    },
    metaInfo: {
        title: 'Χρήστες',
        meta: [
            { name: 'description', content: 'MIS - Χρήστες' },
            { property: 'og:title', content: 'MIS - Χρήστες' },
            { property: 'og:description', content: 'This is the users page.' }
        ]
    }
};
</script>

<style scoped>
.checkbox-wrapper {
  margin-right: 10px;
}

.user-info {
  flex-grow: 1;
}
.userInfoText {
  margin-top: 10px;
}

.card.selected-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
tr.selected, div.selected {
    background: #b1afaf;
}
</style>
