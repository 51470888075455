import apiClient from './api';

async function changeProfile(data) {
  try {
    const response = await apiClient.post('/change-profile', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error('Error changing profile:', error);
    throw error;
  }
}

export { changeProfile };
