<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">

                    <div class="col-md-12 d-flex">
                        <!-- Main Content (Table/Grid) -->
                        <div :class="{ 'col-md-12': !showFilters, 'col-md-9 leftCol': showFilters }">

                            <div v-if="!isGridView" class="card-body table-responsive">
                                <table id="projectTable" class="table table-hover align-middle mb-0 mis_table"
                                    style="width:100%">
                                    <thead>
                                        <tr>
                                            <th style="width:16%;">
                                                <div>Client<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('client_title')"></i></div>
                                            </th>
                                            <th style="width:18%;">
                                                <div>project<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('projects.description')"></i></div>
                                            </th>

                                            <th style="width:12%;">
                                                <div v-if="superUser" class="d-flex align-items-center">

                                                    <select class="nav-link rounded" name="" id="dropdownCreators"
                                                        @change="updateCreatorFilter">
                                                        <option value="" selected>CREATOR</option>
                                                        <option v-for="creator in creators" :key="creator.id"
                                                            :value="creator.id">
                                                            {{ creator.username }}
                                                        </option>
                                                    </select>
                                                    <img class="menu-img" src="../assets/images/icons8-superman.svg"
                                                        alt="Citrine Logo" style="width:2em" >
                                                </div>
                                                <div v-else>
                                                    Creator
                                                    <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('c.username')"></i>
                                                </div>
                                            </th>

                                            <th style="width:10%;">
                                                <div v-if="superUser" class="d-flex align-items-center">
                                                    <select class="ms-1 nav-link rounded" name="" id="dropdownManagers"
                                                        @change="updateManagerFilter">
                                                        <option value="" selected>MANAGER</option>
                                                        <option v-for="manager in managers" :key="manager.id"
                                                            :value="manager.id">
                                                            {{ manager.username }}
                                                        </option>
                                                    </select>
                                                    <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo"
                                                        class="menu-img ms-1" style="width:2em">
                                                </div>
                                                <div v-else>
                                                    Manager
                                                    <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('m.username')"></i>
                                                </div>
                                            </th>

                                            <th style="width:10%;">
                                                <div>
                                                    DateS
                                                    <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('projects.startDate')"></i>
                                                </div>
                                            </th>

                                            <th style="width:15%;">
                                                <div>Status<i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('projects.statusId')"></i></div>
                                            </th>
                                            <th class="no-after"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(project, index) in data" :key="project.info.id"
                                            :class="{ 'selected': selectedProjectIds.includes(project.info.id) }"
                                            @click="toggleProjectSelection(project.info.id, $event)">
                                            <td v-if="project.client?.avatar_file_name">
                                                <img :src="`/storage/clients_avatars/${project.client.avatar_file_name}`"
                                                    style="width:10em">
                                            </td>
                                            <td v-else>
                                                <p v-if="project.client?.title">{{ project.client.title }}</p>
                                            </td>
                                            <td>
                                                <router-link :to="{ path: 'project', query: { id: project.info?.id } }">
                                                    <span class="project-code">{{ project.info?.code }}</span>
                                                </router-link>
                                            </td>


                                            <td v-if="project.creator?.avatar_file_name" class="small-column">
                                                <img :src="`/storage/avatars/${project.creator.avatar_file_name}`"
                                                    width="30" style="border-radius:50%;" :title="project.creator.username">
                                            </td>
                                            <td v-else class="small-column">
                                                {{ project.creator?.username }}
                                            </td>

                                            <td v-if="project.manager?.avatar_file_name" class="small-column">
                                                <img :src="`/storage/avatars/${project.manager.avatar_file_name}`"
                                                    width="30" style="border-radius:50%;" :title="project.manager.username">
                                            </td>
                                            <td v-if="!project.manager?.avatar_file_name" class="small-column">
                                                {{ project.manager?.username }}
                                            </td>


                                            <td>{{ project.info?.startDate }} {{ project.info?.endDate }}</td>
                                            <td>
                                                <div class="dropdown-container">
                                                    <button class="dropbtn" :style="getStatusStyle(project.status?.id)">
                                                        {{ getCurrentStatus(project.status?.id) }}
                                                        <i class="fa fa-caret-down"
                                                            v-if="canEditProject(project) || canEditAllProjects || superUser"></i>
                                                    </button>
                                                    <div class="dropdown-content"
                                                        v-if="canEditProject(project) || canEditAllProjects || superUser">
                                                        <button v-for="status in statuses" :key="status.id"
                                                            @click="updateStatus(project.info?.id, status.id)"
                                                            :style="getStatusStyle(status.id)">
                                                            {{ status.description }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="button-group">
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark">
                                                            <img src="../assets/images/chat.png" alt="Unread comments"
                                                                title="Unread comments" class="menu-img"
                                                                style="width:1.5em">
                                                            <span class="unread-comments"
                                                                v-if="project.unread_comments > 0">{{
                                                                project.unread_comments }}</span>
                                                        </button>
                                                    </div>
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark"
                                                            @click.prevent="editProject(project.info.id)"
                                                            v-if="canEditProject(project) || canEditAllProjects || superUser">
                                                            <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                                class="menu-img" style="width:1.5em">
                                                        </button>
                                                    </div>
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark"
                                                            @click.prevent="archiveProject(project.info.id)"
                                                            v-if="canArchive || superUser">
                                                            <img src="../assets/images/delete.png" alt="Archive"
                                                                title="Archive" class="menu-img" style="width:1.5em">
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                        <tr class="col" v-if="!data || !data.length">
                                            <td>No data</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div v-if="isGridView" class="row g-3">
                                <div v-for="(project, index) in data" :key="project.info.id"
                                    class="col-md-4 col-sm-6 mb-2">
                                    <div class="card h-100"
                                        style="border-radius: 15px; margin-left:1em; background: #eae8e8; color: black;">
                                        <div class="card-body position-relative">
                                            <!-- Header Section -->
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="ms-3"
                                                    style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                                    <router-link
                                                        :to="{ path: 'project', query: { id: project.info?.id } }">

                                                        <h6 class="mb-0 mt-2 fw-bold">{{ project.info?.code }}</h6>
                                                    </router-link>
                                                    <span class="d-block small-14 mb-0">{{ project.client?.title
                                                        }}</span>
                                                </div>
                                            </div>

                                            <!-- Creator and Manager -->
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="me-2 fw-bold">Creator:</span>
                                                <img v-if="project.creator?.avatar_file_name"
                                                    :src="`/storage/avatars/${project.creator.avatar_file_name}`"
                                                    width="30" style="border-radius: 50%">
                                                <span v-else>{{ project.creator?.username }}</span>
                                            </div>
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="me-2 fw-bold">Manager:</span>
                                                <img v-if="project.manager?.avatar_file_name"
                                                    :src="`/storage/avatars/${project.manager.avatar_file_name}`"
                                                    width="30" style="border-radius: 50%">
                                                <span v-else>{{ project.manager?.username }}</span>
                                            </div>

                                            <!-- Project Dates -->
                                            <div class="mb-2">
                                                <span class="fw-bold">Dates:</span> {{ project.info?.startDate }} - {{
                                                    project.info?.endDate }}
                                            </div>

                                            <!-- Action Buttons -->
                                            <div class="action-buttons">
                                                <button class="btn btn-dark comments">
                                                    <img src="../assets/images/chat.png" alt="Unread comments"
                                                        title="Unread comments" class="menu-img" style="width:1.5em">
                                                    <span class="unread-comments" v-if="project.unread_comments > 0">{{
                                                        project.unread_comments }}</span>
                                                </button>
                                                <button class="btn btn-dark edit"
                                                    @click.prevent="editProject(project.info.id)"
                                                    v-if="canEditProject(project) || canEditAllProjects || superUser">
                                                    <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                        class="menu-img" style="width:1.5em">
                                                </button>
                                                <button class="btn btn-dark delete"
                                                    @click.prevent="archiveProject(project.info.id)"
                                                    v-if="canArchive || superUser">
                                                    <img src="../assets/images/delete.png" alt="Archive" title="Archive"
                                                        class="menu-img" style="width:1.5em">
                                                </button>
                                            </div>

                                            <!-- Status Dropdown -->
                                            <div class="dropdown-container2">
                                                <button class="dropbtn w-60"
                                                    :style="getStatusStyle(project.status?.id)">
                                                    {{ getCurrentStatus(project.status?.id) }}
                                                    <i class="fa fa-caret-down" v-if="canEditProject(project) || canEditAllProjects || superUser"></i>
                                                </button>
                                                <div class="dropdown-content2" v-if="canEditProject(project) || canEditAllProjects || superUser">
                                                    <button v-for="status in statuses" :key="status.id"
                                                        @click="updateStatus(project.info?.id, status.id)"
                                                        :style="getStatusStyle(status.id)">
                                                        {{ status.description }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- No Data Message -->
                                <div class="col" v-if="!data || !data.length">
                                    <p>No data</p>
                                </div>
                            </div>
                            <nav aria-label="Page navigation">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks"
                                        :key="index" :class="{ active: paginationLink.active }">
                                        <router-link
                                            :to="{ path: 'projects', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                            v-if="paginationLink.url">
                                            <span class="page-link" v-html="paginationLink.label"></span>
                                        </router-link>
                                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <!-- Filters Column -->
                        <div v-if="showFilters" class="col-md-3 rightCol">
                            <div class="card border-0 mb-2">
                                <div
                                    class="card-header mb-5 py-1 px-0 d-sm-flex align-items-center justify-content-between">
<!--                                     <h3 class="fw-bold mb-0">Filters</h3>
 -->                                    <div>
                                        <button type="button" class="ms-0 ms-sm-3 btn"
                                            style="color:var(--secondary-color); font-weight:bold"
                                            @click="createProject" v-if="canCreateGroup || superUser">
                                            <i class="icofont-plus me-2 fs-6"></i>New project
                                        </button>
                                    </div>
                                    <div class="ms-3 d-flex align-items-center">
                                        <img v-if="isGridView" src="../assets/images/list.png" alt="List" title="List"
                                            class="menu-img" style="width:2.5em; cursor: pointer;"
                                            @click="isGridView = false">
                                        <div v-if="!isGridView" style="background-color: #AFAFAF">
                                            <img src="../assets/images/list2.png" alt="List" title="List"
                                                class="menu-img" style="width:2.5em; cursor: pointer;"
                                                @click="isGridView = false">
                                        </div>
                                        <img v-if="!isGridView" src="../assets/images/grid.png" alt="Grid" title="Grid"
                                            class="menu-img" style="width:2.5em; cursor: pointer;"
                                            @click="isGridView = true">
                                        <div v-if="isGridView" style="background-color: #AFAFAF">
                                            <img src="../assets/images/grid2.png" alt="Grid" title="Grid"
                                                class="menu-img" style="width:2.5em; cursor: pointer;"
                                                @click="isGridView = true">
                                        </div>
                                    </div>
                                </div>
                                <div class="filter-section mt-3">

                                    <div class="d-flex align-items-center mb-3">
                                        <select class="ms-3 nav-link rounded me-2 selectSearch" v-model="searchArea">
                                            <option value="title" selected>Title</option>
                                            <option value="clients">Clients</option>
                                        </select>
                                        <input id="main-search-input" type="search" class="form-control"
                                            placeholder="Αναζήτηση..." aria-label="search"
                                            aria-describedby="addon-wrapping" />
                                    </div>

                                    <div>
                                        <div class="card border-0 mb-2 no-bg">
                                            <div
                                                class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                                                <ul class="nav nav-tabs tab-body-header rounded mt-sm-0 mt-3 ms-0 ms-sm-3 prtab-set w-sm-100"
                                                    role="tablist">
                                                    <li class="nav-item col" v-for="status in statusFilters"
                                                        :key="status.id">
                                                        <a :class="statusClass(status.id)"
                                                            @click.prevent="applyStatusFilter(status.id)" href="#"
                                                            role="tab">{{
                                                                status.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul class="nav nav-tabs tab-body-header rounded w-100 mb-2" role="tablist">

                                            <li class="nav-item ">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '0' || filters.statusId == undefined, 'nav-link text-center': filters.statusId != '0' && filters.statusId != undefined }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '0', page: 1 })"
                                                    href="#" role="tab">
                                                    <p style="font-size: large;">All</p>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '1', 'nav-link text-center': filters.statusId != '1' }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '1', page: 1 })"
                                                    href="#" role="tab"> <img src="../assets/images/on_hold.png"
                                                        alt="On Hold" title="On Hold" class="menu-img"
                                                        style="width:2.5em">
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '2', 'nav-link text-center': filters.statusId != '2' }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '2', page: 1 })"
                                                    href="#" role="tab"> <img src="../assets/images/progress.png"
                                                        alt="In Progress" title="In Progress" class="menu-img"
                                                        style="width:2.5em">

                                                </a>
                                            </li>
                                            <li class="nav-item ">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '3', 'nav-link text-center': filters.statusId != '3' }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '3', page: 1 })"
                                                    href="#" role="tab"> <img src="../assets/images/done.png" alt="Done"
                                                        title="Done" class="menu-img" style="width:2.5em">
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '5', 'nav-link text-center': filters.statusId != '5' }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '5', page: 1 })"
                                                    href="#" role="tab"><img src="../assets/images/on_going.png"
                                                        alt="On going" title="On going" class="menu-img"
                                                        style="width:2.5em"></a>
                                            </li>
                                            <li class="nav-item">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '4', 'nav-link text-center': filters.statusId != '4' }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '4', page: 1 })"
                                                    href="#" role="tab"> <img src="../assets/images/archived.png"
                                                        alt="Archived" title="Archived" class="menu-img"
                                                        style="width:2.5em">
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a :class="{ 'nav-link text-center active': filters.statusId == '6', 'nav-link text-center': filters.statusId != '6' }"
                                                    @click.prevent="() => updateFilters({ ...filters, statusId: '6', page: 1 })"
                                                    href="#" role="tab"> <img src="../assets/images/potential.png"
                                                        alt="Potential" title="Potential" class="menu-img"
                                                        style="width:2.5em">
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- Date Filters -->
                                    <div class="mb-3 d-flex align-items-center">
                                        <label for="start-date" class="form-label me-2">Start date:</label>
                                        <input type="date" id="start-date" v-model="startDate"
                                            class="form-control w-auto" />
                                    </div>

                                    <div class="mb-3 d-flex align-items-center">
                                        <label for="end-date" class="form-label me-2">End date:</label>
                                        <input type="date" id="end-date" v-model="endDate"
                                            class="form-control w-auto" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Create Project-->
                <div class="modal fade" id="create-project-form" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-bold" id="createprojectlLabel">Project Data</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="mb-3">
                                    <label for="projectTitle" class="form-label" aria-label="Title"
                                        aria-placeholder="Title">Title</label>
                                    <input type="text" class="form-control" id="projectTitle" placeholder="Title"
                                        name="title" v-model="projectData.description">
                                </div>
                                <div class="mb-3">
                                    <label for="projectDescriptionTinyMce" class="form-label">Description</label>
                                    <Editor api-key="no-api-key" :init="tinymceInit" v-model="projectData.body">
                                    </Editor>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Client</label>
                                    <Select2 :options="allData.clients" v-model="projectData.clientId"
                                        :settings="select2Settings" />
                                </div>
                                <div class="mb-3" v-if="superUser || canEditProjectCreator">
                                    <label class="form-label">Creator</label>
                                    <Select2 :options="allData.users" v-model="projectData.createdBy"
                                        :settings="select2SettingsProject" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Status</label>
                                    <Select2 :options="allData.statuses" v-model="projectData.statusId"
                                        :settings="select2Settings" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Services</label>
                                    <Select2 :options="allData.services" v-model="projectData.serviceId"
                                        :settings="select2Settings" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Manager</label>
                                    <Select2 :options="allData.users" v-model="projectData.managerId"
                                        :settings="select2Settings" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Assigned users</label>
                                    <Select2 :options="allData.users" v-model="projectData.assignedUsers"
                                        :settings="{ ...select2Settings, multiple: true }" />
                                </div>
                                <div class="mb-3 payable-container">
                                    <div class="mb-3">
                                        <label class="form-label">Payable</label>
                                        <Select2
                                            :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]"
                                            v-model="projectData.payable" :settings="select2Settings" />
                                    </div>
                                    <div class="payable-fields" v-if="projectData.payable == '1'">
                                        <div class="mb-3">
                                            <label class="form-label">Pay Manager</label>
                                            <Select2 :options="allData.users" v-model="projectData.payManagerId"
                                                :settings="select2Settings" />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Invoiced</label>
                                            <Select2
                                                :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]"
                                                v-model="projectData.invoiced" :settings="select2Settings" />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Paid</label>
                                            <Select2
                                                :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]"
                                                v-model="projectData.paid" :settings="select2Settings" />
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="projectEstimatedHours" class="form-label" aria-label="Estimated hours"
                                        aria-placeholder="Estimated hours">Estimated hours</label>
                                    <input type="number" class="form-control" id="projectEstimatedHours"
                                        placeholder="Estimated hours" aria-label="Estimated hours"
                                        aria-placeholder="Estimated hours" name="estimatedHours"
                                        v-model="projectData.estimatedTime">
                                </div>
                                <div class="deadline-form">
                                    <form>
                                        <div class="row g-3 mb-3">
                                            <div class="col">
                                                <label for="projectStartDate" class="form-label">Start Date</label>
                                                <input type="date" class="form-control" id="projectStartDate"
                                                    name="startDate" v-model="projectData.startDate">
                                            </div>
                                            <div class="col">
                                                <label for="projectEndDate" class="form-label">End Date</label>
                                                <input type="date" class="form-control" id="projectEndDate"
                                                    name="endDate" v-model="projectData.endDate">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div id="create-project-errors"></div>
                            </div>
                            <div class="modal-footer">
<!--                                 <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 -->                                <button type="button" class="btn btn-secondary"
                                    @click.prevent="submitForm">Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Confirmation Modal for Archiving Project -->
    <div class="modal fade" id="archive-confirmation-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Archive</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure you want to archive this project?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="confirmArchive">Yes, Archive</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Toggle Button -->
    <button class="btn btn-toggle-filter" @click="toggleFilters"
        style="position: fixed; right: 1em; top: 40vh; z-index: 1000; padding:10px; background-color: var(--secondary-color)">
        <img src="../assets/images/filter-st.png" alt="Toggle Filters" style="width:2em; cursor: pointer;">
    </button>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdate, get, massArchiveProjects, getClientsServicesStatusesUsers } from '@/services/projects';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';
import { updateProjectStatus } from '@/services/todo';
import { get as getProject } from '@/services/projects';
import { fetchManagers } from '@/services/followup';

const defaultProjectData = {
    title: '',
    description: '',
    clientId: '',
    assignedUsers: [],
    serviceId: '',
    managerId: '',
    statusId: '',
    payable: '0',
    payManagerId: '',
    invoiced: '0',
    paid: '1',
    estimatedTime: '',
    startDate: '',
    endDate: ''
};

export default {
    name: 'Projects',
    data() {
        return {
            projectToArchive: null, // Store the project ID to be archived
            isGridView: false, // true for grid view, false for list view
            showFilters: false, // Track if filters are shown
        };
    },
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters; // Toggle the filter display
        },
        canEditProject(project) {
            if (!project) { return false; }
            return localStorage.getItem('current_user_id') == project.info?.createdBy && this.userPermissions?.edit === 1;
        },
        archiveProject(projectId) {
            this.projectToArchive = projectId;
            $("#archive-confirmation-modal").modal("show");
        },
        // Confirm archiving after user approval
        async confirmArchive() {
            if (this.projectToArchive) {
                const response = await massArchiveProjects({ ids: [this.projectToArchive] });
                if (response.data.success) {
                    useToast().success(response.data.message);
                    this.fetchData();
                } else {
                    useToast().error(response.data.message);
                }
                this.projectToArchive = null; // Reset after action
                $("#archive-confirmation-modal").modal('hide');
            }
        }
        // Other existing methods
    },
    components: {
        Editor,
        Select2
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Projects');
        },
        canEditProjectCreator() {
            return this.userPermissions?.editCreator === 1;
        },
        canEditAllProjects() {
            return this.userPermissions?.editAll === 1;
        },
        canCreateProject() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1, active: route.query.active || 1, archived: route.query.archived || 0 }, { page: 1, active: 1, archived: 0 });

        const projectData = ref(defaultProjectData);
        const allData = ref({});
        const selectedProjectIds = ref([]);
        const isDropdownOpen = ref(false);
        const payableFieldsShow = ref(false);
        const searchArea = ref(route.query?.searchField || 'title');
        const startDate = ref(route.query?.startDate || '');
        const endDate = ref(route.query?.endDate || '');
        const managers = ref([]);
        const creators = ref([]);


        // Watch for changes to startDate and endDate and update URL filters
        watch([searchArea], ([newSearchArea]) => {
            router.push({
                path: '/projects',
                query: { ...route.query, searchField: newSearchArea },
            });
        });

        // Watch for changes to startDate and endDate and update URL filters
        watch([startDate, endDate], ([newStartDate, newEndDate]) => {
            router.push({
                path: '/projects',
                query: { ...route.query, startDate: newStartDate, endDate: newEndDate },
            });
        });

        const transformDate = (dateStr) => {
            const [day, month, year] = dateStr.split('/');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        };

        const fetchManagersData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    managers.value = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch managers:', error);
            }
        };

        const fetchCreatorsData = async () => {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    creators.value = response.data.data;
                } else {
                    console.error("Failed to fetch creators:", response.data.message);
                }
            } catch (error) {
                console.error("Failed to fetch creators:", error);
            }
        };

        const statuses = ref([]);

        const getStatusIcon = (id) => {
            const status = statuses.value.find(s => s.id === id);
            return status ? status.icon : '';
        };

        const getCurrentStatus = (id) => {
            const status = statuses.value.find(s => s.id === id);
            return status ? status.description : 'Select Status';
        };

        const toggleProjectSelection = (projectId, event) => {
            // if (!selectedProjectIds.value.includes(projectId)) {
            //     selectedProjectIds.value.push(projectId);
            // } else {
            //     selectedProjectIds.value = selectedProjectIds.value.filter(id => id !== projectId);
            // }
        };

        const updateStatus = async (entityId, statusId) => {
            try {
                const response = await updateProjectStatus(entityId, statusId);
                if (response.data.success) {
                    useToast().success(response.data.message);
                    fetchData();
                } else {
                    useToast().error(response.data.message);
                }
            } catch (error) {
                console.error("Failed to update status:", error);
                useToast().error("Failed to update status.");
            }
        };

        const archiveProjects = async () => {
            if (!selectedProjectIds.value.length) {
                useToast().error('Δεν έχετε επιλέξει projects');
                return;
            }
            const response = await massArchiveProjects({ ids: selectedProjectIds.value });
            if (response.data.success) {
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const editProject = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers();
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const projectInfo = await getProject({ "id": id });
            if (projectInfo.data.success) {
                projectData.value = {
                    id: projectInfo.data.data.info.id,
                    projectId: route.query.id || 0,
                    createdBy: projectInfo.data.data.creator?.id,
                    description: projectInfo.data.data.info.description,
                    body: projectInfo.data.data.info.body,
                    manager_brief: projectInfo.data.data.info.manager_brief,
                    assignedUsers: projectInfo.data.data.assigned ? projectInfo.data.data.assigned?.map((assignedUser) => assignedUser.id) : [],
                    managerId: projectInfo.data.data.manager?.id,
                    serviceId: projectInfo.data.data.service?.id,
                    clientId: projectInfo.data.data.client?.id,
                    payable: projectInfo.data.data.info.payable || '0',
                    payManagerId: projectInfo.data.data.payManager?.id,
                    invoiced: projectInfo.data.data.info.invoiced || '0',
                    paid: projectInfo.data.data.info.paid || '0',
                    statusId: projectInfo.data.data.status?.id,
                    estimatedTime: projectInfo.data.data.info.estimatedTime,
                    startDate: transformDate(projectInfo.data.data.info.startDate),
                    endDate: transformDate(projectInfo.data.data.info.endDate)
                };
            }
            $("#create-project-form").modal('toggle');
        };

        const createProject = async () => {
            const tmpAllData = await getClientsServicesStatusesUsers();
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            projectData.value = {
                title: '',
                body: '',
                description: '',
                clientId: '',
                assignedUsers: [],
                serviceId: '',
                managerId: '',
                statusId: '',
                payable: '0',
                payManagerId: '',
                invoiced: '0',
                paid: '1',
                estimatedTime: '',
                startDate: '',
                endDate: ''
            };
            $("#create-project-form").modal('toggle');
        };

        const submitForm = async () => {
            const response = await createOrUpdate(projectData.value);
            if (response.data.success) {
                useToast().success(response.data.message);
                $("#create-project-form").modal('toggle');
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const tinymceInit = {
            height: 300,
            license_key: 'gpl',
            skin_url: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/ui/oxide',
            content_css: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/content/default/content.css'
        };

        const select2Settings = {
            dropdownParent: '#create-project-form .modal-content',
            placeholder: "Please select"
        };

        const updateManagerFilter = (event) => {
            const newManagerId = event.target.value;
            updateFilters({ ...filters.value, managerFilter: newManagerId, page: 1 });
        };

        const updateCreatorFilter = (event) => {
            const newCreatorId = event.target.value;
            updateFilters({ ...filters.value, creatorFilter: newCreatorId, page: 1 });
        };

        const getStatusStyle = (id) => {
            switch (id) {
                case 2:
                    return 'background: #1CA6F1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em; font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:3.5em';
                case 3:
                    return 'background: #A5CF27; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center;  height:3.5em';
                case 1:
                    return 'background: #B1B1B1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:3.5em';
                case 5:
                    return 'background: #ECC308; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:3.5em';
                case 4:
                    return 'background: #796EEE; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:3.5em';
                case 6:
                    return 'background: #E4364D; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:3.5em';
                default:
                    return 'text-align:center';
            }
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/projects', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        onMounted(async () => {
            $("#main-search-input").val(route.query?.search || '');
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            fetchManagersData();
            fetchCreatorsData();
            const tmpAllData = await getClientsServicesStatusesUsers();
            let newData = { statuses: [] };
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, description: tmpAllData.data.statuses[i].description });
            }
            statuses.value = newData.statuses;
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
        });

        return {
            statuses, searchArea, startDate, endDate, updateStatus, getCurrentStatus, getStatusIcon, data, allData, paginationLinks, filters, updateManagerFilter, managers, updateCreatorFilter, creators,
            updateFilters, fetchData, getPageFromUrl, projectData, submitForm, editProject, createProject, selectedProjectIds, toggleProjectSelection, archiveProjects, toggleDropdown, closeDropdown, getStatusStyle, isDropdownOpen, dropdownStyle, tinymceInit, select2Settings, sortBy
        };
    },
    metaInfo: {
        title: 'Projects',
        meta: [
            { name: 'description', content: 'MIS - Projects' },
            { property: 'og:title', content: 'MIS - Projects' },
            { property: 'og:description', content: 'This is the projects page.' }
        ]
    }
};
</script>

<style scoped>
.checkbox-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

.card.selected-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

tr.selected,
div.selected {
    background: #b1afaf;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 28px;
}

.select2-container--default .select2-selection--single {
    height: 38px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}

.select2-container .select2-selection--single {
    z-index: 9000;
    /* Adjust this value as necessary */
}

.select2-container .select2-selection--multiple {
    z-index: 9000;
    /* Adjust this value as necessary */
}

.select2-dropdown {
    z-index: 9000 !important;
    /* Ensure this is higher than other elements */
}

.modal.show .modal-dialog {
    z-index: 1050;
}

.modal-backdrop.show {
    z-index: 1040;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 90px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
    /* Ensure the container is wide enough */
}

.dropdown-container:hover .dropdown-content {
    display: block;
    width: 100%;
    /* Stretch the dropdown to full width */
}

.dropdown-content button {
    display: block;
    /* Make buttons stack vertically */
    width: 100%;
    /* Make buttons take the full width of the container */
    margin: 0;
    /* Remove margin to avoid spacing issues */
    box-sizing: border-box;
    /* Ensure padding does not affect width */
    margin-bottom: 3px;

}

#projectTable {
    width: 100%;
    /* Full width */
    table-layout: auto;
    /* Allows the table to adjust column widths automatically */
}

.table-responsive {
    overflow-x: auto;
    /* Adds horizontal scrolling */
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling for touch devices */
}

thead th,
tbody td {}

/* Ensure images fit within the smaller columns */
table .small-column img {
    max-width: 100%;
    /* Scale the image to fit the column */
    height: auto;
}

/* Optional: Add padding adjustments for narrow columns */
table .small-column {
    padding: 4px;
    /* Adjust padding as needed */
}





.col-md-12 {
    width: 100%;
}

.button-group>button {
    width: 3em;
}


@media (max-width: 768px) {

    #projectTable th,
    #projectTable td {
        font-size: 0.9em;
        /* Adjusts font size for smaller screens */
        padding: 0.5em;
        /* Reduces padding */
    }

    .dropdown-container,
    .button-group {
        display: block;
        /* Stacks items vertically on smaller screens */
    }
}
</style>
