<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="body d-flex py-3">
                <div class="deadline-form" id="update-user-form">
                    <a href="#" @click.prevent="redirectToAuth">Authenticate with Box</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { getAuthUrl } from '@/services/box-integration';

export default {
    name: 'BoxIntegration',
    setup() {

        const toast = useToast();

        const redirectToAuth = async () => {
            const r = await getAuthUrl();
            if (r && r.data.success) {
                const url = r.data.data.url;
                window.location.href = url;
            }
        };

        return { redirectToAuth };
    },
    metaInfo: {
        title: 'Box Integration',
        meta: [
            { name: 'description', content: 'MIS - Box Integration' },
            { property: 'og:title', content: 'MIS - Box Integration' },
            { property: 'og:description', content: 'This is the box integration page.' }
        ]
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
